<template>
  <div>
    <div>
      <div
        class="template-setting-window d-flex flex-column justify-content-between"
      >
        <div class="d-flex flex-column">
          <div class="template-setting-window-header">
            <div class="template-setting-window-header-name">
              <strong>Превышение скорости</strong>
              <br />
              <small>Настройка отчета</small>
            </div>
            <!-- Картинка крестика-->
            <img
              data-element="close"
              class="close"
              src="/images/close.png"
              @click="() => emit('close', CLOSE_MESSAGE)"
            />
          </div>
          <div
            class="unselectable mt-3"
            style="overflow-y: scroll; max-height: calc(85vh - 115px)"
          >
            <!-- Пока не доступно -->
            <!-- <ui-field
            value="0"
            class="d-flex w-25"
            type="number"
            label="Предел скорости вне зоны"
          /> -->

            <ui-table>
              <template #default>
                <tr>
                  <td class="w-50">
                    <ui-label
                      for="maxOverTime"
                      html="Допустимое время превышения (сек.)"
                    />
                  </td>
                  <td>
                    <ui-field
                      v-model:value="form.maxOverTime.value"
                      id="maxOverTime"
                      class="mb-0"
                      style="width: 150px"
                      :error="form.getError(form.maxOverTime)"
                      @blur="() => form.maxOverTime.blur()"
                    />
                  </td>
                </tr>
                <tr
                  class="d-none"
                  style="height: 33px"
                >
                  <td class="w-50">
                    <ui-label
                      class="w-100"
                      for="detailWithMaps"
                      html="Детализация с картами"
                    />
                  </td>
                  <td>
                    <label
                      class="d-flex align-items-center w-100"
                      style="height: 33px"
                    >
                      <input
                        id="detailWithMaps"
                        class="mb-0"
                        v-model="form.detailWithMaps.value"
                        type="checkbox"
                      />
                    </label>
                  </td>
                </tr>
                <tr
                  class="d-none"
                  style="height: 33px"
                >
                  <td class="w-50">
                    <ui-label
                      class="w-100"
                      for="linkToGoogleMaps"
                      html="Ссылка на карты Google"
                    />
                  </td>
                  <td>
                    <label
                      class="d-flex align-items-center w-100"
                      style="height: 33px"
                    >
                      <input
                        id="linkToGoogleMaps"
                        class="mb-0"
                        v-model="form.linkToGoogleMaps.value"
                        type="checkbox"
                      />
                    </label>
                  </td>
                </tr>
                <tr>
                  <td class="w-50">
                    <ui-label
                      for="speedOn"
                      html="Скорость по"
                    />
                  </td>
                  <td>
                    <select
                      v-model="form.speedOn.value"
                      class="select"
                      style="width: 150px"
                      name="speedOn"
                      id="speedOn"
                    >
                      <option value="gps">GPS/ГЛОНАСС</option>
                      <option value="can">CAN</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="w-50">
                    <ui-label html="Вид геозон" />
                  </td>
                  <td style="padding: 0">
                    <div class="d-flex flex-column align-items-start">
                      <table>
                        <tr style="height: 20px">
                          <td style="width: 15px">
                            <input
                              type="checkbox"
                              id="check-all-geofences-types"
                              checked
                              title="Выбрать все"
                              @click.prevent="() => checkAllGeofencesTypes()"
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              id="check-all-geofences-types"
                              title="Снять все"
                              @click.prevent="() => clearGeofencesTypes()"
                            />
                          </td>
                        </tr>
                        <template v-for="obj of geofenceTypes">
                          <tr>
                            <td style="width: 15px">
                              <input
                                :id="`geofencesType-${obj.value}`"
                                class="mb-0"
                                v-model="form.geofencesTypes.value"
                                :value="obj.value"
                                type="checkbox"
                              />
                            </td>
                            <td>
                              <ui-label
                                class="w-100"
                                :for="`geofencesType-${obj.value}`"
                                :html="obj.text"
                              />
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                  </td>
                </tr>
              </template>
            </ui-table>
          </div>
        </div>
        <div class="center-tag mb-3">
          <span
            class="target-show"
            @mouseenter="addHoverOnTargetByParent"
            @mouseleave="removeHoverOnTargetByParent"
          >
            <input
              class="btn config-btn-primary btn-sm"
              :class="[!!error ? 'btn-danger' : 'btn-primary']"
              type="submit"
              :value="!!error ? 'Ошибка' : 'Выполнить отчет'"
              :disabled="!form.valid"
              @click="submit"
            />
            <span class="target-show-span">
              {{ error || 'Обсчет данных для получения отчета' }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div
      @click="() => emit('close', CLOSE_MESSAGE)"
      data-element="close"
      id="background-wrap"
      class="background-wrap"
      style="display: flex"
      wfd-invisible="true"
    ></div>
    <overspeed
      :trigger="trigger"
      :form_desc="props.form_desc"
      :button="props.button"
      :target-span="props.targetSpan"
      :params="params"
      @reset-trigger="() => resetTrigger()"
    />
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';

import UiTable from '@/ManualApp/Components/Tables/UiTable.vue';
import UiLabel from '@/ManualApp/Components/Fields/UiLabel.vue';
import UiField from '@/ManualApp/Components/Fields/UiField.vue';

import Overspeed from './Overspeed.vue';

import { useForm } from '@/ManualApp/use/form';
import { useValidator } from '@/ManualApp/use/validator';

import {
  addHoverOnTargetByParent,
  removeHoverOnTargetByParent,
} from '@/App/helpers/targetsHelper';

const { required, numeric } = useValidator;

const props = defineProps({
  form_desc: { type: Object, default: {} },
  button: { type: Object, default: {} },
  targetSpan: { type: Object, default: {} },
  isShowing: {
    type: Boolean,
    require: true,
  },
});

const emit = defineEmits(['close']);

const CLOSE_MESSAGE = 'overspeedSettings';

const allGeofences = inject('allGeofences');
const geofenceTypes = inject('geofenceTypes');

const trigger = ref(false);
const params = ref({});

const error = ref('');

const form = useForm({
  maxOverTime: { value: '5', validators: { required, numeric } },
  detailWithMaps: { value: true },
  linkToGoogleMaps: { value: true },
  speedOn: { value: 'gps' },
  geofencesTypes: { value: [] },
});

watch(
  () => props.isShowing,
  () => {
    error.value = '';
  },
);

onMounted(() => {
  checkAllGeofencesTypes();
});

function submit() {
  error.value = '';

  params.value.maxOverTime = form.maxOverTime.value;
  params.value.detailWithMaps = form.detailWithMaps.value;
  params.value.linkToGoogleMaps = form.linkToGoogleMaps.value;
  params.value.speedOn = form.speedOn.value;

  const { ids, geofences } = allGeofences.reduce(
    (acc, geo) => {
      if (form.geofencesTypes.value.includes(geo.type)) {
        acc.ids.push(geo.id);
        acc.geofences.push(geo);
      }
      return acc;
    },
    {
      ids: [],
      geofences: [],
    },
  );

  params.value.geofencesIds = ids;
  params.value.geofences = geofences;

  if (!ids.length) {
    error.value = 'Геозоны не найдены';
    return;
  }

  trigger.value = true;

  emit('close', CLOSE_MESSAGE);
}

function checkAllGeofencesTypes() {
  form.geofencesTypes.value = geofenceTypes.map((obj) => obj.value);
}

function clearGeofencesTypes() {
  form.geofencesTypes.value.length = 0;
}

function resetTrigger() {
  trigger.value = false;
}
</script>

<style scoped>
.select {
  background: #fff;
  border: 1px solid #4a4c53;
  padding: 8px 12px;
  border-radius: 4px;
  outline: none;
}
.center-tag {
  text-align: center;
}
.template-setting-window {
  display: flex;
  padding: 4px 8px;
}
.my-underline {
  text-decoration: underline;
  cursor: pointer;
}
.checkbox-target {
  color: blue;
}
</style>
