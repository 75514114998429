<template>
  <table>
    <caption align="left">
      {{
        tableCaption
      }}
    </caption>
    <thead>
      <tr>
        <th
          v-for="column in columns"
          :key="column"
          :data-minwidth="settingColumns[column].dataMinWidth || ''"
          :style="settingColumns[column].style || {}"
          data-excelalignh="center"
        >
          {{ settingColumns[column]['text'] }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in rows"
        :key="index"
      >
        <regular-moving-by-geo-template-table-element-column
          v-for="column in columns"
          :key="column"
          :detailTable="getRegularMovingColumnDetailTable(row, column, index)"
          :htmlText="getHtmlText(row, column, index)"
          :dataFormatnum="settingColumns[column].formatNum || 0"
          :dataValue="getDataValueByExcel(row, column, index)"
          :showDetailTable="showDetailTable"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import RegularMovingByGeoTemplateTableElementColumn from './RegularMovingByGeoTemplateTableElementColumn.vue';

export default {
  components: {
    RegularMovingByGeoTemplateTableElementColumn,
  },
  SECONDS_PER_DAY: 86400,
  props: {
    tableCaption: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    settingColumns: {
      type: Object,
      required: true,
    },
    replaceNbsp_helper: {
      type: Function,
      required: true,
    },
    getRegularMovingColumnValue: {
      type: Function,
      required: true,
    },
    getRegularMovingColumnDataValue: {
      type: Function,
      required: true,
    },
    getRegularMovingColumnDetailTable: {
      type: Function,
      required: true,
    },
    showDetailTable: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getHtmlText(row, column, index) {
      const formatNum = this.settingColumns[column].formatNum || 0;
      const value = this.getRegularMovingColumnDataValue(row, column, index);
      if (formatNum === 4 && value && !isNaN(value)) {
        return formatToDisplay_helper(
          value / this.$options.SECONDS_PER_DAY,
          formatNum,
        ); // см. main_helper_010.js
      }
      return formatToDisplay_helper(value, formatNum); // см. main_helper_010.js
    },
    getDataValueByExcel(row, column, index) {
      const formatNum = this.settingColumns[column].formatNum || 0;
      let result = '';
      if (formatNum >= 1 && formatNum <= 4) {
        const value = this.getRegularMovingColumnDataValue(row, column, index);
        if (value && !isNaN(value)) {
          switch (formatNum) {
            case 1:
            case 1.1:
            case 1.5:
            case 1.6:
              result =
                value / (this.$options.SECONDS_PER_DAY * 1000) +
                25569 -
                new Date().getTimezoneOffset() / 1440; // 1440 = 60 * 24;
              break;
            case 4:
              result = value / this.$options.SECONDS_PER_DAY;
              break;
            default:
              result = value;
          }
          // if ([1, 1.1, 1.5, 1.6].includes(formatNum)) {
          //   return (value / (this.$options.SECONDS_PER_DAY * 1000)) + 25569 - (new Date().getTimezoneOffset() / 1440); // 1440 = 60 * 24;
          // }
          // if (formatNum === 4) {
          //   return value / this.$options.SECONDS_PER_DAY
          // }
          // return value;
        }
      }

      return String(result);
    },
  },
};
</script>

<style scoped>
table {
  margin: 6px 3px;
  text-align: center;
}
caption {
  text-align: left;
  caption-side: top;
}
</style>
