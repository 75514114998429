export const getSkillsManDetailTemplateHtml = (detailColumnsString) => {
  return `
    <div id="skillsManDetailsTemplateHtmlExportButton">
        <button class="not-print float-left"
        onclick="html_to_excel_helper.export( this, 'skills-man-detail-template-content', 'Навыки MAN детализация', 'landscape' )">
        Экспорт&nbsp;в&nbsp;EXCEL 
        </button>
    </div>
    <!-- <input type="button" onclick="table_export_helper('skills-man-detail-template-content-table')" value="Export to Excel"> -->

    <div class="temlate-report" id="skills-man-detail-template-content">
      
      

      <% 
          let styleTable = 'border="1" cellpadding="5" style="border-collapse:collapse; border:1px solid black; caption-side: top; margin-right: 5px;"';
          let styleCaption = 'style="caption-side:top;"';
          let splitTrClass = 'class="border-bottom-tr"';
          let detailColumns = [${detailColumnsString}]; 
          <!-- let detailColumns = [];  -->
          let isAllId = violationsFilter.isAllId;
          let detailViolationsDesc = violationsFilter.detailViolationsDesc;
          let filterInfo = (isAllId) ? 'выводятся все нарушения' : '<span style="color:red">фильтр по нарушениям включен!</span>';
          
          for (let i = 0; i < header.transmissionLockArr.length; i++) {
              detailColumns.push('D' + header.transmissionLockArr[i].nameArr);
          }
          detailColumns.push('DdrvTaho1');
          detailColumns.push('DdrvTahoState1');
          detailColumns.push('DdrvTaho2');
          detailColumns.push('DdrvTahoState2');
          detailColumns.push('DComment');
          let DCommentPadding = ' ';
      %>

    <h5 id="skillsManDetailsTemplateHtmlTitle" class="text-center footer-desc">
    <%= header.reportName + ' ' + header.reportPeriod + ' (' + filterInfo + ')' %>
    </h5>
      <!-- <hr class="not-print"> -->

      <table class="text-center sortable-table freezeTable-table" data-excelautofilter <%= styleTable %> id="skills-man-detail-template-content-table">
          <thead>
              <tr class="border-bottom-tr print-repeat print-frozen-row" >
                  <% for (let j = 0; j < detailColumns.length; j++) {
                      let key = detailColumns[j];
                      if (! (key in header) || ! ('minWidth' in header[key])) {
                          console.error('not key: ', key + Boolean(key in header));
                      }
                      let colWidthHtml = !header[key]['minWidth'] ? '' : ' style="min-width:'+Math.round(header[key]['minWidth']*8.7)+'px;"';
                      %>
                      <th data-excelalignh="center" data-excelalignv="middle" data-minwidth="<%= header[key]['minWidth'] %>"<%= colWidthHtml %>><%= header[key]['name'] %></th>
                  <% } %>
              </tr>
          </thead>

          <tbody>
                  <% for (let j = 0; j < detailViolationRows.length; j++) {
                      for (let jj = 0; jj < detailViolationRows[j].length; jj++) {
                          let lineValues = detailViolationRows[j][jj];
                          let violationsId = lineValues['violationsId'];
                          let violationsDesc = '';
                          let violationsColor = '';
                          let violationsColorColumns = {};

                          for (let z = 0; z < violationsId.length; z++) {
                              if (violationsDesc.length) violationsDesc += ';<br>';
                              let violId = violationsId[z];
                              let violDesc = getVolationDesc_helper(violId, violationsDescriptions);
                              violationsDesc += violDesc['description'];
                              violationsColor = violDesc['color'];
                              
                              let violColumnsColor = violDesc['selection_of_columns'].split(',');
                              for (let zz = 0; zz < violColumnsColor.length; zz++) {
                                  if (! (violColumnsColor[zz] in accordanceColumns)) continue;
                                  let colName = accordanceColumns[violColumnsColor[zz]];
                                  violationsColorColumns[colName] = violationsColor;
                              }
                          } 

                          violationsDesc = String(violationsDesc)
                              .replace("clutch_time_uninterruptedly", lineValues['clutch_time_uninterruptedly'])
                              .replace("pto_cnt_violation", lineValues['pto_cnt_violation'])
                              .replace("spd_accel", lineValues['spd_accel'] / 10)
                              .replace("iobj_accelLimit", lineValues['iobj_accelLimit']);
                          
                          let splitStr = '';
                          if (jj == detailViolationRows[j].length - 1) {
                              splitStr = splitTrClass;
                          } 
                          
                          if (detailViolationRows[j][jj]?.selected) {
                              splitStr = ' style="border: 2px solid #999" '
                          }
                          %>
                          <tr <%= splitStr %>>
                          <% for (let k = 0; k < detailColumns.length; k++) {
                              let key = detailColumns[k]; 
                              let formatNum = header[key]['format'];
                              let innerVal;
                              if (key == 'DComment') {
                                  innerVal = violationsDesc;
                              } else {
                                  innerVal = lineValues[key];
                              } 
                              let tdColor = '';
                              if (key in violationsColorColumns) {
                                  tdColor = 'bgcolor="' + violationsColorColumns[key] + '"';
                              }
                              let data_formatNum_value = addFormatAndValAttributesFromLodash_helper(formatNum, innerVal);
                              if (DCommentPadding !== ' ') {
                                  DCommentPadding = '';
                              }
                              if ( key == 'DComment' && ! innerVal && DCommentPadding === ' ') {
                                  DCommentPadding = ' style="padding: 0 150px;"';
                              }
                              
                              
                              %>
                              <td data-excelalignh="center" <%=tdColor + ' ' + data_formatNum_value + DCommentPadding%>><%= format(innerVal, formatNum) %></td>
                          <% } %>
                          </tr>
                      <% } %>
                  <% } %>
              </tbody>


      </table>
    </div>
  `;
};
