export const calcElementPosition = (event, mobileEvents, targetSpan) => {
  if (mobileEvents.isTargetTouch) {
    return;
  }
  let x = event.clientX;
  let y = event.clientY;

  if (!targetSpan) return;

  let windowWidth = window.innerWidth;

  if (windowWidth > 767) {
    let rightSpace = windowWidth - x - 15;
    targetSpan.style.maxWidth = rightSpace + 'px';
    let elemHeight = targetSpan.offsetHeight;
    let windowHeight = window.innerHeight;
    let targetSpanBottomX = y - 15 + elemHeight;

    if (targetSpanBottomX > windowHeight) {
      targetSpanBottomX = windowHeight - 15;
    }
    targetSpan.style.top = targetSpanBottomX - elemHeight + 'px';
    targetSpan.style.left = x + 15 + 'px';
  } else {
    // from mobile
    targetSpan.style.left = 2 + 'px';
    targetSpan.style.width = windowWidth - 20 + 'px';

    let elemHeight = targetSpan.offsetHeight;
    let windowHeight = window.innerHeight;
    if (windowHeight / 2 > y) {
      // show on bottom
      targetSpan.style.top = y - elemHeight - 7 + 'px';
    } else {
      // show on top
      targetSpan.style.top = y - elemHeight - 5 + 'px';
    }
  }
};
