import OurAggregated from '@/src/dataRequest/OurAggregatedClass';

import { IPeriod, Smenas } from '../Smenas';

import {
  aggregationCalculating,
  TSettingOfValues,
} from '../AggregationCalculating';

import type {
  AggregationResponse,
  DiagnosticAggregationPosition,
  DiagnosticRaw,
  DiagnosticRawArray,
} from './Aggregation';

import {
  getPositionsFromOur,
  ITreatmentPositions,
} from '@/App/TemplateComponents/ExcavatorOperation/getPositionsFromOur';

import {
  addCopyArrOfObjects_helper,
  getPercent,
} from '@/helpers/main_helper_010';
import { IPosition } from './Type';
import { TProcessCallback } from '@/types/TProcessCallback';
import { DiagnosticNext } from './Detail';

export type ReportName = 'diagnostic' | 'diagnosticDetail';
export type Id = string;
export type Response = AggregationResponse<DiagnosticAggregationPosition>;

export type TOnSuccess = (result: ReportCalculated) => void;

export interface ReportOptions {
  objects: Id[];
  from: Date;
  to: Date;
  templateName: ReportName;
}

export interface Interval {
  from: number;
  to: number;
}

export type DiagnosticRow = {
  [T in keyof DiagnosticRaw]: DiagnosticRaw[T];
};

export interface ObjectDiagnostic {
  name: string;
  stateNumber: string;
  Periods: any;
}

export interface ReportCalculated {
  objects: ObjectDiagnostic[];
  responseInterval: Interval;
  templateSettings?: any;
}

const DETAIL_COLUMNS = ['time', 'engine_time', 'dgnc', 'is_last_day_pos'];

const ROUND_TO = 5; // округление по 5 минут
const OPTION = 5;

const AGGREGATION_ARRAY_TEMPLATE = {
  begin: 0,
  interval: 1,
  code: 2,
  MIF: 3,
  IS: 4,
  OC: 5,
  lamp1: 6,
  lamp2: 7,
  addr: 8,
  priority: 9,
};
const DETAIL_ARRAY_TEMPLATE = {
  code: 0,
  MIF: 1,
  IS: 2,
  OC: 3,
  lamp1: 4,
  lamp2: 5,
  addr: 6,
  priority: 7,
  counter: 8,
};

export default class {
  private _interval: Interval = { from: 0, to: 0 };

  private _smenas: Smenas;

  private _onProcess: TProcessCallback | null;
  private _onSuccess: TOnSuccess | null;

  constructor() {
    this._onProcess = null;
    this._onSuccess = null;

    this._smenas = new Smenas({ SmenasFlag: false });
  }

  getReport(
    { objects, from, to, templateName }: ReportOptions,
    templateSettings: ReportCalculated['templateSettings'],
  ): void {
    this._smenas.resetSettings();

    if (this._onProcess) {
      this._onProcess('success', 'Обсчет данных для получения отчета');
    }

    this._smenas.getSmenasSetting(ROUND_TO, +from, +to);

    if (this._smenas.badSmenasFlag) {
      if (this._onProcess) {
        this._onProcess('error', 'Смены были заданы неверно');
      } else {
        console.error('Смены были заданы неверно');
      }
      return;
    } else {
      if (this._onProcess) {
        this._onProcess('success', 'Обсчет данных для получения отчета');
      }
    }

    this._interval.from = this._roundMinutes(new Date(+from), ROUND_TO);
    this._interval.to = this._roundMinutes(new Date(+to), ROUND_TO);

    if (templateName === 'diagnostic') {
      this._requestAggregation(objects, templateName, templateSettings);
    } else {
      this._requestDetail(objects);
    }
  }

  private _requestAggregation(
    objects: ReportOptions['objects'],
    templateName: ReportOptions['templateName'],
    templateSettings: ReportCalculated['templateSettings'],
  ): void {
    const ourAggregated = new OurAggregated('diagnostic', {
      timeBegin: this._interval.from,
      timeEnd: this._interval.to,
    });

    if (this._onProcess) {
      ourAggregated.onProcess(this._onProcess as TProcessCallback);
    }

    ourAggregated.onSuccess<Response[]>((responses) => {
      try {
        const reportCalculated = this._calculate(responses);

        reportCalculated.templateSettings = templateSettings;
        if (this._onSuccess) this._onSuccess(reportCalculated);
      } catch (error) {
        if (this._onProcess) {
          const type = `error`;
          const message = `Произошла ошибка при обсчете данных. Повторите запрос, либо перезагрузите страницу`;
          this._onProcess(type, message);
        }
      }
    });

    ourAggregated.callOurAggregated(
      objects.map((id) => ({ id })),
      [],
      OPTION,
    );
  }

  private async _requestDetail(
    objectsIds: ReportOptions['objects'],
  ): Promise<void> {
    const countOfQueries = objectsIds.length;
    let numberOfResponse = 0;

    const objects: ObjectDiagnostic[] = [];
    for (const id of objectsIds) {
      try {
        const treatmentPositions = await getPositionsFromOur({
          objId: id,
          processCallback: () => {},
          isOnMap: true,
          interval: this._interval,
          columns: DETAIL_COLUMNS,
        });

        if (!treatmentPositions) continue;

        numberOfResponse++;

        if (this._onProcess) {
          const percent = getPercent(numberOfResponse, countOfQueries);
          this._onProcess(
            'loading',
            `Выполнено ${numberOfResponse} из ${countOfQueries} (${percent}%) `,
            percent,
          );
        }

        const object = this._calculateDiagnosticDetail(treatmentPositions);

        objects.push(object);
      } catch (error) {
        if (this._onProcess) {
          const type = `error`;
          const message = `Произошла ошибка при запросе данных. Повторите запрос, либо перезагрузите страницу`;
          this._onProcess(type, message);
          break;
        }
      }
    }

    if (this._onSuccess) {
      this._onSuccess({
        objects,
        responseInterval: this._interval,
      });
    }
  }

  private _roundMinutes(date: Date, roundTo: number): number {
    const minutes = date.getMinutes();

    date.setMinutes(minutes - (minutes % roundTo));

    return date.getTime();
  }

  private _calculate(Responses: Response[]): ReportCalculated {
    try {
      const objects: ReportCalculated['objects'] = [];
      for (const response of Responses) {
        const { posArray, objConf, error } = response;
        if (error) {
          console.error(error, response);
          continue;
        }

        const Periods = addCopyArrOfObjects_helper(
          this._smenas.setSmenas.Periods || [],
        ) as IPeriod[];

        if (!Periods) continue;

        aggregationCalculating(
          posArray,
          this._settingOfValues,
          Periods,
          this._interval.from,
          this._interval.to,
        );

        this._calculateDiagnostic(posArray, Periods);

        objects.push({
          name: objConf.name,
          stateNumber: objConf.state_number,
          Periods,
        });
      }

      return {
        objects,
        responseInterval: this._interval,
      };
    } catch (error) {
      console.error(error);

      return {
        objects: [],
        responseInterval: this._interval,
      };
    }
  }

  private _calculateDiagnosticDetail(
    treatmentPositions: ITreatmentPositions,
  ): ObjectDiagnostic {
    const { objName, stateNumber, points } = treatmentPositions;

    const Periods = addCopyArrOfObjects_helper(
      this._smenas.setSmenas.Periods || [],
    ) as IPeriod[];

    aggregationCalculating(
      points.allValues,
      this._settingOfValues,
      Periods,
      this._interval.from,
      this._interval.to,
    );

    let i = 0;
    for (const period of Periods) {
      const { TimeBegin, TimeEnd } = period;
      if (!TimeBegin || !TimeEnd) {
        console.error('Period interval is not defined', TimeBegin, TimeEnd);
        continue;
      }

      period.diagnostic = [];

      for (i; i < points.allValues.length; i++) {
        const position = points.allValues[i];

        const diagnosticRaw: IPosition['dgnc'] = position.dgnc;

        if (position.time < TimeEnd) {
          if (Array.isArray(diagnosticRaw)) {
            const isPresecenceSignals = this.checkPresenceSignals(
              diagnosticRaw,
              DETAIL_ARRAY_TEMPLATE,
            );

            if (!isPresecenceSignals) {
              continue;
            }

            period.diagnostic.push([position.time / 1000, ...diagnosticRaw]);
          } else {
            const {
              dgnc_spn = null,
              dgnc_fmi = null,
              dgnc_cm = null,
              dgnc_oc = null,
              dgnc_lamp1 = null,
              dgnc_lamp2 = null,
              dgnc_addr = null,
              dgnc_priority = null,
              dgnc_counter = null,
            } = diagnosticRaw || {};

            const diagnosticArray: DiagnosticNext = [
              dgnc_spn,
              dgnc_fmi,
              dgnc_cm,
              dgnc_oc,
              dgnc_lamp1,
              dgnc_lamp2,
              dgnc_addr,
              dgnc_priority,
              dgnc_counter,
            ];

            const isPresecenceSignals = this.checkPresenceSignals(
              diagnosticArray,
              DETAIL_ARRAY_TEMPLATE,
            );

            if (!isPresecenceSignals) {
              continue;
            }

            period.diagnostic.push([position.time / 1000, ...diagnosticArray]);
          }
        } else {
          // value in next period
          break;
        }
      }
    }

    return {
      name: objName || 'н/у',
      stateNumber: stateNumber || 'г/н н/у',
      Periods,
    };
  }

  private _settingOfValues: TSettingOfValues = {
    time: [0.001, false],
    cnt_real_pos: [1, true],
    engine_time: [1, true],
  };

  private checkPresenceSignals(
    dgnc: DiagnosticRaw | DiagnosticNext,
    template: typeof AGGREGATION_ARRAY_TEMPLATE | typeof DETAIL_ARRAY_TEMPLATE,
  ) {
    const lamp1 = dgnc[template['lamp1']];

    if (lamp1 === null || lamp1 === undefined) {
      return false;
    }

    if (((lamp1 >> 2) & 3) === 1) {
      // Yellow signal
      return true;
    }

    if (((lamp1 >> 4) & 3) === 1) {
      // Red signal
      return true;
    }

    return false;
  }

  private _calculateDiagnostic(
    posArray: Response['posArray'],
    Periods: IPeriod[],
  ) {
    const temp: { [posDiagnostic: string]: any } = {};

    for (
      let positionIndex = 0;
      positionIndex < posArray.length;
      positionIndex++
    ) {
      const position = posArray[positionIndex];

      const diagnosticRawArray: DiagnosticRawArray =
        typeof position.dgnc === 'string'
          ? JSON.parse(position.dgnc)
          : position.dgnc;

      if (diagnosticRawArray !== null) {
        for (let index = 0; index < diagnosticRawArray.length; index++) {
          const [
            begin,
            interval,
            code,
            MIF,
            IS,
            OC,
            lamp1,
            lamp2,
            addr,
            priority,
          ] = diagnosticRawArray[index];

          const posEntry = [
            begin,
            code,
            MIF,
            IS,
            OC,
            lamp1,
            lamp2,
            addr,
            priority,
          ].join('#');

          temp[posEntry] = diagnosticRawArray[index];
        }
      }
    }

    const tempValues = Object.values(temp);

    let i = 0;
    for (const period of Periods) {
      const { TimeBegin, TimeEnd } = period;
      if (!TimeBegin || !TimeEnd) {
        console.error('Period interval is not defined', TimeBegin, TimeEnd);
        continue;
      }

      period.diagnostic = [];

      for (i; i < tempValues.length; i++) {
        const [time] = tempValues[i];
        const unixTime = time * 1000;

        const isPresecenceSignals = this.checkPresenceSignals(
          tempValues[i],
          AGGREGATION_ARRAY_TEMPLATE,
        );

        if (!isPresecenceSignals) {
          continue;
        }

        if (unixTime < TimeEnd) {
          period.diagnostic.push(tempValues[i]);
        } else {
          // value in next period
          break;
        }
      }
    }
  }

  onProcess(listener: TProcessCallback): this {
    this._onProcess = listener;
    return this;
  }
  onSuccess(listener: TOnSuccess): this {
    this._onSuccess = listener;
    return this;
  }
}
