import { getUnixByDate } from '@/helpers/main_helper_010';
import { D3LinearChart } from '../d3/D3LinearChart';
import fetchJson from '../src/dataRequest/fetch-json';
import {
  ajaxPositionsDoneAll,
  concatOtherPositions,
} from '../App/use/getElementPosition';
import { useChart } from './chart';

// Статус уровнемера:
// 0 - 4 - первые два байта - важность уровнемера
// 8 - если установлено то это суммарный уровнемер
// 16 - событие заправка
// 32 - идет заправка
// 64 - событие слив
// 128 - идет слив

export class TrackChartsMotion {
  // track-chart-${id}
  constructor({
    mainWrapper,
    trackChartsSettingIframe,
    panel,
    points,
    leafletMain,
    objName,
    chartAreaId,
    stateNumber,
    getBegin,
    getEnd,
    chartSubelements,
    panelSubelements,
    otherQueries,
    chartNames = [
      'engineWorking',
      'speed',
      'rpm',
      'gps',
      'gsm',
      'kren',
      'tang',
      'pwr',
      'bat',
      'distance',
      'consumptionAndAnalyzedCharts',
    ],
    displayedCharts = ['distance'],
  } = {}) {
    this.chartAreaId = chartAreaId;
    this.chartSubelements = chartSubelements;
    this.panelSubelements = panelSubelements;
    const { trackChartWrapperInner } = chartSubelements;
    this.trackChartWrapperInner = trackChartWrapperInner;
    this.trackChartsSettingIframe = trackChartsSettingIframe;

    const [drawArea] = panel.getElementsByClassName('track-charts-panel');
    this.drawArea = drawArea;
    this.mainWrapper = mainWrapper;

    this.panel = panel;
    this.getBegin = getBegin;
    this.getEnd = getEnd;

    this.points = points;
    this.leafletMain = leafletMain;
    this.stateNumber = stateNumber;
    this.objName = objName;

    this.otherQueries = otherQueries;

    this.markerIcon = leafletMain.createIcon({
      iconUrl: '/images/markers/marker-icon-green.png',
      shadowUrl: '/images/markers/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });

    this.chartNames = chartNames;
    this.displayedCharts = displayedCharts;

    setTimeout(() => {
      try {
        this.chartCreate({ panel, points, getBegin, getEnd });
        this.addEventListeners();
      } catch (err) {
        console.error(err);
      }
    }, 0);

    return this;
  }

  addCharts({ columns, chartNames, levels, fuelSettings, positions }) {
    this.concatThisPoints(columns, fuelSettings, positions.points);

    const dataValues = useChart.getDatasetsFromOurPositions(
      this.points,
      fuelSettings,
    );

    const charts = this.getCharts(dataValues, chartNames, levels);

    const axes = this.getAxes(this.chart.minMax, charts);

    const margin = this.getChartsMargin(axes);

    this.chart.addCharts(charts, axes, margin);
  }

  concatThisPoints(columns, fuelSettings, points) {
    if (!columns.length && !Object.keys(fuelSettings).length) return;

    const newPositions = points.allValues.reduce((accum, pos) => {
      accum[pos.time] = pos;
      // delete pos.time;
      return accum;
    }, {});

    this.points.allValues.forEach((curPosition, index) => {
      const { time } = curPosition;
      const newPos = newPositions[time] || {};

      for (const key of columns) {
        const value = newPos[key];

        if (!index || value !== null) {
          curPosition[key] = value;
        }
      }

      for (const settingKey in fuelSettings) {
        for (const setting of fuelSettings[settingKey]) {
          const { tDetailNum } = setting;
          for (const key in newPos) {
            if (key.indexOf(tDetailNum) > -1) {
              curPosition[key] = newPos[key];
            }
          }
        }
      }
    });
  }

  chartCreate({ panel, points, getBegin, getEnd, fuelSettings } = {}) {
    const dataValues = useChart.getDatasetsFromOurPositions(
      points,
      fuelSettings,
    );

    const { required } = dataValues;
    const minMax = {
      min: new Date(getBegin * 1000),
      max: new Date(getEnd * 1000),
    };

    const minWidth = 250;
    const minHeight = 250;
    let width = panel.getBoundingClientRect().width;
    if (width < minWidth) width = minWidth;

    let height = 0.4 * document.documentElement.clientHeight;
    if (height < minHeight) height = minHeight;

    const charts = this.getCharts(
      dataValues,
      this.chartNames,
      [],
      this.displayedCharts,
    );
    const axes = this.getAxes(minMax, charts);
    const margin = this.getChartsMargin(axes);

    const config = {
      requiredPositions: required,
      width,
      height,
      isLegend: true,
      legendTextSize: '14px',
      legendRectWidth: 15,
      infoTitleSize: '14px',
      infoTitleBgFill: 'rgba(211, 211, 211,0.8)',
      dataTextSize: '14px',
      dataTextFontWeight: 'bold',
      margin,
      charts,
      axes,
    };

    this.chart = new D3LinearChart({ d3, elementId: this.chartAreaId, config });

    this.chart.minMax = minMax;
  }

  getChartsMargin(axes = []) {
    const margin = {
      top: 5,
      left: 0,
      bottom: 55,
      right: 0,
    };

    const ids = [];
    axes.forEach(({ id }) => {
      if (ids.includes(id)) return;

      if (['yHours', 'yGpsGsmPwr', 'ySpeed'].includes(id)) {
        margin.left += 60;
      }
      if (['yDistance', 'yLevelConsumption', 'yRpm'].includes(id)) {
        margin.right += 60;
      }

      ids.push(id);
    });

    return margin;
  }

  getCharts(dataValues, chartNames, levels = [], displayedCharts = []) {
    const charts = [];

    if (!!levels.length) {
      charts.push(
        ...useChart.charts.consumptionAndAnalyzedCharts(
          dataValues['labels'],
          dataValues['levels'],
          true,
        ),
      );
    }

    chartNames.forEach((name) => {
      if (name === 'engineWorking') {
        charts.push(
          useChart.charts.engineWorking(
            dataValues['labels'],
            dataValues['moto1Fill'],
            !displayedCharts.includes(name),
          ),
        );
        return;
      }

      if (name === 'distance' || name === 'distSumm') {
        charts.push(
          useChart.charts.distance(
            dataValues['labels'],
            dataValues['distSumm'],
            !displayedCharts.includes(name),
          ),
        );
        return;
      }

      charts.push(
        useChart.charts[name](
          dataValues['labels'],
          dataValues[name],
          !displayedCharts.includes(name),
        ),
      );
    });

    return charts;
  }
  getAxes(minMax, charts) {
    const chartLabelsByAxesName = {};

    charts.forEach((chart) => {
      if (!(chart.xAxis in chartLabelsByAxesName)) {
        chartLabelsByAxesName[chart.xAxis] = [];
      }
      if (!(chart.yAxis in chartLabelsByAxesName)) {
        chartLabelsByAxesName[chart.yAxis] = [];
      }

      if (!chartLabelsByAxesName[chart.xAxis].includes(chart.label)) {
        chartLabelsByAxesName[chart.xAxis].push(chart.label);
      }

      if (!chartLabelsByAxesName[chart.yAxis].includes(chart.label)) {
        chartLabelsByAxesName[chart.yAxis].push(chart.label);
      }
    });

    const axes = [];

    for (const axesName in chartLabelsByAxesName) {
      const chartLabels = chartLabelsByAxesName[axesName];

      if (axesName === 'xDateTime') {
        axes.push(useChart.axes[axesName](minMax, chartLabels));
        continue;
      }

      axes.push(useChart.axes[axesName](chartLabels));
    }

    return axes;
  }

  selectionValuesByAngle({ data = [], indexBegin, indexEnd, label } = {}) {
    const { min = null, max = null } = this.minMaxOfDataCalculate({
      data,
      indexBegin,
      indexEnd,
      key: 'y',
    });
    if (min === null || max === null) {
      return `${label} -`;
    }

    const dispersion = max - min;
    return `${label} min: ${Math.round(min * 100) / 100}, max: ${
      Math.round(max * 100) / 100
    }, разброс: ${Math.round(dispersion * 100) / 100} (град)`;
  }

  minMaxOfDataCalculate({ data, indexBegin, indexEnd, key = 'y' } = {}) {
    const minMax = {};
    for (let i = indexBegin; i < indexEnd + 1 && i < data.length - 1; i++) {
      const val = data[i][key];
      if (i === indexBegin) {
        minMax.min = val;
        minMax.max = val;
        continue;
      }
      if (val > minMax.max) {
        minMax.max = val;
      }
      if (val < minMax.min) {
        minMax.min = val;
      }
    }

    return minMax;
  }

  getDatasets(points) {
    const { allValues } = points;

    const result = {
      labels: [],
      speed: [],
      distSumm: [],
      gps: [],
      gsm: [],
      kren: [],
      tang: [],
      rpm: [],
      pwr: [],
      bat: [],
      moto1Fill: [],
      levels: {},
      required: [],
    };

    const levels = result.levels;
    const leveCount = 10;
    const levelsDesc = [
      { purpose: 'consumption', purposeText: 'расходный' },
      { purpose: 'cistern', purposeText: 'цистерна' },
    ];

    const intervalMoto1 = {
      value: 0,
      prev: false,
      prevTime: 0,
    };

    let curSpeed = 0;

    let prevGps = 0;

    let f = 0;
    allValues.forEach((values, index) => {
      const {
        time,
        speed,
        distSumm: distSummOrigin,
        gsm = null,
        gps = null,
        rpm,
        kren,
        tang,
        pwr,
        bat,
        moto_mask,
      } = values;
      result.required[index] = { val: false, because: '' };

      if (speed < 2500) {
        // 250 км/ч
        curSpeed = speed;
      }

      if (intervalMoto1.prev) {
        intervalMoto1.value += time - intervalMoto1.prevTime;
      }

      const moto1isWork = Boolean(moto_mask & 1);

      if (intervalMoto1.prev !== moto1isWork) {
        result.required[index - 1] = { val: true, because: 'moto1Fill - 1' };
        result.required[index] = { val: true, because: 'moto1Fill' };
      }

      result.labels[index] = new Date(time);

      // result.labels[index] = time / 1000;
      result.speed[index] = curSpeed / 10;
      result.distSumm[index] = distSummOrigin / 1000;
      if (gps !== null) {
        result.gps[index] = gps;
        if (gps !== prevGps) {
          result.required[index - 1] = { val: true, because: 'gps - 1' };
          result.required[index] = { val: true, because: 'gps' };
          prevGps = gps;
        }
      }
      if (gsm !== null) {
        result.gsm[index] = gsm;
      }
      result.rpm[index] = rpm > -1 ? rpm : 0;
      result.kren[index] = kren / 100;
      result.tang[index] = tang / 100;
      result.pwr[index] = pwr / 1000;
      result.bat[index] = bat / 1000;
      result.moto1Fill[index] = {
        y: moto1isWork,
        interval: intervalMoto1.value,
      };

      intervalMoto1.prev = moto1isWork;
      intervalMoto1.prevTime = time;

      levelsDesc.forEach((lvDesc) => {
        const { purpose, purposeText } = lvDesc;

        for (let ii = 0; ii < leveCount; ii++) {
          const lvNum = ii + 1;
          const lvName = `${purpose}_${lvNum}`;
          const lvVal = values[`original_${lvName}`] ?? false;

          if (lvVal === false || (lvVal === null && !(lvName in levels))) {
            continue;
          }

          const lvStatus = values[`status_${lvName}`];
          const lvAnalyzed = values[`analyzed_${lvName}`];

          if (!(lvName in levels)) {
            const summText = lvStatus & 8 ? ' (сумма)' : '';

            levels[lvName] = {
              oiginal: [],
              analyzed: [],
              status: [],
              text: `ДУТ ${purposeText} ${lvNum}${summText}`,
              purpose,
            };
          }

          const level = levels[lvName];
          level.oiginal[index] = lvVal !== null ? lvVal / 1000 : lvVal;
          level.analyzed[index] =
            lvAnalyzed !== null ? lvAnalyzed / 1000 : lvAnalyzed;
          level.status[index] = lvStatus;

          if (lvStatus & 16 || lvStatus & 64) {
            result.required[index - 1] = { val: true, because: 'status - 1' };
            result.required[index] = { val: true, because: 'status' };
          }
        }
      });
    });

    return result;
  }

  trackChartWrapperInnerResizeEvent() {
    this.that.trackChartWrapperInnerResize();
  }

  trackChartWrapperInnerResize() {}

  trackChartsSettingIframeResize() {
    if (!this.that.wrapperSize) {
      this.that.wrapperSize = {};
    }

    if (!this.that.isOpened || !this.that.wrapperSize.height) {
      const initHeight = 40;
      this.that.drawArea.style.height = `${initHeight}vh`;
      this.that.percentHeightValueChange(initHeight);

      this.that.isOpened = true;
      return;
    } else {
      this.that.drawArea.style.height = '';
    }

    const mainWrapper = this.that.mainWrapper;
    const { width: lastW = 0, height: lastH = 0 } = this.that.wrapperSize;
    // const {width:iframeW,height:iframeH} = this.that.trackChartsSettingIframe.getBoundingClientRect();
    let { width: wrapperW, height: wrapperH } =
      mainWrapper.getBoundingClientRect();

    const {
      borderTopWidth: mainWrapperBorderTop,
      borderBottomWidth: mainWrapperBorderBottom,
    } = mainWrapper.style;

    wrapperH +=
      parseInt(mainWrapperBorderTop || 0) +
      parseInt(mainWrapperBorderBottom || 0);

    if (
      !(
        wrapperW < lastW ||
        wrapperW > lastW + 2 ||
        wrapperH < lastH ||
        wrapperH > lastH + 2
      )
    ) {
      return;
    }

    this.that.wrapperSize = {
      width: wrapperW,
      height: wrapperH,
    };

    if (!this.that.chart) {
      return;
    }

    // const allHeight = this.that.trackChartsSettingIframe.getBoundingClientRect().height;
    const allHeight = wrapperH - 3;
    const { trackChartsSettingHeader, trackChartsSettingContainer } =
      this.that.panelSubelements;
    const { trackChartValues, trackChartHeader, trackChartWrapper } =
      this.that.chartSubelements;

    const topElementsHeight =
      trackChartsSettingHeader.getBoundingClientRect().height +
      trackChartsSettingContainer.getBoundingClientRect().height +
      trackChartValues.getBoundingClientRect().height +
      trackChartHeader.getBoundingClientRect().height;

    const chartHeight = allHeight - topElementsHeight;
    // this.that.drawArea.style.height = `${chartHeight}px`;
    // const chartWidth = this.that.drawArea.getBoundingClientRect().width;
    const chartWidth = wrapperW;

    if (
      this.that.panelSubelements.trackChartsContainer.getElementsByClassName(
        'track-charts-item',
      ).length > 1
    ) {
      this.that.chart.resize({ width: chartWidth });
      return;
    }

    this.that.chart.resize({ width: chartWidth, height: chartHeight });

    const percentHeight = Math.floor(
      (100 * chartHeight) /
        document.documentElement.getBoundingClientRect().height,
    );
    this.that.percentHeightValueChange(percentHeight);
  }

  percentHeightValueChange(percentHeight) {
    this.chartSubelements.trackChartSettingHeight.value = percentHeight;
    this.chartSubelements.trackChartSettingHeightValue.innerText = `${percentHeight} %`;
  }

  inputHeightEvent(event) {
    const value = event.target.value;
    this.that.chartSubelements.trackChartSettingHeightValue.innerText = `${value} %`;
  }

  changeHeightEvent(event) {
    const value = event.target.value;

    // посчитать высоту в пикселях
    const height = (value * document.documentElement.clientHeight) / 100;

    this.that.chart.resize({ height });
  }

  graphLineMovedEvent(event) {
    const { indexByFirstChart, value, selectionIndexes } = event.detail;
    this.that.graphLineMoved({ indexByFirstChart, value, selectionIndexes });
  }

  graphLineMoved({ indexByFirstChart, value, selectionIndexes } = {}) {
    const latLonDelimeter = 1000000000000000;
    const { leafletMain, points, marker } = this;

    const { allValues = [] } = points;
    // const latLon = (indexByFirstChart === null) ? null : latlngs[indexByFirstChart]
    const data = indexByFirstChart === null ? {} : allValues[indexByFirstChart];
    const { time, lat: latOrigin, lon: lonOrigin } = data;
    const latLon = [latOrigin / latLonDelimeter, lonOrigin / latLonDelimeter];

    const text = `${this.stateNumber}<br> ${formatDateHelper(
      new Date(time),
      'hh:nn:ss dd.mm.yy',
    )}`;

    if (latLon && marker) {
      leafletMain.moveMarker({ marker: marker, latLon, text });
    }

    if (!latLon && marker) {
      leafletMain.dropMarker(marker);
      this.marker = null;
    }

    if (latLon && !marker) {
      const options = {
        icon: this.markerIcon,
      };
      this.marker = leafletMain.addMaker({ latLon, text, options });
    }

    if (selectionIndexes) {
      const [iBegin, iEnd] = selectionIndexes;
      const latlngs = this.getLatLngsInterval({
        allValues,
        latLonDelimeter,
        iBegin,
        iEnd,
      });

      const { time: timeBeginU } = allValues[iBegin] || {};
      const { time: timeEndU } = allValues[iBegin] || {};

      const timeBegin = timeBeginU
        ? formatDateHelper(new Date(timeBeginU), 'hh:nn:ss dd.mm.yy')
        : 'н.д.';
      const timeEnd = timeEndU
        ? formatDateHelper(new Date(timeEndU), 'hh:nn:ss dd.mm.yy')
        : '?';

      const lineText = `Выделено на графике по объекту ${this.stateNumber}<br>с ${timeBegin} по ${timeEnd}`;

      if (this.polyline) {
        leafletMain.setLatLngsPolylyne({
          polyline: this.polyline,
          latlngs,
          text: lineText,
        });
      } else {
        const lineOptions = {
          color: 'rgba(255, 0, 0, 0.4)',
          weight: 7,
        };

        this.polyline = leafletMain.addPolyline({
          latlngs,
          options: lineOptions,
          text: lineText,
        });
      }
    }

    if (this.polyline && !selectionIndexes) {
      leafletMain.dropPolline(this.polyline);
      this.polyline = null;
    }
  }

  getLatLngsInterval({ allValues, latLonDelimeter, iBegin, iEnd } = {}) {
    const latlngs = [];

    for (let ii = iBegin; ii < iEnd + 1; ii++) {
      const { lat, lon } = allValues[ii] || {};
      if (lat && lon) {
        latlngs.push([lat / latLonDelimeter, lon / latLonDelimeter]);
      }
    }

    return latlngs;
  }

  addEventListeners() {
    this.chartSubelements.trackChartSettingHeight.addEventListener('input', {
      handleEvent: this.inputHeightEvent,
      that: this,
    });

    this.chartSubelements.trackChartSettingHeight.addEventListener('change', {
      handleEvent: this.changeHeightEvent,
      that: this,
    });

    this.trackChartsSettingIframe.contentWindow.addEventListener('resize', {
      handleEvent: this.trackChartsSettingIframeResize,
      that: this,
    });

    this.chart.chart._chartAreaElement
      .node()
      .addEventListener('graphLineMoved', {
        handleEvent: this.graphLineMovedEvent,
        that: this,
      });
  }

  removeEventListeners() {
    this.chartSubelements.trackChartSettingHeight.removeEventListener('input', {
      handleEvent: this.inputHeightEvent,
      that: this,
    });

    this.chartSubelements.trackChartSettingHeight.removeEventListener(
      'change',
      {
        handleEvent: this.changeHeightEvent,
        that: this,
      },
    );

    this.trackChartsSettingIframe.contentWindow.removeEventListener('resize', {
      handleEvent: this.trackChartsSettingIframeResize,
      that: this,
    });

    this.chart.chart._chartAreaElement
      .node()
      .removeEventListener('graphLineMoved', {
        handleEvent: this.graphLineMovedEvent,
        that: this,
      });
  }

  destroy() {
    if (this.marker) {
      this.leafletMain.dropMarker(this.marker);
    }
    if (this.polyline) {
      this.leafletMain.dropPolline(this.polyline);
    }
    this.removeEventListeners();
    // if (this.enhancer) {
    //     this.enhancer.destroy();
    // }
    this.chart.destroy();
    // this.enhancer = null;
    this.chart = null;
  }
}
