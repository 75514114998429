import { in_array_helper, toOnlyDay } from '@/helpers/main_helper_010';

export interface ISmenasOptions {
  [key: string]: any;

  SmenasFlag: boolean;
  Breakdown?: boolean;

  s1?: boolean;
  s1BhBm?: string;
  s1EhEm?: string;

  s2?: boolean;
  s2BhBm?: string;
  s2EhEm?: string;

  s3?: boolean;
  s3BhBm?: string;
  s3EhEm?: string;
}

export interface ISmena {
  TimeBegin: number;
  TimeEnd: number;
}

export interface IPeriod {
  [key: string]: any;
  TimeBegin?: number;
  TimeEnd?: number;
  nSmena: number;
  isWebOpened: boolean;
  isWebClosed: boolean;
  webBegin: { [key: string]: any };
  webEnd: { [key: string]: any };
  webSumm: { [key: string]: any };
  webDetail: any[];
}

export interface ISmenas {
  forResponse: any[];
  smenasSetting: any[];
  setSmenas: {
    Periods?: IPeriod[];
    smenas?: string[];
    arrSmena?: ISmena[];
    WorkTimesCnt?: number;
  };
  smenasFlag: boolean;
  breakdown: boolean;
  badSmenasFlag: boolean;
}

export interface ISmenasSetting {
  [key: string]: string;

  begin: string;
  end: string;
}

const SECONDS_BY_DAY = 86400000;

export class Smenas {
  forResponse: any[];
  smenasSetting: any[];
  setSmenas: {
    Periods?: IPeriod[];
    smenas?: string[];
    arrSmena?: ISmena[];
    WorkTimesCnt?: number;
  };
  smenasFlag: boolean;
  breakdown: boolean;
  badSmenasFlag: boolean;

  _smenasOptions: ISmenasOptions;

  constructor(smenasOptions: ISmenasOptions) {
    this.forResponse = [];
    this.smenasSetting = [];
    this.setSmenas = {};
    this.smenasFlag = false;
    this.breakdown = false;
    this.badSmenasFlag = true;

    this._smenasOptions = smenasOptions;
  }

  private _getSmenasSetting_pice(
    hours: string,
    minutes: string,
    roundTo: number,
  ): string {
    roundTo = roundTo || 10;

    if (!hours && !minutes) {
      return '00:00';
    }

    if (+hours > 23 || +hours < 0) hours = '00';
    if (!minutes) {
      return hours + ':00';
    }

    if (+minutes > 60 || +minutes < 0) {
      return hours + ':' + '00';
    }

    const deciMinute = minutes.charAt(0) || '0';

    if (roundTo == 10) {
      // минута всегда будет нулевой
      return hours + ':' + deciMinute + '0'; // минуты по 10 минут допускаются
    }

    const minute = minutes.charAt(1) || '0';

    // roundTo = 5
    if (+minute < 5) {
      return hours + ':' + deciMinute + '0'; // минуты по 5 минут допускаются, но тут округление
    }
    return hours + ':' + deciMinute + '5'; // минуты по 5 минут допускаются
  }

  private _getSmenas_getTime(timeStroke: string): number {
    // seconds from begin day (secons of a day)
    return (
      (Number(timeStroke.split(':')[0]) * 60 +
        Number(timeStroke.split(':')[1])) *
      60000
    ); // в милисекундах
  }

  private _getSmenas_createPeriod(
    v_time: Date | boolean,
    arrSmena: ISmena[],
    idxWorkTime: number,
  ): IPeriod {
    const Period: IPeriod = {
      nSmena: idxWorkTime,
      isWebOpened: false,
      isWebClosed: false,
      webBegin: {},
      webEnd: {},
      webSumm: {},
      webDetail: [],
    };

    if (v_time instanceof Date) {
      Period.TimeBegin = new Date(
        Number(toOnlyDay(v_time)) + arrSmena[idxWorkTime].TimeBegin,
      ).getTime();
      Period.TimeEnd = new Date(
        Number(toOnlyDay(v_time)) + arrSmena[idxWorkTime].TimeEnd,
      ).getTime();
    }

    return Period;
  }

  private _getSmenas(
    smenasFlag: boolean,
    smenasSetting: ISmenasSetting[],
    ReportBegin: number,
    ReportEnd: number,
  ) {
    /* получение смен за весь период */
    const getFirstSmenaIdx_checkBeginInSmena = (
      reportBegin: number,
      timeSmenaBegin: number,
      timeSmenaEnd: number,
    ) => {
      return Boolean(
        reportBegin >= timeSmenaBegin && reportBegin < timeSmenaEnd,
      );
    };

    const getFirstSmenaIdx = (
      startDayOfSmenas: number,
      arrSmena: ISmena[],
    ): {
      i: number;
      CurWorkTimeBegin: number;
      CurWorkTimeEnd: number;
    } => {
      let CurWorkTimeBegin = 0;
      let CurWorkTimeEnd = 0;

      // оставляем внутри _getSmenas
      if (!WorkTimesCnt) {
        return { i: -1, CurWorkTimeBegin, CurWorkTimeEnd };
      }

      const arrSmenaTimes: {
        timeBegin: number;
        timeEnd: number;
        nSmena: number;
      }[] = [];

      const reportBegin = ReportBegin;

      for (let i = 0; i < WorkTimesCnt; i++) {
        const timeBegin = arrSmena[i].TimeBegin + startDayOfSmenas;
        let timeEnd = arrSmena[i].TimeEnd + startDayOfSmenas;

        if (timeBegin >= timeEnd) {
          timeEnd += SECONDS_BY_DAY;

          if (
            getFirstSmenaIdx_checkBeginInSmena(
              reportBegin,
              timeBegin - SECONDS_BY_DAY,
              timeEnd - SECONDS_BY_DAY,
            )
          ) {
            // в этой смене начинается отчет, но с переводом на сутки назад
            CurWorkTimeBegin = reportBegin;
            CurWorkTimeEnd = timeEnd - SECONDS_BY_DAY;
            return { i, CurWorkTimeBegin, CurWorkTimeEnd };
          }
        }

        if (
          getFirstSmenaIdx_checkBeginInSmena(reportBegin, timeBegin, timeEnd)
        ) {
          // в этой смене начинается отчет
          CurWorkTimeBegin = reportBegin;
          CurWorkTimeEnd = timeEnd;
          return { i, CurWorkTimeBegin, CurWorkTimeEnd };
        }

        arrSmenaTimes[i] = {
          timeBegin: timeBegin,
          timeEnd: timeEnd,
          nSmena: i,
        };
      }

      // отчет не начинается ни в одной из заданных смен
      arrSmenaTimes.sort((a, b) => {
        return a.timeBegin - b.timeBegin;
      });

      for (let i = 0; i < WorkTimesCnt; i++) {
        if (arrSmenaTimes[i].timeBegin > reportBegin) {
          CurWorkTimeBegin = arrSmenaTimes[i].timeBegin;
          CurWorkTimeEnd = arrSmenaTimes[i].timeEnd;
          return {
            i: arrSmenaTimes[i].nSmena,
            CurWorkTimeBegin,
            CurWorkTimeEnd,
          };
        }
      }

      // в этом дне нет смен, нужно брать первую смену следующего дня (в которой начало смены лежит в следующем дне)
      CurWorkTimeBegin = arrSmenaTimes[0].timeBegin + SECONDS_BY_DAY;
      CurWorkTimeEnd = arrSmenaTimes[0].timeEnd + SECONDS_BY_DAY;
      return { i: arrSmenaTimes[0].nSmena, CurWorkTimeBegin, CurWorkTimeEnd };
    };

    this.badSmenasFlag = true;
    this.setSmenas = {};

    let WorkTimesCnt = 1; // одна смена - минимум
    const arrSmena: ISmena[] = [];

    if (smenasFlag && 1 in smenasSetting) {
      // две смены точно есть
      if (2 in smenasSetting) {
        // установлены три смены
        WorkTimesCnt = 3;
      } else {
        WorkTimesCnt = 2;
      }
    }

    for (let s = 0; s < WorkTimesCnt; s++) {
      const TimeBegin =
        smenasFlag && s in smenasSetting
          ? this._getSmenas_getTime(smenasSetting[s]['begin'])
          : 0;
      const TimeEnd =
        smenasFlag && s in smenasSetting
          ? this._getSmenas_getTime(smenasSetting[s]['end'])
          : 86400 * 1000;
      arrSmena.push({ TimeBegin, TimeEnd });
    }

    const Periods = [];
    let CurWorkTimeBegin;
    let CurWorkTimeEnd;
    let idxWorkTime = 0;
    let idxWorkTimeNext;

    //находим первую полную смену за период
    const startDayOfReport = Number(toOnlyDay(new Date(ReportBegin)));

    const firstSmenaIdxData = getFirstSmenaIdx(startDayOfReport, arrSmena);
    idxWorkTime = firstSmenaIdxData.i;
    CurWorkTimeBegin = firstSmenaIdxData.CurWorkTimeBegin;
    CurWorkTimeEnd = firstSmenaIdxData.CurWorkTimeEnd;

    if (idxWorkTime < 0 && !CurWorkTimeBegin && !CurWorkTimeBegin) {
      // в этих сутках нет смены - берем следующие
      const firstSmenaIdxData = getFirstSmenaIdx(
        startDayOfReport + SECONDS_BY_DAY,
        arrSmena,
      );

      idxWorkTime = firstSmenaIdxData.i;
      CurWorkTimeBegin = firstSmenaIdxData.CurWorkTimeBegin;
      CurWorkTimeEnd = firstSmenaIdxData.CurWorkTimeEnd;
    }

    //раскладка смен на весь период
    let cntSP = 0;

    let Period = this._getSmenas_createPeriod(false, arrSmena, idxWorkTime);
    Period.TimeBegin = new Date(CurWorkTimeBegin).getTime();
    Period.TimeEnd = new Date(CurWorkTimeEnd).getTime();
    Periods[0] = Period;

    while (Period.TimeEnd && +Period.TimeEnd < ReportEnd) {
      cntSP++;

      if (
        WorkTimesCnt == 1 &&
        arrSmena[idxWorkTime].TimeEnd > arrSmena[idxWorkTime].TimeBegin
      ) {
        // v_time = new Date(Number(Periods[cntSP - 1].TimeEnd.toOnlyDay()) + 24 * 3600 * 1000);
        const onlyDayTimeStamp = new Date(
          Number(toOnlyDay(new Date(Number(Periods[cntSP - 1].TimeEnd)))),
        );
        const v_time = onlyDayTimeStamp;

        Period = this._getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);

        // 241120
        if (
          Period.TimeEnd &&
          Number(Periods[cntSP - 1].TimeEnd) === +Period.TimeEnd
        ) {
          const v_time = new Date(SECONDS_BY_DAY + +onlyDayTimeStamp);
          Period = this._getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);
        }
      } else {
        idxWorkTimeNext = idxWorkTime < WorkTimesCnt - 1 ? idxWorkTime + 1 : 0;
        idxWorkTime = idxWorkTimeNext;
        const v_time = toOnlyDay(new Date(Number(Periods[cntSP - 1].TimeEnd)));

        Period = this._getSmenas_createPeriod(
          v_time || false,
          arrSmena,
          idxWorkTime,
        );

        if (
          Period.TimeEnd &&
          Period.TimeBegin &&
          Period.TimeEnd <= Period.TimeBegin
        ) {
          Period.TimeEnd = new Date(
            Number(Period.TimeEnd) + 24 * 3600 * 1000,
          ).getTime();
        }

        const maxSmenasCol = 3;
        let cnt = 0;
        while (cnt < maxSmenasCol) {
          cnt++;
          if (cntSP - cnt < 0) {
            break;
          }
          if (
            Number(Periods[cntSP - cnt].TimeEnd) == Number(Period.TimeEnd) &&
            Number(Periods[cntSP - cnt].TimeBegin) == Number(Period.TimeBegin)
          ) {
            // такая смена уже есть - нужно перелистнуть сутки
            Period.TimeEnd = new Date(
              Number(Period.TimeEnd) + 24 * 3600 * 1000,
            ).getTime();
            Period.TimeBegin = new Date(
              Number(Period.TimeBegin) + 24 * 3600 * 1000,
            ).getTime();
            break;
          }
        }
      }

      if (Number(Period.TimeBegin) < ReportEnd) {
        Periods[cntSP] = Period;
      } else {
        // 241120
        cntSP--;
      }
    }

    // 241120
    if (Number(Periods[cntSP].TimeBegin) >= ReportEnd) {
      Periods.splice(cntSP, 1);
    }

    const smenas = [];
    let smena_name;
    if (smenasFlag) {
      for (let z = 0; z < WorkTimesCnt; z++) {
        smena_name =
          'Смена' +
          (z + 1) +
          ' (' +
          smenasSetting[z]['begin'] +
          ' - ' +
          smenasSetting[z]['end'] +
          ')';
        smenas.push(smena_name);
      }
    } else {
      smena_name =
        'Расчет ведется по суткам (сменой считаются границы суток с 00:00:00 до 23:59:59)';
      smenas.push(smena_name);
    }

    this.setSmenas = {
      Periods: Periods,
      smenas: smenas,
      arrSmena: arrSmena,
      WorkTimesCnt: WorkTimesCnt,
    };

    // корректировка первого и последнего периода по периоду отчета при необходимости
    if (Periods.length) {
      if (Number(Periods[0].TimeBegin) < ReportBegin) {
        Periods[0].TimeBegin = new Date(ReportBegin).getTime();
      }

      if (Number(Periods[Periods.length - 1].TimeEnd) > ReportEnd) {
        Periods[Periods.length - 1].TimeEnd = new Date(+ReportEnd).getTime();
      }
    }
    //проверка раскладки смен (цикл ниже)
    this.badSmenasFlag = false;
    for (let i = 0; i < Periods.length - 1; i++) {
      if (Number(Periods[i].TimeEnd) > Number(Periods[i + 1].TimeBegin)) {
        this.badSmenasFlag = true;
        break;
      }
    }
  }

  public getSmenasSetting(
    roundTo: number,
    ReportBegin: number,
    ReportEnd: number,
  ) {
    this.smenasFlag = this._smenasOptions.SmenasFlag;
    this.breakdown = this._smenasOptions.Breakdown || false;

    if (!this.smenasFlag) {
      this.smenasSetting = [];
      // вычисление периодов
      this._getSmenas(this.smenasFlag, [], ReportBegin, ReportEnd);
      return;
    }

    const ssSetting: ISmenasSetting[] = [];

    for (let n = 1; n < 4; n++) {
      if (!this._smenasOptions['s' + n]) {
        break;
      }

      const [sBeginHours, sBeginMinutes] =
        this._smenasOptions[`s${n}BhBm`].split(':');
      const [sEndHours, sEndMinutes] =
        this._smenasOptions[`s${n}EhEm`].split(':');

      const sSetting: ISmenasSetting = { begin: '', end: '' };

      sSetting.begin = this._getSmenasSetting_pice(
        sBeginHours,
        sBeginMinutes,
        roundTo,
      );
      sSetting.end = this._getSmenasSetting_pice(
        sEndHours,
        sEndMinutes,
        roundTo,
      );

      ssSetting.push(sSetting);
      for (const key in sSetting) {
        if (!this.smenasFlag) {
          break;
        }

        const secondsOfTheDay = this._getSmenas_getTime(sSetting[key]) / 1000;

        if (
          secondsOfTheDay > 0 &&
          secondsOfTheDay != 86400 &&
          !in_array_helper(secondsOfTheDay, this.forResponse)
        ) {
          this.forResponse.push(secondsOfTheDay);
        }
      }
    }

    this.smenasSetting = ssSetting;

    // вычисление периодов
    this._getSmenas(this.smenasFlag, ssSetting, ReportBegin, ReportEnd);
  }

  public resetSettings() {
    // обнуление запомненных настроек
    this.forResponse = [];
    this.smenasSetting = [];
    this.setSmenas = {};
    this.smenasFlag = false;
    this.breakdown = false;
    this.badSmenasFlag = true;
  }
}
