<template>
  <ui-popup
    v-if="visible"
    @close="() => (visible = false)"
    :style-for-body="{
      'padding': '10px',
      'width': '80vw',
      'max-width': '100vw',
      'display': 'flex',
      'flex-direction': 'column',
      'filter': isRequest ? 'blur(4px)' : '',
    }"
    z-index="1002"
    close-button
    class="my-popup"
  >
    <template #header>
      <h5>Панель дозапроса данных</h5>
    </template>
    <div
      style="
        background: #fff;
        color: #000;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        min-width: 320px;
      "
    >
      <div>
        <div>Выберите графики</div>

        <label
          class="d-flex align-items-center"
          @click="toggleAll"
        >
          <div>
            <img
              style="color: #000"
              :src="`/images/${selectAll ? 'clear_all' : 'done_all'}.svg`"
              :alt="selectAll ? 'Снять все' : 'Выбрать все'"
            />
          </div>

          <div class="ml-2">
            <div>
              {{ selectAll ? 'Снять все' : 'Выбрать все' }}
            </div>
          </div>
        </label>

        <template
          v-for="(chart, chartName) in CHARTS_TEMPLATE"
          :key="chartName"
        >
          <label class="d-flex">
            <div>
              <input
                type="checkbox"
                v-model="checkedCharts"
                :value="chartName"
              />
            </div>

            <div class="ml-2">
              <div>{{ chart.label }}</div>
            </div>
          </label>
        </template>
        <template v-if="props.fuelSettings">
          <template
            v-for="(label, key) in FUEL_KEYS"
            :key="key"
          >
            <template v-if="props.fuelSettings[key].length">
              <hr />
              <div>Уровнемеры {{ label }}</div>

              <label
                v-for="obj of props.fuelSettings[key]"
                :key="obj.navigator.id"
                class="d-flex"
              >
                <div>
                  <input
                    type="checkbox"
                    v-model="checkedLevels"
                    :value="obj.tDetailNum"
                  />
                </div>

                <div class="ml-2">
                  <div>
                    {{
                      ucFirst_helper(obj.navigator.name) ||
                      `Уровнемер ${obj.navigator.num}`
                    }}
                  </div>
                </div>
              </label>
            </template>
          </template>
        </template>
      </div>

      <div class="request-btn">
        <input
          type="button"
          class="btn"
          :style="{
            background: button.bgColor,
          }"
          :value="button.label"
          @click="requestChartsData"
        />
      </div>
    </div>
  </ui-popup>
  <div
    v-if="isRequest"
    class="d-flex flex-column align-items-center justify-content-center"
    style="
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1003;
    "
  >
    <div>
      <img
        v-if="requestError"
        src="images/notifications/error.svg"
        alt="Error"
        style="width: 50px; height: 50px"
      />
      <spin-loader
        v-if="!requestError"
        :width="50"
        :height="50"
      />
    </div>

    <div
      class="mt-2 text-teal"
      style="font-size: 1.1em"
    >
      {{ requestText }}
    </div>

    <div class="d-flex">
      <button
        v-if="requestError"
        class="btn btn-primary mt-2"
        @click="requestChartsData"
      >
        Повторить запрос
      </button>
      <button
        v-if="!!isRequest"
        class="btn btn-primary mt-2"
        :class="[{ 'ml-1': requestError }]"
        @click="cancelRequest"
      >
        Отменить запрос
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import SpinLoader from '../SpinLoader.vue';

import { getPositionsFromOur } from '@/App/TemplateComponents/ExcavatorOperation/getPositionsFromOur';

import { globalObjects } from '@/src/dataRequest/mainScript';

import {
  ucFirst_helper,
  addCopyArray_helper,
  wait,
} from '@/helpers/main_helper_010';

import { useChart } from '@/Components/chart';

import Request from '@/src/dataRequest/Request';

const props = defineProps({
  modelValue: Boolean,
  hidden: Boolean,
  id: [Number, String],
  fuelSettings: Object,
  saved: Array,
  chartNames: Array,
  levels: Array,
  from: [String, Number],
  to: [String, Number],
  category: { type: [String, Number], default: '' },
  positions: { type: [Object, undefined] },
});

const emit = defineEmits(['update:modelValue', 'addCharts']);

const visible = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v),
});

const { CHARTS_TEMPLATE } = useChart;

const FUEL_KEYS = {
  consumption: 'в баках',
  cistern: 'в цистернах',
  // uss_in: '',
  // uss_out: '',
};

const BTN_COLORS = {
  default: '#0069d9',
  success: 'lime',
  error: 'red',
};

const button = ref({
  bgColor: BTN_COLORS.default,
  label: 'Сохранить',
});

const isRequest = ref(false);
const requestText = ref('');
const requestError = ref(false);
const request = ref(null);

const selectAll = ref(false);
const checkedCharts = ref([]);
const checkedLevels = ref([]);

watch(
  visible,
  (isVisible) => {
    if (isVisible) {
      checkedCharts.value = props.chartNames;
      checkedLevels.value = props.levels;
    } else {
      checkedCharts.value.length = 0;
      checkedLevels.value.length = 0;
    }
  },
  { immediate: true },
);

watch(checkedCharts, (charts) => {
  if (JSON.stringify(props.chartNames) !== JSON.stringify(charts)) {
    button.value.bgColor = BTN_COLORS.default;
    button.value.label = 'Сохранить';
  }
});
watch(checkedLevels, (levels) => {
  if (JSON.stringify(props.levels) !== JSON.stringify(levels)) {
    button.value.bgColor = BTN_COLORS.default;
    button.value.label = 'Сохранить';
  }
});

const processCallback = (state, label, color, percent) => {
  requestText.value = label;
  isRequest.value = true;
  if (state === 'error') {
    requestError.value = true;
    abortRequest();
  }
};

async function requestChartsData() {
  button.value.bgColor = BTN_COLORS.default;
  button.value.label = 'Запросить';
  isRequest.value = true;
  requestError.value = false;
  request.value = null;

  const lastTreatmentPositions = props.positions
    ? props.positions
    : globalObjects.globalPositions[props.id] || {};

  const uniqColumns = new Set();

  for (const name of checkedCharts.value) {
    if (props.saved.includes(name)) continue;
    for (const column of CHARTS_TEMPLATE[name].columns) {
      uniqColumns.add(column);
    }
  }

  const uniqLevels = new Set();

  for (const name of checkedLevels.value) {
    if (props.saved.includes(name)) continue;
    uniqLevels.add(name);
  }

  const chartNames = addCopyArray_helper(checkedCharts.value);

  if (uniqColumns.size > 0 || uniqLevels.size > 0) {
    const columns = ['time', ...uniqColumns];

    const requestObject = new Request();

    requestObject.onSuccess((treatmentPositions) => {
      if (requestError.value) {
        button.value.bgColor = BTN_COLORS.error;
        button.value.label = 'Ошибка';
        return;
      }

      requestText.value = 'Данные загружены. Обновляем графики...';
      wait(0).then(() => {
        emit('addCharts', {
          treatmentPositions,
          levels: checkedLevels.value,
          chartNames,
          columns,
        });

        isRequest.value = false;

        button.value.bgColor = BTN_COLORS.success;
        button.value.label = 'Сохранено';
      });
    });

    requestObject.onProcess(processCallback);

    requestObject.run(() =>
      getPositionsFromOur({
        objId: props.id,
        processCallback: (...args) =>
          requestObject._onProcess && requestObject._onProcess(...args),
        isOnMap: true,
        interval: { from: props.from * 1000, to: props.to * 1000 },
        columns,
        levels: [...uniqLevels],
        objectCategory: props.category,
      }),
    );

    request.value = requestObject;
  } else {
    requestText.value = 'Обновляем графики...';

    wait(0.5).then(() => {
      try {
        emit('addCharts', {
          treatmentPositions: lastTreatmentPositions,
          levels: checkedLevels.value,
          chartNames,
          columns: [],
        });
        button.value.bgColor = BTN_COLORS.success;
        button.value.label = 'Сохранено';
        isRequest.value = false;
      } catch (error) {
        requestText.value = 'Ошибка добавления трека на карту';
        requestError.value = true;
        if (!Object.keys(lastTreatmentPositions).length) {
          console.error('Отсутствуют объект позиций');
        }
      }
    });
  }
}

function abortRequest() {
  if (request.value) {
    request.value.abort();
  }
}
function cancelRequest() {
  abortRequest();
  isRequest.value = false;
  button.value.label = 'Сохранить';
}

function toggleAll() {
  if (selectAll.value) {
    checkedCharts.value.length = 0;
  } else {
    checkedCharts.value.length = 0;
    for (const chartName in CHARTS_TEMPLATE) {
      checkedCharts.value.push(chartName);
    }
  }
  selectAll.value = !selectAll.value;
}
</script>

<style lang="scss" scoped>
.inner-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.request-btn {
  position: fixed;
  bottom: 5px;
  right: 30px;
  z-index: 1;
}
</style>
