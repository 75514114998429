import RequestQueue from './RequestQueue';

import { getPercent, getTzh } from '@/helpers/main_helper_010';

import { useRequest } from '@/ManualApp/use/request';

import { TProcessCallback } from './../../types/TProcessCallback';

interface IObject {
  id: string;
}

interface IInterval {
  timeBegin: number;
  timeEnd: number;
}

interface IQueryParams {
  [key: string]: any;
  time_begin: number;
  time_end: number;
  option: number;
}

type TOnSuccess<T> = (result: T) => void;

const { send } = useRequest();

const MAX_REQUESTS_ON_QUEUE = 5;
const URL_GET_OTHER_AGGREGATED = 'Api/other/getAgregationPositions';

class OurAggregated {
  private _templateName: string;
  private _responses: unknown[];
  private _interval: IInterval;
  private _onProcess: TProcessCallback | null;
  private _onSuccess: TOnSuccess<any> | null;

  constructor(templateName: string, interval: IInterval) {
    this._templateName = templateName;
    this._responses = [];
    this._interval = interval;
    this._onProcess = null;
    this._onSuccess = null;
  }

  private _prepareQueryParams(
    id: string | number,
    params: { [key: string]: string | number },
  ) {
    params.tzh = getTzh();

    let queryString = `?id=${id}`;

    if (this._templateName) {
      queryString += `&template=${this._templateName}`;
    }

    for (const key in params) {
      queryString += `&${key}=${params[key]}`;
    }

    return queryString;
  }

  get(objects: IObject[], params: IQueryParams) {
    if (this._onProcess) {
      this._onProcess(
        'success',
        'Отправляю запрос для суммарного отчета...',
        0,
      );
    }

    this._responses = [];

    const queue = RequestQueue.channels(MAX_REQUESTS_ON_QUEUE);

    queue.onProcess(async ({ id, params }: any, next) => {
      const queryParams = this._prepareQueryParams(id, params);
      const url = URL_GET_OTHER_AGGREGATED;
      try {
        const data = await send(url + queryParams, 'GET');

        next(null, data);
      } catch (error: any) {
        next(error, null);
      }
    });

    const queryCount = objects.length;
    let queryNumber = 0;
    queue.onSuccess((res: any) => {
      queryNumber++;

      const percent = getPercent(queryNumber, queryCount);

      if (this._onProcess) {
        this._onProcess(
          'success',
          `Выполнено ${queryNumber} из ${queryCount} (${percent}%) `,
          percent,
        );
      }

      this._responses.push(res);
    });

    queue.onFailure((err) => {
      if (this._onProcess) {
        this._onProcess(
          'error',
          'Ошибка выполнения запроса. Выполните новый запрос, либо обновиту страницу',
          0,
        );

        console.error(err);
      }
    });

    queue.onDone(() => {
      if (this._onSuccess) this._onSuccess(this._responses);
      this._responses = [];
    });

    objects.forEach(({ id }) => queue.add({ id, params }));
  }

  public callOurAggregated<smenasSecondsType>(
    objects: IObject[],
    smenasSeconds: smenasSecondsType[],
    option?: number,
    time_scale_minutes?: number,
  ) {
    smenasSeconds = smenasSeconds || [];
    option = option || Number(process.env.REQUEST_OPTION) || 2;

    const params: IQueryParams = {
      time_begin: this._interval.timeBegin / 1000,
      time_end: this._interval.timeEnd / 1000,
      option,
    };
    if (time_scale_minutes) {
      params.time_scale_minutes = time_scale_minutes;
    }

    // добавим смены в запрос
    for (const s in smenasSeconds) {
      const nSmena = +s + 1;
      params['smena_' + nSmena] = smenasSeconds[s];
    }

    this.get(objects, params);
  }

  public onProcess(listener: TProcessCallback) {
    this._onProcess = listener;
    return this;
  }

  public onSuccess<T>(listener: TOnSuccess<T>) {
    this._onSuccess = listener;
    return this;
  }
}

export default OurAggregated;
