import { formatDateHelper } from './main_helper_010';
import { translit } from './translitConverter';

window.html_to_xml_helper = {
  exportFile: async function (button, xmlString, temlateName) {
    try {
      const blob = new Blob([xmlString], { type: 'text/xml' });

      saveAs(
        blob,
        `${temlateName}_${formatDateHelper(
          new Date(),
          'yyyy_mm_dd__hh_nn_ss',
        )}`,
      );
    } catch (error) {
      this.errorHandler(
        button,
        `Произошла ошибка при экспорте файла. Текст ошибки: ${error.message}`,
      );
    }
  },

  exportHtml: async function (button, templateId, temlateName) {
    const buttonTextEnd = button.innerText;
    const exportInfo = 'Экспорт:';
    const errorText = 'ОШИБКА';

    button.style.backgroundColor =
      'internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59))'; // default
    button.disabled = true;

    button.innerText = `${exportInfo} 0%`;

    try {
      let html = document.getElementById(templateId);
      let childrenCnt = html.children.length;

      if (!childrenCnt) {
        this.errorHandler(button, errorText);
        return;
      }

      if (html.children[0].classList.contains('temlate-report')) {
        html = html.children[0]; // это обертка отчета
        childrenCnt = html.children.length;

        if (html.children.length == 0) {
          this.errorHandler(button, errorText);
          return;
        }
      }

      html.style.fontSize = '1rem';
      html.style.fontWeight = '400';
      html.style.lineHeight = '1.5';
      html.style.color = '#212529';
      html.style.textAlign = 'left';
      html.style.backgroundColor = '#fff';
      html.style.padding = '5px';

      // обработка html перед созданием файла
      let progressPercent = 0;
      for (let i = 0; i < childrenCnt; i++) {
        const elem = html.children[i];
        this.exportElement(i, elem);

        //show progress
        const percent = Math.round((i / childrenCnt) * 100);
        if (percent - progressPercent) {
          progressPercent = percent;

          button.innerText = `${exportInfo} ${percent}%`;

          await new Promise((res) => setTimeout(res, 0));
        }
      }
      saveAsXml(html, temlateName);

      button.innerText = buttonTextEnd;
      button.disabled = false;
    } catch (ex) {
      this.errorHandler(button, errorText);
      console.error(ex);
    }
  },

  errorHandler: (button, message) => {
    button.innerText = message;
    button.style.backgroundColor = 'red';
    button.disabled = false;
  },

  exportElement: function (i, elem) {
    const isContains = (className) => {
      return elem.classList.contains(className);
    };
    const deepCheck = () => {
      for (let i = 0; i < elem.children.length; i++) {
        this.exportElement(i, elem.children[i]);
      }
    };

    // Сначала теги

    if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(elem.tagName)) {
      elem.style.fontSize = '1.5rem';
      elem.style.fontWeight = '500';
      elem.style.lineHeight = '1.2';
    }

    if (['CAPTION'].includes(elem.tagName)) {
      elem.style.textAlign = 'left';
      elem.style.display = 'table-caption';
      elem.style.paddingTop = '0.72rem';
      elem.style.paddingBottom = '0.36rem';
    }

    if (['TABLE', 'THEAD', 'TBODY', 'TR'].includes(elem.tagName)) {
      deepCheck();
    }

    if (['TH', 'TD'].includes(elem.tagName)) {
      elem.style.padding = '0 5px';
      elem.style.border = '1px solid #ddd';
    }

    if (['TH'].includes(elem.tagName)) {
      elem.style.backgroundColor = 'rgb(239, 239, 239)';
      elem.style.lineHeight = '1.5';
    }

    if (['TD'].includes(elem.tagName)) {
      elem.style.textAlign = 'center';
    }

    // потом классы

    if (isContains('d-none') || isContains('display-none')) {
      elem.style.display = 'none';
    }

    if (isContains('border-bottom-tr')) {
      elem.style.borderBottom = '3px solid black';
    }

    if (isContains('text-center') || isContains('text-align-center')) {
      elem.style.textAlign = 'center';
    }

    if (isContains('text-left') || isContains('text-align-left')) {
      elem.style.textAlign = 'left';
    }

    if (isContains('text-right') || isContains('text-align-right')) {
      elem.style.textAlign = 'right';
    }

    if (isContains('margin-none')) {
      elem.style.margin = '0';
    }
  },

  getNameOfFile: (templateName) => {
    const dateTime = new Date();

    const day = dateTime.getDate();
    const mounth = dateTime.getMonth();
    const year = dateTime.getFullYear();

    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();

    const timeString = `${day}_${mounth}_${year}__${hours}_${minutes}_${seconds}`;

    const name = translit(templateName).replace(' ', '_');

    return `${name}__${timeString}`;
  },
};

export function saveAsXml(html, temlateName) {
  const serializer = new XMLSerializer();
  const xmlStr = serializer.serializeToString(html);

  const nameOfFile = this.getNameOfFile(temlateName);

  const blob = new Blob([xmlStr], { type: 'text/xml' });

  saveAs(blob, `${nameOfFile}.xml`);
}
