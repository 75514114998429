import fetchJson from '../../src/dataRequest/fetch-json';
import { getDistanceHelper } from '@/Leaflet/get_distance_helper';
import { WSPositionsFetcher } from '@/WebSocket/positions/WSPositionsFetcher';
import {
  globalObjects,
  violationsDescriptions,
} from '../../src/dataRequest/mainScript';
import { violationsDescriptionListHtml } from '../Monitoring/Map/templates/violationsDescriptionListHtml';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';
import SkillsManDetailCalculate from '@/Template/skills_man_template/SkillsManDetailCalculate';
import { checkUseGPS, getTzh } from '@/helpers/main_helper_010';
import { url_get_aggregated } from '@/src/dataRequest/ourAggregated';
import { useTrackInfo } from '@/App/TemplateComponents/ExcavatorOperation/trackInfo';

const origin = process.env.VUE_APP_BACKEND_URL || window.location.origin + '/';

export const url_get_positions = `${origin}Api/getPositionsOurPost`;
const url_get_positions_with_columns = `${origin}Api/getPositionsOurWithColumns`;
export const url_get_positions_with_columns_array = `${origin}Api/getPositionsOurWithColumnsArray`;
export const url_get_positions_other = `${origin}Api/other/getPositions`;

export function callOurPositions({
  obj,
  clickElem,
  targetElem,
  timeInterval,
  isOnMap = false,
  skillsManDetailTemplate = {},
  isOtherQuery = true,
  isReturnResult = false,
  violationsArray = [],
  isWebSocket = false,
  isGpsFilter = false,
  objectCategory = '',
}) {
  // obj is a Proxy
  const objectData = { ...obj };

  // Used in trachChartsMotion
  const otherQueries = [];

  const preparing_data = preparingDataFromGetPos(
    objectData.id,
    targetElem,
    isOnMap,
    timeInterval,
  );

  if (!preparing_data) return;

  // подготовка для асинохронного запроса
  getCallOurPosotionsPrepareDataSends(preparing_data, isOnMap);

  let timeCheck = Date.now();

  let piecesPositions = [];

  let sectionReportsDetail = isOnMap ? false : 'section-reports-detail';
  const withGpsFilter = isGpsFilter ? ' с фильтрацией gps' : '';
  infoShowText_helper(
    targetElem,
    `Отправляю запрос${withGpsFilter}...`,
    sectionReportsDetail,
  );

  if (isOnMap && clickElem) {
    clickElem.style.background = 'yellow';
  }

  return getPicesPositions();

  function getPicesPositions() {
    // простой запрос позиций без python сервиса

    const isOther = isOnMap;

    if (isWebSocket) {
      try {
        const { timeBegin, timeEnd } = timeInterval;

        const positionsFetcher = new WSPositionsFetcher(objectData.id, {
          timeBegin: timeBegin / 1000,
          interval: (timeEnd - timeBegin) / 1000,
          isOtherPositions: isOther,
        });

        positionsFetcher.startWS(
          ajaxPositionsDoneAll_action,
          ajaxPositionsFailAll,
          showMessage,
        );

        return;
      } catch (error) {
        console.error(error.message);
      }
    }

    const promises = preparePromises(
      url_get_positions,
      preparing_data,
      isOther,
      isOtherQuery,
    );
    // асинхронный запрос
    return Promise.all(promises)
      .then((responses) => {
        piecesPositions = responses;
        return ajaxPositionsDoneAll_action();
      })
      .catch((error) => {
        console.error(error);
        ajaxPositionsFailAll();
      });

    function ajaxPositionsFailAll() {
      //fail
      console.error('error');

      infoShowText_helper(
        targetElem,
        'Ошибка запроса. Выполнить новый запрос?',
        sectionReportsDetail,
      );

      if (clickElem) {
        clickElem.style.background = 'red';
      }
    }
  }

  function showMessage(message) {
    infoShowText_helper(targetElem, message, sectionReportsDetail);
  }

  function ajaxPositionsDoneAll_action(response) {
    if (!response) {
      response = ajaxPositionsDoneAll(
        piecesPositions,
        targetElem,
        isOnMap,
        objectData,
      );
    }

    if (!response) return;

    infoShowText_helper(
      targetElem,
      'Все данные получены, обрабатываю результат... ',
      sectionReportsDetail,
    );

    if (isOnMap) {
      if (clickElem) {
        clickElem.style.background = 'green';
      }
      return treatmentResponseOurAndSave({
        response,
        timeCheck,
        targetElem,
        violationsDescriptionsDesc: violationsDescriptions.desc,
        violationsArray,
        isReturnResult,
        isGpsFilter,
        objectCategory,
        otherQueries,
      });
    } else {
      response.randomNum = Math.random();
      globalObjects['positionsFromTemplateDetail'] = response;
      skillsManDetailTemplate.rows = {};
      if (response) {
        if (!violationsDescriptions.desc) {
          violationsDescriptions.laod();
        }
        const worker = new CalculateRequestDataworker();

        const detailViolationsSetting =
          skillsManDetailTemplate.getDetailViolationsId(
            skillsManDetailTemplate.detailViolationsTable,
          );
        worker.postMessage({
          templateName: 'skillsManDetail',
          data: {
            REPORT: response,
            violationsDescriptionsDesc: violationsDescriptions.desc,
            detailViolationsSetting,
            morePositionsCnt: skillsManDetailTemplate.morePositionsCnt,
            detailPosCntElementValue:
              skillsManDetailTemplate.detailPosCntElement.value,
            allPosesInDetailElementChecked:
              skillsManDetailTemplate.allPosesInDetailElement.checked,
          },
        });

        worker.addEventListener('message', (event) => {
          const data = event.data;
          if (data.message) {
            if (data.type == 'error' && clickElem) {
              clickElem.style.background = 'red';
            }
            infoShowText_helper(targetElem, data.message, sectionReportsDetail);
            return;
          }
          data.format = formatToDisplay_helper;
          data.getVolationDesc_helper = getVolationDesc_helper;
          skillsManDetailTemplate.renderHtml(data);
          worker.terminate();
        });

        worker.addEventListener('error', ({ message }) => {
          console.error(message);
          worker.terminate();
        });
      }
      infoShowText_helper(
        targetElem,
        'Отчет завершен, получено ' +
          response.positions.length +
          ' позиций за ' +
          roundNumber_helper((new Date() - timeCheck) / 1000, 1) +
          ' секунд. Выполнить новый?',
      );
    }
  }

  function preparePromises(
    url,
    { sends: preparingDataArr, aggregation: preparingAggregationData },
    isOther = false,
    isOtherQuery,
  ) {
    if (isOnMap) {
      // url = url_get_positions_with_columns;
      url = url_get_positions_with_columns_array;
    }

    const countOfAggregationResponses = 1;
    const queryCount = preparingDataArr.length + countOfAggregationResponses;
    const infoTextBegin = 'Загрузка: ';
    const method = 'POST';

    let resCount = 0;

    const headers = {
      'Content-Type': 'application/json;charset=utf-8',
    };

    const useGPSSpeed = checkUseGPS(objectCategory, objectData.name);

    const requests = preparingDataArr.map((preparingData) => {
      const { objects, timeBegin, interval } = preparingData;

      const data = isOnMap
        ? {
            oid: +objects,
            timeBegin,
            interval,
            columns: [
              'time',
              'lat',
              'lon',
              'rpm',
              useGPSSpeed ? 'gspd' : 'speed',
              'violation_1',
              'violation_2',
              useGPSSpeed ? 'gdist, gdist_not_engine' : 'dist',
              'dist_day',
              'is_last_day_pos',
            ].join(','),
          }
        : preparingData;

      const options = {
        method,
        headers,
        body: JSON.stringify(data),
      };

      const optionsGetFuels = {
        method,
        headers,
        body: JSON.stringify({
          id: objects,
          timeBegin: timeBegin / 1000,
          interval: interval / 1000,
          allColumns: false,
          isfuel: false,
          columns: ['time', 'engine_time', 'active_time', 'engine_move'],
          levels: [],
        }),
      };

      const queries = [];
      return new Promise((resolve, reject) => {
        queries[0] = { url, options };

        const otherQueryObj = {
          url: url_get_positions_other,
          options: optionsGetFuels,
        };

        otherQueries.push(otherQueryObj);

        if (isOtherQuery && isOther) {
          queries.push(otherQueryObj);
        }

        const promises = queries.map(({ url, options }) => {
          return fetchJson(url, options);
        });

        Promise.all(promises)
          .then((responses) => {
            const [response, other] = responses.map((res) => {
              if (res !== null && typeof res === 'object') {
                return res;
              } else {
                try {
                  const json_res = JSON.parse(res);
                  return json_res;
                } catch (e) {
                  console.error('not JSON', res);
                }

                return '';
              }
            });

            responses = '';
            response.other = other;

            resCount++;

            infoShowText_helper(
              targetElem,
              infoTextBegin +
                resCount +
                ' из ' +
                queryCount +
                ' (' +
                roundNumber_helper((100 * resCount) / queryCount, 1) +
                ' %)',
              sectionReportsDetail,
            );

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

    const aggregationRequestUrl = new URL(url_get_aggregated);
    for (const key in preparingAggregationData) {
      const value = preparingAggregationData[key];
      aggregationRequestUrl.searchParams.append(key, value);
    }
    const aggregationRequest = fetchJson(aggregationRequestUrl, {
      method: 'GET',
      headers,
    }).then((response) => {
      resCount++;

      infoShowText_helper(
        targetElem,
        infoTextBegin +
          resCount +
          ' из ' +
          queryCount +
          ' (' +
          roundNumber_helper((100 * resCount) / queryCount, 1) +
          ' %)',
        sectionReportsDetail,
      );

      return { aggregation: response };
    });

    requests.push(aggregationRequest);
    return requests;
  }
}

export function ajaxPositionsDoneAll(
  piecesPositions,
  targetElem,
  isOnMap,
  objectData,
) {
  if (!piecesPositions.length) {
    infoShowText_helper(
      targetElem,
      'Ошибка: данные отсутствуют, попробуйте обновить страницу. ',
    );
    return;
  }

  function ajaxPositionsDoneAllGetParam(result, jsonRes, paramName) {
    if (!result[paramName] && jsonRes[paramName]) {
      result[paramName] = String(jsonRes[paramName]).trim();
    }
  }

  const result = {
    objName: '',
    gearboxName: '',
    objId: '',
    getBegin: 0,
    getEnd: 0,
    memory_get_peak_usage_summ: 0,
    positions: [],
    aggregation: null,
  };

  if (!isOnMap) {
    result['error'] = '';
    result['objConf'] = '';
  }

  let positionsArr = [];
  for (let i = 0; i < piecesPositions.length; i++) {
    let piecePos = piecesPositions[i];

    if (!!piecePos.aggregation) {
      result.aggregation = piecePos.aggregation;
      continue;
    }

    let jsonRes;
    if (piecePos !== null && typeof piecePos === 'object') {
      jsonRes = piecePos;
    } else {
      jsonRes = JSON.parse(piecePos);
      piecesPositions[i] = ''; // free memory
    }

    if (!jsonRes) {
      console.error('Ошибка: получен НЕ JSON в номере ответа ' + i);
      continue;
    }

    if (
      !('objId' in jsonRes) ||
      // !('objName' in jsonRes) ||
      !('positions' in jsonRes)
    ) {
      console.error(
        'Ошибка: ответ получен, но он невалидный !!! в номере ответа ' + i,
      );
      continue;
    }

    if (!jsonRes['positions'].json_arr) {
      if (
        !(0 in jsonRes['positions']) ||
        !('json_agg' in jsonRes['positions'][0])
      ) {
        console.error(
          'Ошибка: ответ получен, но в нем нет информации о позициях !!! в номере ответа ' +
            i,
        );
        continue;
      }
    }

    let piecePosIsEmpty = true;
    for (let key in jsonRes) {
      piecePosIsEmpty = false;
      break;
    }

    if (piecePosIsEmpty) {
      console.error(
        'Ошибка: пустое тело при запросе позиций обычным методом в ответе номер ' +
          i,
      );
      continue;
    }

    if ('objConf' in jsonRes && jsonRes['objConf']) {
      result['objConf'] = jsonRes['objConf'];
    } else {
      result['objConf'] = objectData;
    }

    if ('error' in jsonRes && jsonRes['error']) {
      result['error'] += ' / ' + jsonRes['error'];
    }

    if (
      jsonRes['getBegin'] &&
      (!result['getBegin'] || result['getBegin'] > jsonRes['getBegin'])
    ) {
      result['getBegin'] = jsonRes['getBegin'];
    }

    if (
      jsonRes['getEnd'] &&
      (!result['getEnd'] || result['getEnd'] < jsonRes['getEnd'])
    ) {
      result['getEnd'] = jsonRes['getEnd'];
    }

    const objRow = document.getElementById(jsonRes.objId);

    if (objRow) {
      const tdName = objRow.children[3].innerText;
      result['objName'] = tdName || '';
    }
    // ajaxPositionsDoneAllGetParam(result, jsonRes, 'objName');
    ajaxPositionsDoneAllGetParam(result, jsonRes, 'objId');
    ajaxPositionsDoneAllGetParam(result, jsonRes, 'gearboxName');

    if (jsonRes['memory_get_peak_usage']) {
      result['memory_get_peak_usage_summ'] +=
        jsonRes['memory_get_peak_usage'] / 1000000; // usage for php script in server, Mb
    }

    if (jsonRes['positions'].json_arr) {
      const positions = arraysToObjectsArr(
        JSON.parse(jsonRes['positions'].json_arr),
      );
      jsonRes['positions'].json_arr = ''; // free memory

      if (jsonRes['other'] && !jsonRes['other']['error']) {
        const positionsOther = convertJsonAgg(
          jsonRes['other']['positions'][0]['json_agg'],
        );
        jsonRes['other']['positions'][0]['json_agg'] = ''; // free memory
        concatOtherPositions({ positions, positionsOther });
      }

      positionsArr.push({
        positions,
        getBegin: jsonRes['getBegin'],
      });
    } else if (jsonRes['positions'][0]['json_agg']) {
      const positions = convertJsonAgg(jsonRes['positions'][0]['json_agg']);
      jsonRes['positions'][0]['json_agg'] = ''; // free memory

      if (jsonRes['other'] && !jsonRes['other']['error']) {
        const positionsOther = convertJsonAgg(
          jsonRes['other']['positions'][0]['json_agg'],
        );
        jsonRes['other']['positions'][0]['json_agg'] = ''; // free memory
        concatOtherPositions({ positions, positionsOther });
      }

      positionsArr.push({
        positions,
        getBegin: jsonRes['getBegin'],
      });
    }
  }

  concatResultPositions(result, positionsArr);

  return result;
}

function convertJsonAgg(json_agg) {
  if (json_agg !== null && typeof json_agg === 'object') {
    return json_agg;
  } else {
    return JSON.parse(json_agg);
  }
}

export function concatAggregationWithDetail({ detail, aggregation }) {
  const detailPositions = detail;
  const aggregationPositions = aggregation;

  if (!aggregationPositions || !aggregationPositions.length) {
    return detailPositions || [];
  }

  const result = detailPositions.map((pos) => addCopyObj_helper(pos));

  const detailPositionsByTime = result.reduce((acc, pos) => {
    acc[pos.time] = pos;
    return acc;
  }, {});
  const aggregationPositionsByTime = aggregationPositions.reduce((acc, pos) => {
    acc[pos.time * 1000] = pos;
    return acc;
  }, {});

  let detailIndex = 0;
  for (const aggregationTime in aggregationPositionsByTime) {
    const aggregationPosition = aggregationPositionsByTime[aggregationTime];

    const preparedAggregationPositions = aggregationPosition;
    preparedAggregationPositions.time *= 1000;
    preparedAggregationPositions.distSumm = preparedAggregationPositions.dist;
    preparedAggregationPositions.gdist_not_engine =
      preparedAggregationPositions.dist_not_engine;

    for (const key in preparedAggregationPositions) {
      const val = preparedAggregationPositions[key];
      if (val === null || isNaN(val)) {
        preparedAggregationPositions[key] = val;
      } else {
        preparedAggregationPositions[key] = Number(val);
      }
    }

    if (aggregationTime in detailPositionsByTime) {
      const detailPosition = detailPositionsByTime[aggregationTime];

      for (const key in preparedAggregationPositions) {
        detailPosition[key] = preparedAggregationPositions[key];
      }
    } else {
      for (detailIndex; detailIndex < result.length; detailIndex++) {
        const { time: currDetailPosTime } = result[detailIndex];
        const { time: nextDetailPosTime } = result[detailIndex + 1] || {};
        if (+aggregationTime < result[0].time) {
          result.unshift(preparedAggregationPositions);
          detailIndex++;
          break;
        }

        if (
          +aggregationTime > currDetailPosTime &&
          +aggregationTime < nextDetailPosTime
        ) {
          // Добавляем позицию аггрегации после текущей позиции
          result.splice(detailIndex + 1, 0, preparedAggregationPositions);
          // Чтоб не было конфликта при обсчете
          result[detailIndex].is_last_day_pos = false;
          detailIndex++;
          break;
        }

        if (+aggregationTime > result[result.length - 1].time) {
          result.push(preparedAggregationPositions);
          break;
        }
      }
    }
  }

  return result;
}

export function concatOtherPositions({
  positions = [],
  positionsOther = [],
} = {}) {
  if (!positionsOther || !positionsOther.length) {
    return;
  }

  positionsOther = positionsOther.reduce((accum, pos) => {
    accum[pos.time] = pos;
    delete pos.time;
    return accum;
  }, {});

  positions.forEach((poition, index) => {
    const { time } = poition;
    const posOther = positionsOther[time] || {};

    for (let key in posOther) {
      const value = posOther[key];
      if (!index || value !== null) {
        poition[key] = value;
      }
    }
  });
}

function arraysToObjectsArr(arrays) {
  const columns = arrays[0];
  const objectsArr = [];
  for (let i = 1; i < arrays.length; i++) {
    objectsArr[i - 1] = {};
    for (let j = 0; j < columns.length; j++) {
      objectsArr[i - 1][columns[j]] = arrays[i][j];
    }
  }
  return objectsArr;
}

function concatResultPositions(result, positionsArr) {
  for (const key in result['objConf']) {
    result['objConf'][key] = String(result['objConf'][key]).trim();
  }

  positionsArr.sort(function (a, b) {
    return a['getBegin'] - b['getBegin'];
  });
  positionsArr.forEach((picePoses) => {
    const { positions } = picePoses;
    if (positions.length) {
      [].push.apply(result['positions'], positions);
    }
  });
}

export function preparingDataFromGetPos(
  objId,
  targetElem,
  isOnMap,
  timeInterval,
) {
  let preparing_data = {};
  preparing_data.objects = objId;

  if (!timeInterval.timeBegin) {
    infoShowText_helper(
      targetElem,
      'Неверно указаны дата или время начала запроса.',
    );
    return false;
  }
  if (!timeInterval.timeEnd) {
    infoShowText_helper(
      targetElem,
      'Неверно указаны дата или время окончания запроса.',
    );
    return false;
  }
  preparing_data.timeBegin = timeInterval.timeBegin / 1000;
  preparing_data.timeEnd = timeInterval.timeEnd / 1000;

  if (preparing_data.timeBegin >= preparing_data.timeEnd) {
    infoShowText_helper(
      targetElem,
      'неверно выбран интервал запроса, исправьте ошибку.',
    );
    return false;
  }

  preparing_data.timeOffset = -new Date().getTimezoneOffset() / 60;

  preparing_data.interval = preparing_data.timeEnd - preparing_data.timeBegin; //86400 переводит секунды в часы
  preparing_data.allColumns = isOnMap ? '' : 1;

  return preparing_data;
}

export function getCallOurPosotionsPrepareDataSends(preparing_data) {
  // const step = 43200000; // 86400 - сутки, 43200000 = 12 часов (в милисекундах)
  const step = 28800_000; // 28800 - 8 часов (в миллисекундах)
  const allColumns = true;

  const { timeEnd, timeBegin } = preparing_data;

  const getTimeBegin = timeBegin * 1000;
  const getTimeEnd = timeEnd * 1000;
  const getTimeInterval = getTimeEnd - getTimeBegin;

  preparing_data.aggregation = {
    id: preparing_data.objects,
    time_begin: timeBegin,
    time_end: timeEnd,
    option: 2,
    template_name: 'shiftWork',
    tzh: getTzh(),
  };

  preparing_data.sends = [];

  for (let i = 0; i < getTimeInterval; i += step) {
    let resBegin = getTimeBegin + i;
    if (i) resBegin++;

    let resEnd = getTimeBegin + i + step;

    if (resEnd > getTimeEnd) {
      resEnd = getTimeEnd;
    }

    let resInterval = resEnd - resBegin;

    if (resInterval <= 0) {
      continue;
    }

    let getConfig = !i || '';

    preparing_data.sends.push({
      interval: resInterval,
      objects: preparing_data.objects,
      timeBegin: resBegin,
      timeEnd,
      timeOffset: preparing_data.timeOffset,
      allColumns,
      getConfig: getConfig,
      isPython: false,
    });
  }
}

function treatmentResponseOurAndSave({
  response,
  timeCheck,
  targetElem,
  violationsDescriptionsDesc,
  violationsArray,
  isReturnResult,
  isGpsFilter,
  objectCategory,
  otherQueries,
}) {
  // сохранение позиций и вставка трека на карту
  if (
    !('objId' in response) ||
    !('objName' in response) ||
    !('positions' in response)
  ) {
    infoShowText_helper(
      targetElem,
      'Ошибка: ответ получен, но он невалидный !!!',
    );
    return;
  }

  infoShowText_helper(targetElem, 'Данные получены, обрабатываю ...');
  let positions = response['positions'];

  // const withGpsFilter = isGpsFilter ? ' с фильтрацией gps' : '';

  let trackBegin = formatDateHelper(
    new Date(response['getBegin'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  let trackEnd = formatDateHelper(
    new Date(response['getEnd'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );

  if (!positions.length) {
    // return;
  }

  let objName = response['objName'];
  let gearboxName = response['gearboxName'];
  let objId = response['objId'];

  const objectRow = document.getElementById(objId);
  const stateNumber = objectRow.querySelector('.search-stateNumber').innerText;

  const points = getPointsFromOur(
    positions,
    objName,
    gearboxName,
    violationsDescriptionsDesc,
    isGpsFilter,
    objectCategory,
  );
  points.stateNumber = stateNumber;
  points.objId = objId;
  points.aggregation = response.aggregation;

  const detailViolationsSetting = {
    ids: [],
    isAll: false,
  };

  let allPosesInDetailElementChecked = true;
  if (violationsArray.length) {
    detailViolationsSetting.ids = violationsArray.map((objectData) => ({
      id: +objectData.id,
      desc: objectData.description,
    }));

    detailViolationsSetting.isAll = false;

    allPosesInDetailElementChecked = false;
  } else {
    detailViolationsSetting.ids = violationsDescriptionsDesc.map(
      (objectData) => ({
        id: +objectData.id,
        desc: objectData.description,
      }),
    );

    detailViolationsSetting.isAll = true;
  }

  try {
    let skillsManDetails = SkillsManDetailCalculate({
      REPORT: response,
      violationsDescriptionsDesc,
      detailViolationsSetting,
      morePositionsCnt: 5,
      detailPosCntElementValue: 5,
      allPosesInDetailElementChecked,
    });

    const copiedSkillsManDetails = JSON.parse(JSON.stringify(skillsManDetails));
    copiedSkillsManDetails.header = { ...skillsManDetails.header };

    if (isReturnResult) {
      return copiedSkillsManDetails;
    }

    skillsManDetails = {};
  } catch (ex) {
    console.error(ex.message);
  }

  const { trackInfo, infoForShow } = useTrackInfo(
    {
      points,
      getBegin: response.getBegin,
      getEnd: response.getEnd,
      trackBegin,
      trackEnd,
      stateNumber,
      objName: response.objName,
    },
    timeCheck,
    objectCategory,
  );

  infoShowText_helper(targetElem, infoForShow);

  // add into positionsList
  let positionsList = document.getElementById('positionsList');
  let html =
    '<option selected value="' +
    objId +
    '">' +
    objName +
    '(' +
    trackBegin +
    ' - ' +
    trackEnd +
    ')' +
    '</option>';
  removeSelectOption(positionsList, '[value="' + objId + '"]'); // если ранее запрос уже был выполнен - удалим его
  positionsList.innerHTML += html;

  removeSelectOption(positionsList, '[value="none"]');

  leafletMain.deleteMarkersDrainOrRefueling(objId);
  // violations list setting - меню нарушений на карте заполнение
  if (!(objId in globalObjects.violationsSettingsList)) {
    // по умолчанию отключаем отображение нарушений на карте
    globalObjects.violationsSettingsList[objId] = {};
  }
  violationsSettingListInnerHtml(
    violationsDescriptions.desc,
    globalObjects.violationsSettingsList[objId],
    points['violations_stat'],
  );

  leafletMain.leafletAddPolyline(points, trackInfo, objId, globalObjects);
  leafletMain.addViolationsOnMap(
    points,
    objId,
    objName,
    gearboxName,
    globalObjects.violationsSettingsList[objId],
    false,
    otherQueries,
    response.objConf,
  );

  globalObjects.globalPositions[objId] = {
    points,
    trackInfo,
    infoForShow,
    getBegin: response['getBegin'],
    getEnd: response['getEnd'],
    trackBegin,
    trackEnd,
    stateNumber,
    objName,
    otherQueries,
  };
}

function getPointsFromOur(
  positions,
  objName,
  gearboxName,
  violationsDescriptionsDesc,
  isGpsFilter = false,
  objectCategory = '',
) {
  const fillPoint = (paramKey, currPosition, prevPosition) => {
    if (!(paramKey in currPosition) && paramKey in prevPosition) {
      currPosition[paramKey] = prevPosition[paramKey];
    }
  };

  const latLonDelimeter = 1_000_000_000_000_000;
  const points = {
    objName,
    gearboxName,
    latlngs: [],
    params: [], // values: [], пишется в params
    levels: [],
    allValues: positions, // для графиков,
    violations_stat: {
      // статистика по количеству нарушений
      summ: 0, // сумма всех нарушений
    },
    consumptions_stat: {
      // статистика по количеству заправок
      summ: 0, // сумма всех заправок
    },
    violations_text: [],
    violations_values: [],
  };

  let column = 0;
  let violation_id = [];
  let violation_text = [];
  let violation_values = [];

  let posIndex = 0;
  let maxSpeed = 0;

  let distSumm = 0;
  let distPrev = -1;
  let distBegin = -1;
  let timeWithDist = 0;
  let prevTimeWithDist = 0;
  let curSpeed = 0;

  let latPrev = 0;
  let lonPrev = 0;
  let headPrev = 0;

  let prevRpm = 0;
  let prevActiveTime = 0;
  let prevEngineTime = 0;
  let hirpm_notactime = 0;

  const levels = points.levels;
  const leveCount = 10;
  const levelsDesc = [
    { purpose: 'consumption', purposeText: 'расходный' },
    { purpose: 'cistern', purposeText: 'цистерна' },
  ];

  const useGPSSpeed = checkUseGPS(objectCategory, points.objName);

  const isDistCalculatingByDistDay =
    positions[0] && positions[0].dist_day !== null;

  const calculateDist = initCalculatingByDay();
  const calculateEngineTime = initCalculatingByDay();
  const calculateActiveTime = initCalculatingByDay();
  const calculateGDistNotEngine = initCalculatingByDay();

  for (let k = 0; k < positions.length; k++) {
    const {
      time,
      lat = 0,
      lon = 0,
      head,
      speed,
      gspd,
      violation_1,
      violation_2,
      clutch_time_uninterruptedly,
      pto_cnt_violation,
      spd_accel,
      gps = 0,
      dist,
      dist_day,
      gdist,
      moto_mask,
      gdist_not_engine,
      is_last_day_pos,
    } = positions[k];

    const vehicleSpeed = useGPSSpeed ? gspd : speed;
    const vehicleDist = useGPSSpeed
      ? gdist
      : isDistCalculatingByDistDay
      ? dist_day
      : dist;

    if (vehicleSpeed < 2500) {
      // 250 км/ч
      curSpeed = vehicleSpeed;
    }

    positions[k].speed = curSpeed;

    if (useGPSSpeed || isDistCalculatingByDistDay) {
      positions[k].distSumm = calculateDist(k, vehicleDist, is_last_day_pos);
    } else {
      const canDistProtocolLimit = 21055406000; // в метрах
      const isDist = Boolean(vehicleDist && vehicleDist < canDistProtocolLimit);

      // if (
      //   k > 627
      //   // time > 1693819500000
      // )
      //   debugger;

      // Инициализация счетчиков пробега
      if (isDist && distBegin === -1) {
        distBegin = vehicleDist;
        distPrev = vehicleDist;
      }

      if (isDist) {
        let dSummTemp = 0;

        // Если пробег больше чем в предыдущей позиции
        // берем его для расчетов
        // иначе пробег в предыдущей позиции
        if (vehicleDist > distPrev) {
          dSummTemp = vehicleDist - distBegin;
        } else {
          dSummTemp = distPrev - distBegin;
        }

        // Если есть время с пробегом и пробег больше обсчитанного пробега для предыдущей позиции
        if (timeWithDist && dSummTemp && dSummTemp > distSumm) {
          // Подготовка интервала за который был совершен пробег
          const timeDiff = time - timeWithDist;

          // Если не было интервала считаем что прошла секунда
          if (!timeDiff) {
            timeDiff = 1000;
          }

          // Находим среднюю скорость за интервал
          const avgSpdTemp =
            (dSummTemp - distSumm) / 1000 / (timeDiff / 3600000);

          // Если интервал выше максимального считаем что пробега нет
          // иначе обновляем время в котором был последний пробег
          if (avgSpdTemp > 250) {
            dSummTemp = distSumm;
          } else {
            prevTimeWithDist = timeWithDist;
            timeWithDist = time;
          }
        }

        // Инициализация времени с пробегом
        if (timeWithDist === 0) {
          prevTimeWithDist = timeWithDist;
          timeWithDist = time;
        }

        if (dSummTemp - distSumm > 100) {
          console.log(
            `Индекс: ${k}, Пробег: ${
              Math.floor((dSummTemp - distSumm) / 100) / 10
            } км. ${formatTimeHelper(
              (time - prevTimeWithDist) / 86400000,
              'tt:nn:ss',
            )} `,
          );
        }
        // Обновление значений
        distSumm = dSummTemp;
        distPrev = vehicleDist;
      }

      positions[k].distSumm = distSumm;
    }

    fillPoint('engine_time', positions[k], positions[k - 1]);
    fillPoint('engine_move', positions[k], positions[k - 1]);
    fillPoint('active_time', positions[k], positions[k - 1]);

    const engine_time = calculateEngineTime(
      k,
      positions[k]['engine_time'],
      is_last_day_pos,
    );
    const active_time = calculateActiveTime(
      k,
      positions[k]['active_time'],
      is_last_day_pos,
    );

    if (
      prevRpm > 800 &&
      positions[k]['rpm'] > 800 &&
      active_time - prevActiveTime <= 0
    ) {
      hirpm_notactime += engine_time - prevEngineTime;
    }

    prevRpm = positions[k]['rpm'];
    prevActiveTime = active_time;
    prevEngineTime = engine_time;

    positions[k]['hirpm_notactime'] = hirpm_notactime;

    if (!(lat > 0 && lon > 0)) {
      continue;
    }

    column = 0;
    violation_id = [];
    violation_text = [];
    violation_values = '';
    while ('violation_' + Number(++column) in positions[k]) {
      const col_name = 'violation_' + column;
      if (!(positions[k][col_name] > 0)) {
        continue;
      }

      for (let i = 0; i < 31; i++) {
        if (positions[k][col_name] & (1 << i)) {
          let viol_id = i + 1 + 31 * (column - 1);
          violation_id.push(viol_id);
          let violation_desc = getVolationDesc_helper(
            viol_id,
            violationsDescriptionsDesc,
          );
          violation_text.push(violation_desc['description']);

          if (viol_id in points['violations_stat']) {
            points['violations_stat'][viol_id]++;
          } else {
            points['violations_stat'][viol_id] = 1;
          }

          points['violations_stat']['summ']++;
        }
      }

      violation_values = {
        clutch_time_unine: clutch_time_uninterruptedly,
        pto_cnt_violation: pto_cnt_violation,
        spd_accel: spd_accel,
        spd: curSpeed,
      };
    }

    if (curSpeed > maxSpeed) {
      maxSpeed = curSpeed;
    }

    const [curLat, curLon] = [lat / latLonDelimeter, lon / latLonDelimeter];
    let [latRes, lonRes] = [curLat, curLon];
    const moto1isWork = Boolean(moto_mask & 1);
    const curSpeedKmH = curSpeed / 10;
    if (
      !isGpsFilter ||
      !k ||
      (moto1isWork && (headPrev || head)) ||
      (head && (curSpeedKmH > 7 || gps > 10)) ||
      getDistanceHelper(curLat, curLon, latPrev, lonPrev) > 0.2
    ) {
      [latPrev, lonPrev] = [curLat, curLon];
    } else {
      [latRes, lonRes] = [latPrev, lonPrev];
    }

    headPrev = head;

    points['latlngs'][posIndex] = [latRes, lonRes];

    levelsDesc.forEach((lvDesc) => {
      const { purpose, purposeText } = lvDesc;

      for (let ii = 0; ii < leveCount; ii++) {
        const lvNum = ii + 1;
        const lvName = `${purpose}_${lvNum}`;
        const lvVal = positions[k][`original_${lvName}`] ?? false;

        if (lvVal === false || (lvVal === null && !(lvName in levels))) {
          continue;
        }

        const lvStatus = positions[k][`status_${lvName}`];
        const lvAnalyzed = positions[k][`analyzed_${lvName}`];

        if (!(lvName in levels)) {
          const summText = lvStatus & 8 ? ' (сумма)' : '';

          levels[lvName] = {
            oiginal: [],
            analyzed: [],
            status: [],
            text: `ДУТ ${purposeText} ${lvNum}${summText}`,
            purpose,
          };
        }

        const level = levels[lvName];
        level.oiginal[posIndex] = lvVal !== null ? lvVal / 1000 : lvVal;
        level.analyzed[posIndex] =
          lvAnalyzed !== null ? lvAnalyzed / 1000 : lvAnalyzed;
        level.status[posIndex] = lvStatus;

        if (lvStatus & 16 || lvStatus & 64) {
          const value = level.analyzed[posIndex] - level.analyzed[posIndex - 1];

          points['consumptions_stat'][posIndex] = {
            latlon: points['latlngs'][posIndex],
            time,
            value: value.toFixed(1),
            status: lvStatus,
          };
        }
      }
    });

    if (violation_id.length > 1 && violation_id.includes(17)) {
      const removedIndex = violation_id.indexOf(17);

      if (removedIndex > -1) {
        violation_id.splice(removedIndex, 1);
        violation_text.splice(removedIndex, 1);

        points['violations_stat']['17']--;
        points['violations_stat']['summ']--;
      }
    }

    points['params'][posIndex] = {
      time,
      viewTime: time / 1000,
      speed: curSpeed,
      violation_1: violation_1,
      violation_2: violation_2,
      violation_id: violation_id.join(';'),
      violation_text: violation_text.join(';<br>'),
      violation_values: violation_values,
      head: head,
      gps,
      maxSpeed,
      violationsSumm: points['violations_stat']['summ'],
    };

    posIndex++;
  }

  window.points = points;
  return points;
}

export function initCalculatingByDay() {
  let reset = 0;
  let begin = 0;
  let end = 0;

  return (index, value, is_last_day_pos) => {
    if (index === 0) {
      begin = value;
    }

    end = value;

    const result = end - begin + reset;

    if (is_last_day_pos) {
      reset += value;
    }

    return result;
  };
}

function removeSelectOption(selectList, childSelector) {
  // remove option
  let childOption = selectList.querySelector(childSelector);
  if (childOption) {
    selectList.removeChild(childOption);
  }
  // add default option
  if (!selectList.options.length) {
    document.getElementById('violations-setting').innerHTML =
      'Меню нарушений на карте (нет запросов).';
    selectList.innerHTML += '<option value="none">Нет запросов</option>';
  } else {
    // проверить какой объект выводится - и по нему вывести настройку отображения
  }
}

function violationsSettingListInnerHtml(
  violationsDescriptionsDesc,
  violationsSettingsObject,
  violationsStat,
) {
  // add into html
  let violationsDescriptionList = getViolationsDescriptionList(
    violationsDescriptionsDesc,
    violationsSettingsObject,
    violationsStat,
  ); // html text
  document.getElementById('violations-setting').innerHTML =
    violationsDescriptionList;
}

function getViolationsDescriptionList(
  violationsDescriptionsDesc,
  violationsSetting,
  violationsStat,
) {
  // let violationsDescriptionListTemplate = document.getElementById(
  //   'violations-description-list-template',
  // ).innerHTML;
  // let compiled = _.template(violationsDescriptionListTemplate);
  let compiled = _.template(violationsDescriptionListHtml);
  let html = compiled({
    data: violationsDescriptionsDesc,
    violationsSetting: violationsSetting,
    violationsStat: violationsStat,
  });
  return html;
  // document.getElementById('section-reports').innerHTML = html;
}
