<template>
  <div class="wrapper">
    <div
      class="mobile-navigation__overflow"
      v-if="isMobile"
    >
      <span
        class="mobile-navigation"
        @click="mobileBtnImageSrc"
      >
        <svg
          v-if="nextBox === 'table'"
          xmlns="http://www.w3.org/2000/svg"
          height="37px"
          viewBox="0 0 24 24"
          width="37px"
          fill="#000000"
        >
          <path
            d="M0 0h24v24H0V0z"
            fill="none"
          />
          <path
            d="M10 10.02h5V21h-5zM17 21h3c1.1 0 2-.9 2-2v-9h-5v11zm3-18H5c-1.1 0-2 .9-2 2v3h19V5c0-1.1-.9-2-2-2zM3 19c0 1.1.9 2 2 2h3V10H3v9z"
          />
        </svg>

        <svg
          v-if="nextBox === 'graphic'"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="37px"
          viewBox="0 0 24 24"
          width="37px"
          fill="#000000"
        >
          <rect
            fill="none"
            height="24"
            width="24"
          />
          <g>
            <path
              d="M7.5,21H2V9h5.5V21z M14.75,3h-5.5v18h5.5V3z M22,11h-5.5v10H22V11z"
            />
          </g>
        </svg>

        <svg
          v-if="nextBox === 'list'"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="37px"
          viewBox="0 0 24 24"
          width="37px"
          fill="#000000"
        >
          <rect
            fill="none"
            height="24"
            width="24"
          />
          <path
            d="M3,14h4v-4H3V14z M3,19h4v-4H3V19z M3,9h4V5H3V9z M8,14h13v-4H8V14z M8,19h13v-4H8V19z M8,5v4h13V5H8z"
          />
        </svg>
      </span>
    </div>

    <div
      class="objects-list"
      v-show="nextBox === 'graphic' || !isMobile"
    >
      <objects-list
        v-model:date="objectsListDatepickerDate"
        :completeGraphData="isGetNewRequest"
        :selectedSmenas="selectedSmenas"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :violationsObjectsList="objectsList"
        @selectedSmenas="setSelectedSmenas"
        @objectsListIsUpdated="setObjectsList"
        @submit="requestStatistic"
      />
    </div>

    <div
      class="graphics-list"
      v-show="nextBox === 'list' || !isMobile"
    >
      <div
        class="center"
        v-show="
          [
            'loadingObjectsList',
            'objectsListIsLoaded',
            'requestSended',
            'error',
          ].includes(status)
        "
      >
        <div
          class="loading-process"
          v-html="loadingProcess"
        ></div>

        <div class="graphic-preloader">
          <preloader
            v-if="['loadingObjectsList', 'requestSended'].includes(status)"
          />
          <button
            v-if="status === 'objectsListIsLoaded'"
            class="btn btn-primary btn-lg"
            @click="requestStatistic"
          >
            Запросить статистику
          </button>
        </div>

        <svg id="violations-chart-preload"></svg>
      </div>
      <graphics-list
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :graphData="graphData"
        v-show="status === 'success'"
      />
    </div>

    <div class="modules-list">
      <modules-list
        v-show="nextBox === 'table' || !isMobile"
        :clientWidth="clientWidth"
        :clientHeight="clientHeight"
        :objects="objectsData"
        :data="graphData"
        :loadingProcess="modulesLoadingProcess"
      />
    </div>
    <the-toaster ref="toaster" />
  </div>
</template>

<script>
import ObjectsList from './components/VObjectsList.vue';
import GraphicsList from './components/VGraphicsList.vue';
import ModulesList from './components/VModulesList.vue';
import Preloader from '../Components/Preloader.vue';
import TheToaster from '../Components/toaster/TheToaster.vue';

import { OurAggregated } from '../../src/dataRequest/ourAggregated.js';
import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template.js';

import {
  colorByNumberHelper,
  colorByNumberHelper2,
  rounded100,
} from '@/helpers/MineHelper.js';
import fetchJson from '../../src/dataRequest/fetch-json';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';
import { CalculateGraphData } from '../../Template/graphics/CalculateGraphData';
import statisticCalculated from '@/Template/statistic/statisticCalculated';
import { usePrepareGeofencesForReports } from '@/App/use/prepareGeofencesForReports';
import PRELOAD_GRAPHIC_DATA from './PRELOAD_GRAPHIC_DATA.js';

import * as d3 from '@/d3/d3_v6_7_0/d3.min.js';

export default {
  name: 'ViolationsGraphicsLayout',
  components: {
    ObjectsList,
    GraphicsList,
    ModulesList,
    Preloader,
    TheToaster,
  },

  GEO_COLOR: '#3388ff',

  inject: ['allGeofences'],

  data() {
    return {
      objectsListDatepickerDate: [],
      objectsList: [],
      selectedSmenas: [
        // {
        //   begin: '07:00',
        //   end: '19:00',
        // },
        // {
        //   begin: '19:00',
        //   end: '07:00',
        // },
      ],

      graphData: [],

      isSuccessRequestToTemplatesService: true,
      status: 'loadingObjectsList',
      loadingProcess: 'Загрузка списка объектов...',
      nextBox: 'graphic',

      objectsData: {},
      modulesLoadingProcess: '',

      geofences: [],

      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      isMobile: false,
    };
  },

  mounted() {
    const d = new Date();

    const to = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);

    const daysInOneMonthAgo =
      32 - new Date(d.getFullYear(), d.getMonth(), 32).getDate();

    let from;
    if (to.getDate() >= daysInOneMonthAgo) {
      // первый день настоящего месяца
      from = new Date(d.getFullYear(), d.getMonth(), 1);
    } else {
      // тот же день текущего месяца, месяц назад
      from = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());
    }

    this.objectsListDatepickerDate = [from.toString(), to.toString()];

    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth;
      this.clientHeight = document.documentElement.clientHeight;
    });
    this.drawPreloadGraphic();
  },

  watch: {
    objectsListDatepickerDate(newDate, oldDate) {
      if (!newDate[1]) newDate[1] = oldDate[1];
    },
    clientWidth: {
      handler(newClientWidth) {
        this.drawPreloadGraphic();

        if (newClientWidth <= 1024) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      },
      immediate: true,
    },
    clientHeight: {
      handler(newClientHeight) {
        this.drawPreloadGraphic();
      },
      immediate: true,
    },
  },

  NEW_GEOFENCE() {
    return {
      name: 'Новая геозона',
      newRadius: 0,
      shape: '',
      speedLimit: 0,
      info: '',
      owner: '',
      organization: '',
      type: 0,
      id: '-',
      geoJson: '',
      radius: '-',
      // saved: false,
      editing: false,
      layer: null,
      key: new Date().getTime(),
      // geoJsonBeforeEdit
      beforeEdit: {},
      lastEdit: {},
      sending: false,
      sendingError: '',
      deleting: false,
      deletingError: '',
      isVisible: true,
    };
  },

  computed: {
    isGetNewRequest() {
      return ['success', 'error', 'objectsListIsLoaded'].includes(this.status);
    },
  },

  methods: {
    drawPreloadGraphic() {
      if (document.getElementById('violations-chart-preload') === null) return;
      const objects = PRELOAD_GRAPHIC_DATA;

      let width = 800;
      let height = 450;
      const margin = { top: 20, bottom: 80, left: 10, right: 10 };

      const onePercentWidth = this.clientWidth / 100 || 0;
      const onePercentHeight = this.clientHeight / 100 || 0;
      if (this.clientWidth <= 768) {
        const countObjects = objects.length;
        if (countObjects <= 3) {
          width = onePercentWidth * 90 - 40;
        } else if (countObjects <= 10) {
          width = countObjects * 50;
        } else {
          width = countObjects * 20;
        }
        height = onePercentHeight * 80 - 125;
      } else if (this.clientWidth <= 1024) {
        width = onePercentWidth * 90 - 40;

        height = onePercentHeight * 80 - 150;
      } else {
        width = onePercentWidth * 55 - 40;
        height = onePercentHeight * 80 - 150;
      }

      height = height > 0 ? height : 0;

      document.getElementById('violations-chart-preload').innerHTML = '';

      const svg = d3
        .select('#violations-chart-preload')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height]);

      const x = d3
        .scaleBand()
        .domain(d3.range(objects.length))
        .range([margin.left, width - margin.right])
        .padding(0.2);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(objects, (d) => d.value)])
        .range([height - margin.bottom, margin.top]);

      svg
        .append('g')
        .attr('fill', '#ccc')
        .selectAll('rect')
        .data(objects)
        .join('rect')
        .attr('x', (d, i) => x(i) || 0)
        .attr('y', (d) => y(d.value) || 0)
        .attr('width', x.bandwidth() || 0)
        .attr('height', (d) => {
          return y(0) - y(d.value) > 0 ? y(0) - y(d.value) : 0;
        });

      const xAxis = (g) => {
        g.attr('transform', `translate(0, ${height - margin.bottom})`)
          .attr('fill', 'none')
          .call(d3.axisBottom(x).tickFormat((i) => ''));
      };

      const yAxis = (g) => {
        g.attr('transform', `translate(${margin.left}, 0)`)
          .attr('fill', 'none')
          .call(d3.axisLeft(y).tickFormat((i) => ''));
      };

      svg.append('g').call(xAxis);
      svg.append('g').call(yAxis);
      svg.node();
    },
    async sendData(url, data) {
      try {
        const responce = await fetchJson(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: data,
        });
        return { responce };
      } catch (e) {
        return {
          error: 'Ошибка отправки',
        };
      }
    },
    setObjectsList(objects) {
      this.objectsList = objects;
      this.status = 'objectsListIsLoaded';
      this.loadingProcess = 'Список объектов загружен';
    },
    getSmenaId(id) {
      this.selectedSmenaId = id;
    },
    setSelectedSmenas(smenasArr) {
      this.selectedSmenas = smenasArr;
    },
    mobileBtnImageSrc() {
      if (this.nextBox === 'graphic') {
        this.nextBox = 'list';
      } else if (this.nextBox === 'list') {
        this.nextBox = 'table';
      } else this.nextBox = 'graphic';
    },
    requestStatistic() {
      this.status = 'requestSended';

      if (this.isMobile) {
        this.nextBox = 'list';
      }

      const objIdArr = this.objectsList.reduce((acc, obj) => {
        if (obj.flag) {
          acc.push(obj.id);
        }
        return acc;
      }, []);

      this.loadingProcess = `Загружено 0 из ${objIdArr.length} (0%)`;

      const ourAggregated = new OurAggregated(skillsManTemplate);

      const smenasBeginTimesIntoUnixTime = this.selectedSmenas.map(
        (objSmena) => {
          const [begin, end] = Object.values(objSmena).map((val) =>
            val.split(':'),
          );

          return (
            new Date(Date.UTC(1970, 0, 1, begin[0], 0, 0)).getTime() / 1000
          );
        },
      );
      const timeBegin = Math.round(
        new Date(this.objectsListDatepickerDate[0]).getTime() / 1000,
      );
      const timeEnd = Math.round(
        new Date(this.objectsListDatepickerDate[1]).getTime() / 1000,
      );

      const geofencesWorkZone = this.allGeofences.filter(
        (geo) => parseInt(geo.type) === 8,
      );
      const geofencesRegularZone = this.allGeofences.filter((geo) =>
        [1, 2, 3, 5].includes(parseInt(geo.type)),
      );

      const geofencesWorkZoneIds = [];

      const geofencesRegularZoneIds = geofencesRegularZone.reduce(
        (acc, geo) => {
          acc.push(+geo.id);
          return acc;
        },
        [],
      );

      const dataSend = {
        smena_1: smenasBeginTimesIntoUnixTime[0],
        smena_2: smenasBeginTimesIntoUnixTime[1],
        smena_3: smenasBeginTimesIntoUnixTime[2],
        time_begin: timeBegin,
        time_end: timeEnd,
        option: 2,
        template_name: 'regularMovingGeoTemplate',
        geofences: geofencesRegularZoneIds.concat(geofencesWorkZoneIds),
        is_basket: false,
      };

      const geofencesLayerData = usePrepareGeofencesForReports(
        geofencesRegularZoneIds.concat(geofencesWorkZoneIds),
        this.allGeofences,
      );
      const params = {
        componentName: 'violationsIndicator',
        objectsList: Object.assign({}, this.objectsList),
        objectsDisableList: [],

        isBasket: false,
        isGeoCalc: true,
        isMovingGroup: false,

        geofencesWorkZone: [...geofencesWorkZoneIds],
        geofencesToTemplate: [...geofencesRegularZoneIds],
        leafletGeofences: this.allGeofences,
        geofencesLayerData,
        templateName: 'regularMovingGeoTemplate',
      };

      this.loadingProcess = 'Отправлен запрос на сервер...';

      const templateName = 'regularMovingGeoTemplate';

      this.isSuccessRequestToTemplatesService = true;

      fetchToTemplateService({
        templateName: 'statistic',
        form_desc: {
          objects: objIdArr,
          beginTime: timeBegin * 1000,
          endTime: timeEnd * 1000,
        },
        options: {
          templateName,
          params,
          isGetSmenas: true,
          smenasOrigin: this.selectedSmenas,
        },

        onDownloadProgressHandler: (percent) =>
          (this.loadingProcess = `Загрузка данных (${percent}%)`),

        progressCallback: (progress) => (this.loadingProcess = progress),

        successCallback: this.calculated,

        errorCallback: () => {
          this.isSuccessRequestToTemplatesService = false;
          ourAggregated.getAggregated({
            dataArr: { objIdArr },
            dataSend,
            templateName,
            callback: (data) => {
              try {
                this.calculated(data);
              } catch (error) {
                this.status = 'error';
                this.loadingProcess =
                  'Ошибка при обсчете данных, попробуйте повторить запрос';
              }
            },
            params,
            isGetSmenas: true,
            smenasOrigin: this.selectedSmenas,
            VueLoadingProcess: (str) => (this.loadingProcess = str),
            VueErrorHandler: (error) => {
              this.status = 'error';
              this.loadingProcess = error;
            },
          });
        },
      });
    },
    calculated(data) {
      this.loadingProcess = 'Данные загружены. Обработка...';

      this.status = 'success';

      let { violationSummOfPeriodsDatasets, titleParts, format } = this
        .isSuccessRequestToTemplatesService
        ? data
        : statisticCalculated.getViolationSummOfPeriods(data);

      format = formatToDisplay_helper;

      this.graphData = violationSummOfPeriodsDatasets;
      this.graphData[0].statisticOfViolationsPerAllPeriods.format = format;

      // КОЛИЧЕСТВО НАРУШЕНИЙ НА 100КМ ЗА ПЕРИОД В ТАБЛИЦУ ОБЪЕКТОВ
      this.objectsList.map((obj, index) => {
        const sumViolationsAndDistancePerAllPeriodsOfObject = {
          violations: 0,
          distance: 0,
        };

        // Суммируем все нарушения и всю дистанцию по всем сменам
        violationSummOfPeriodsDatasets.map((obj) => {
          if (!obj.countViolationsOfPeriods[index]) return;
          sumViolationsAndDistancePerAllPeriodsOfObject.violations +=
            obj.countViolationsOfPeriods[index].sumAllViolations;
          sumViolationsAndDistancePerAllPeriodsOfObject.distance +=
            obj.countViolationsOfPeriods[index].distance;
        });

        const s = sumViolationsAndDistancePerAllPeriodsOfObject;

        // Если дистанция больше 10 км подсчитываем нарушения на 100 км. Иначе 0
        const violationsPer100km =
          s.distance > 10 ? (s.violations / s.distance) * 100 : 0;

        // Заносим данные каждому объекту и присваиваем цвет
        obj.violationsPer100km = violationsPer100km;
        obj.hex = colorByNumberHelper(violationsPer100km);
      });

      // КОЭФФИЦИЕНТ ВЫХОДА НА ЛИНИЮ
      const countOfObjects =
        violationSummOfPeriodsDatasets[0].countViolationsOfPeriods.length;
      let countOfObjectsWithDistMore10kmPerPeriod = 0;

      // Коэффициент выхода на линию по сменам
      const ratiosOutLineOfSmena = violationSummOfPeriodsDatasets.map((obj) => {
        //Суммируем все объекты с пробегом за смену выше 10 км
        countOfObjectsWithDistMore10kmPerPeriod +=
          obj.countOfObjectsWithDistMore10km;

        // Коэффициент выхода на линию за смену по формуле:
        //
        // Округляем до сотых(количество объектов с пробегом больше 10км / количество всех выбранных объектов)
        const val = rounded100(
          obj.countOfObjectsWithDistMore10km / countOfObjects,
        );

        // Получаем цвет по коэффициенту
        // менее 0,6 – закрашивается красным,
        // в диапазоне от 0,6 – 0,9 – синим,
        // более 0,9 – зеленым.
        const bgcolor = colorByNumberHelper2(val);

        return {
          value: val,
          backgroundColor: bgcolor,
          label: obj.label,
          shortLabel: obj.shortLabel,
          countViolationsOfPeriods: obj.countViolationsOfPeriods,
        };
      });

      this.objectsData.ratiosOutLineOfSmena = ratiosOutLineOfSmena;

      // Коэффициент выхода на линию за выбранный период по формуле:
      //
      // Количество объектов с дистанцией более 10км / (количество всех объектов * количество смен за период)
      const valueRatioOutLineOfPeriod = rounded100(
        countOfObjectsWithDistMore10kmPerPeriod /
          (countOfObjects * ratiosOutLineOfSmena.length),
      );

      const calculateGraphData = new CalculateGraphData({ format });
      // Получаем заголовок к графику
      let periodsLabels = calculateGraphData.titleTemplate(
        titleParts.smenasStr,
        titleParts.t_interval,
        'График коэффициента выхода на линию ',
      );
      // Записываем коэффициент выхода на линию за период для модуля
      this.objectsData.ratioOutLineOfPeriod = {
        value: valueRatioOutLineOfPeriod,
        backgroundColor: colorByNumberHelper2(valueRatioOutLineOfPeriod),
        title: periodsLabels.title,
      };

      // РАСХОД ТОПЛИВА ЛИТРОВ НА 100КМ ПО ПАРКУ В СРЕДНЕМ НА ОДНО ТС
      let countCanExpencePerPeriod = 0;
      let countDistancePerPeriod = 0;

      violationSummOfPeriodsDatasets.map((obj) => {
        let countCanExpencePerSmena = 0;
        let countDistancePerSmena = 0;

        obj.countViolationsOfPeriods.map((obj) => {
          if (obj.distance > 10) {
            // Суммируем топливо и пробег за период по каждому объекту за смену
            countCanExpencePerPeriod += obj.canExpence;
            countDistancePerPeriod += obj.distance;

            // Суммируем топливо и пробег за смену
            countCanExpencePerSmena += obj.canExpence;
            countDistancePerSmena += obj.distance;

            // Добавляем каждому объекту расход топлива литров на 100 км для таблицы по формуле:
            //
            // (Расход топлива у объекта за смену / пробег объекта за смену) * 100
            obj.canExpenceFuelLiters100km = rounded100(
              (obj.canExpence / obj.distance) * 100,
            );
          }
        });

        // Добавляем каждой смене расход топлива литров на 100км для графика
        //
        // (Суммарный расход топлива всех объектов за смену / суммарный пробег всех объектов за смену) * 100
        obj.canExpenceFuelLiters100km =
          rounded100((countCanExpencePerSmena / countDistancePerSmena) * 100) ||
          0;
      });

      // Считаем за период расход топлива литров на 100км
      //
      // (Суммарный расход топлива всех объектов за весь период / суммарный пробег всех объектов за весь период) * 100
      const canExpenceFuelLiters100kmOfPeriod = rounded100(
        (countCanExpencePerPeriod / countDistancePerPeriod) * 100,
      );

      // Получаем заголовок к графику
      periodsLabels = calculateGraphData.titleTemplate(
        titleParts.smenasStr,
        titleParts.t_interval,
        'Общий расход топлива литров на 100 км. по парку ТС, в среднем на одно ТС ',
      );

      // Записываем расход топливо литров на 100км по парку за период для модуля
      this.objectsData.canExpenceFuelLiters100kmOfPeriod = {
        value: canExpenceFuelLiters100kmOfPeriod,
        backgroundColor: colorByNumberHelper2(
          canExpenceFuelLiters100kmOfPeriod / 100,
        ),
        title: periodsLabels.title,
      };

      // ТОПЛИВО ВСЕГО ЛИТРОВ
      let countAllFuelLiters = 0;

      violationSummOfPeriodsDatasets.map((obj) => {
        let countAllFuelLitersPerSmena = 0;

        obj.countViolationsOfPeriods.map((obj) => {
          // Подсчитываем все топливо за период
          countAllFuelLiters += obj.canExpence;

          // Подсчитываем все топливо за смену
          countAllFuelLitersPerSmena += obj.canExpence;

          // В таблице столбец "Топливо, литров" заполняется obj.canExpence
        });

        // Записываем все топливо за смену для графика
        obj.countAllFuelLitersPerSmena = countAllFuelLitersPerSmena;
      });

      // Получаем заголовок к графику
      periodsLabels = calculateGraphData.titleTemplate(
        titleParts.smenasStr,
        titleParts.t_interval,
        'Топливо по парку всего литров ',
      );

      // Записываем топливо всего литров за период для модуля
      this.objectsData.allFuelLiters = {
        value: countAllFuelLiters,
        backgroundColor: '#83f095',
        title: periodsLabels.title,
      };

      // КОЭФФИЦИЕНТ ИСПОЛЬЗОВАНИЯ ГРУЖЕННОГО ПРОБЕГА
      let regularMovingDistPerAllPeriods = 0;
      let distancePerAllPeriods = 0;
      violationSummOfPeriodsDatasets.map((obj) => {
        let regularMovingDistPerSmena = 0;
        let distancePerSmena = 0;

        obj.countViolationsOfPeriods.map((item) => {
          if (item.distance < 10) {
            item.ratioUseDist = 0;
            return;
          }

          // Подсчитываем весь груженный пробег и весь пробег за период
          regularMovingDistPerAllPeriods += item.regularMovingDist;
          distancePerAllPeriods += item.distance;

          // Подсчитываем весь груженный пробег и весь пробег за смену
          regularMovingDistPerSmena += item.regularMovingDist;
          distancePerSmena += item.distance;

          item.ratioUseDist =
            rounded100(item.regularMovingDist / item.distance) || 0;
        });
        // Записываем все топливо за смену для графика
        obj.ratioUseDistPerSmena =
          rounded100(regularMovingDistPerSmena / distancePerSmena) || 0;
      });

      // Получаем заголовок к графику
      periodsLabels = calculateGraphData.titleTemplate(
        titleParts.smenasStr,
        titleParts.t_interval,
        'Коэффициент использования пробега ',
      );

      const ratioUseDistOfAllPeriods =
        rounded100(regularMovingDistPerAllPeriods / distancePerAllPeriods) || 0;

      this.objectsData.ratioUseDistOfPeriod = {
        value: ratioUseDistOfAllPeriods,
        backgroundColor: colorByNumberHelper2(ratioUseDistOfAllPeriods),
        title: periodsLabels.title,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
  }
}
.objects-list {
  width: 22.5%;
}
.graphics-list {
  width: 55%;
}
.modules-list {
  width: 22.5%;
}
.loading-process {
  margin-top: 20px;
  font-size: 25px;
  text-align: center;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#violations-chart-preload {
  position: relative;
}
.graphic-preloader {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  background: rgba($color: #fff, $alpha: 0.8);
  border-radius: 5px;

  -webkit-box-shadow: 0px 0px 20px 0px rgba(48, 55, 60, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(48, 55, 60, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(48, 55, 60, 0.2);

  padding: 10px 20px;
}
.mobile-navigation {
  margin-top: 5px;
  /* padding: 3px 3px 0 3px; */
  width: 40px;
  height: 40px;
  background-size: contain;
  cursor: pointer;
  border: 1px solid black;
}
.mobile-navigation__overflow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .objects-list,
  .graphics-list,
  .modules-list {
    width: 100%;
  }
}
@media (orientation: portrait) {
  .objects-list,
  .graphics-list,
  .modules-list {
    width: 100%;
  }
}
</style>
