import { accessToken } from '../jwtSender';

import { WSTemplatesFetcher } from '@/WebSocket/templates/WSTemplatesFetcher';

const portAndPath = String(process.env.VUE_APP_TEMPLATE_SERVICE_PORT_AND_PATH);
let hostname, protocol, port, path;

if (portAndPath.startsWith(":") || portAndPath.startsWith("/")) { // example :5000/api/templates/
  const regex = /:?(\d+)?(\/.+)$/;
  const matches = portAndPath.match(regex);
  if (matches) {
    protocol = process.env.NODE_ENV === 'development' ? 'ws' : "wss";
    hostname = process.env.VUE_APP_TEMPLATE_SERVICE_URL || window.location.hostname;
    port = matches[1] ? matches[1]: '';
    path = matches[2];
  }
} else if (portAndPath.startsWith("ws://") || portAndPath.startsWith("wss://")) { // example wss://dev.sample-site.ru:5000/api/templates/
  const regex = /^(\w+):\/\/([^:/]+):(\d+)(\/.+)$/;
  const matches = portAndPath.match(regex);
  if (matches) {
    protocol = matches[1];
    hostname = matches[2];
    port = matches[3];
    path = matches[4];
  }
}

const URL_GET_TEMPLATE = `${protocol}://${hostname}:${port}${path}`;

export default async ({
  templateName = '',
  form_desc = false,
  templateHtml = '',
  options = {},
  areaId = '',
  callbackComponent = {},

  successCallback = false,
  errorCallback = () => {},
  progressCallback = false,

  onDownloadProgressHandler = () => {},
}) => {
  try {
    if (!templateName) throw new Error('Template name is not defined');

    if (!form_desc) {
      form_desc = objectsListFormSerializeArray_helper('objectsListForm');
    }

    const { targetSpan: targetElement = {}, button: buttonElement = {} } =
      templateName === 'statistic'
        ? {}
        : objectsListFormColorAndTarget_helper(form_desc);

    if (!targetElement || !buttonElement) return;

    infoShowText_helper(targetElement, `Запрос отправлен...`, areaId);

    const dataSend = {
      ids: form_desc.objects,
      time_begin: form_desc.beginTime / 1000,
      time_end: form_desc.endTime / 1000,
    };

    dataSend.token = accessToken.value.token;

    dataSend.tzh = 0 - new Date().getTimezoneOffset() / 60;

    for (let key in options) {
      dataSend[key] = options[key];
    }

    const firstMessage = {
      template_name: templateName,
      body: dataSend,
    };

    const message = JSON.decycle(firstMessage);

    const url = new URL(URL_GET_TEMPLATE);
    url.searchParams.append('roomid', templateName + new Date().getTime());

    const doneHandler = requestDoneHandler({
      templateName,
      successCallback,
      templateHtml,
      targetElement,
      buttonElement,
      areaId,
      callbackComponent,
      options,
    });

    const templatesFetcher = new WSTemplatesFetcher(url, message);

    templatesFetcher.startWS(
      doneHandler,
      errorCallback,
      showMessageHandler(progressCallback, targetElement, areaId),
    );
  } catch (err) {
    console.error(err.message);
    // запрос и вычисления
    return errorCallback();
  }
};

const requestDoneHandler = ({
  templateName,
  successCallback,
  templateHtml,
  targetElement,
  buttonElement,
  areaId,
  callbackComponent,
  options,
}) => {
  return (data) => {
    data = JSON.retrocycle(data);
    if (
      [
        'statistic',
        'skillsMan',
        'canIndicators',
        'getCalculated',
        'criticalViolations',
        'getCalculatedWithGeofences',
        'regularMovingGeoTemplate',
        'evacuatorMoving',
      ].includes(templateName)
    ) {
      data.format = formatToDisplay_helper;
    }

    if (successCallback) {
      const params = {
        areaId,
        targetElement,
        buttonElement,
        callbackComponent,
        isGetSmenas: options.isGetSmenas,
      };

      return successCallback(data, params);
    }

    lodashRenderHtmlByTemplateHtml_helper(
      data,
      templateHtml,
      'section-reports',
    );

    infoShowText_helper(
      targetElement,
      'Суммарный отчет построен. Выполнить новый запрос?',
    );

    buttonElement.style.borderColor = 'green';
  };
};

const showMessageHandler = (progressCallback, targetElement, areaId) => {
  return (message) => {
    if (progressCallback) return progressCallback(message);

    infoShowText_helper(targetElement, message, areaId);
  };
};
