<template>
  <div>
    <div>
      <critical-violations
        v-if="showComponents.criticalViolations"
        :trigger="triggers.criticalViolationsTrigger"
      />
      <skills-man-detail-settings
        v-show="showComponents.skillsManDetailSettings"
        :violationsDescriptions="violationsDescriptions"
        :userRole="userRole"
        @close="close"
      />
      <shift-work-settings
        v-if="showComponents.shiftWorkTemplateSettings"
        @close="close"
      />
      <excavator-operation-settings
        v-if="showComponents.specMachineTemplateSettings"
        :objects="form_desc.objects"
        :interval="{ from: form_desc.beginTime, to: form_desc.endTime }"
        @display-report="(report) => displayReport('specMachine', report)"
        @close="() => close('specMachineTemplateSettings')"
      />
      <diagnostic-settings
        v-if="showComponents.diagnosticSettings"
        :objects="form_desc.objects"
        :interval="{ from: form_desc.beginTime, to: form_desc.endTime }"
        :reportName="form_desc.templateName"
        @display-report="
          (report) => displayReport(form_desc.templateName, report)
        "
        @close="() => close('diagnosticSettings')"
      />
    </div>
    <div>
      <evacuator-moving-template
        v-if="showComponents.evacuatorMovingTemplate"
        :trigger="triggers.evacuatorMovingTrigger"
      />
    </div>
    <div>
      <clients-monthly
        v-if="showComponents.clientsMonthlyTemplate"
        :trigger="triggers.clientsMonthlyTrigger"
      />
    </div>
    <div>
      <regular-moving-by-geo-template-setting
        v-show="showComponents.regularMovingByGeoTemplateSetting"
        @close="close"
        :is-showing="showComponents.regularMovingByGeoTemplateSetting"
      />
    </div>
    <div>
      <overspeed-settings
        v-show="showComponents.overspeedSettings"
        :form_desc="form_desc"
        :is-showing="showComponents.overspeedSettings"
        @close="close"
      />
    </div>
    <div>
      <excavator-operation
        v-if="showComponents.specMachine"
        :data="displayedReportData['specMachine']"
      />
    </div>
    <div>
      <diagnostic-template
        v-if="showComponents.diagnostic"
        name="diagnostic"
        :data="displayedReportData['diagnostic']"
      />
      <diagnostic-template
        v-if="showComponents.diagnosticDetail"
        name="diagnosticDetail"
        :data="displayedReportData['diagnosticDetail']"
      />
    </div>
  </div>
</template>

<script>
import { OurAggregated } from '../../src/dataRequest/ourAggregated';
import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template';

import { skillsManTemplateHtml } from './SkillsMan/SkillsManTemplateHtml';

import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';

const ourAggregated = new OurAggregated(skillsManTemplate);

import ShiftWorkSettings from './ShiftWork/ShiftWorkSettings.vue';
import SkillsManDetailSettings from './SkillsManDetail/SkillsManDetailSettings.vue';
import RegularMovingByGeoTemplateSetting from './RegularMoving/RegularMovingByGeoSetting.vue';
import OverspeedSettings from './Overspeed/OverspeedSettings.vue';
import CriticalViolations from './CriticalViolations/CriticalViolations.vue';
import EvacuatorMovingTemplate from './EvacuatorMoving/EvacuatorMovingTemplate.vue';
import clientsMonthly from './ClientsMonthly/ClientsMonthly.vue';
import ExcavatorOperationSettings from './ExcavatorOperation/ExcavatorOperationSettings.vue';
import ExcavatorOperation from './ExcavatorOperation/ExcavatorOperation.vue';
import DiagnosticSettings from './Diagnostic/DiagnosticSettings.vue';
import DiagnosticTemplate from './Diagnostic/DiagnosticTemplate.vue';

import { CanIndicatorsTemplate } from '../../Template/skills_man_template/can_indicators_template';
import { ClutchWorkTemplate } from '../../Template/skills_man_template/clutch_work_template';
import { CriticalViolationsTemplate } from '../../Template/skills_man_template/critical_violations_template';
import { FuelInOutTemplate } from '../../Template/fuel_in_out_template/fuel_in_out_template';

export default {
  props: {
    userRole: {
      type: String,
      default: '',
    },
  },
  components: {
    ShiftWorkSettings,
    // RegularMovingByGeoTemplate,
    RegularMovingByGeoTemplateSetting,
    OverspeedSettings,
    EvacuatorMovingTemplate,
    clientsMonthly,
    SkillsManDetailSettings,
    CriticalViolations,
    ExcavatorOperationSettings,
    ExcavatorOperation,
    DiagnosticSettings,
    DiagnosticTemplate,
  },
  data() {
    return {
      violationsDescriptions: [],
      form_desc: {},
      displayedReportData: {},
      showComponents: {
        criticalViolations: false,
        skillsManDetailSettings: false,
        shiftWorkTemplateSettings: false,
        // regularMovingByGeoTemplate: false,
        regularMovingByGeoTemplateSetting: false,
        specMachineTemplateSettings: false,
        evacuatorMovingTemplate: false,
        clientsMonthlyTemplate: false,
        overspeedSettings: false,
        specMachine: false,
        diagnosticSettings: false,
        diagnostic: false,
        diagnosticDetail: false,
      },
      triggers: {
        evacuatorMovingTrigger: false,
        clientsMonthlyTrigger: false,
        criticalViolationsTrigger: false,
      },
    };
  },
  methods: {
    templateStart(event) {
      const area = document.getElementById('section-reports');
      const detailArea = document.getElementById('section-reports-detail');

      if (
        event.detail.name !== 'diagnostic' &&
        event.detail.name !== 'diagnosticDetail'
      ) {
        this.close('diagnostic');
      }

      // template start proxy
      switch (event.detail.name) {
        case 'skillsManTemplate':
          this.skillsManReport_render();
          break;
        case 'canIndicators':
          this.canIndicatorsReport_render();
          break;
        case 'clutchWork':
          this.clutchWorkReport_render();
          break;
        case 'criticalViolations':
          this.criticalViolationsReport_render();
          break;
        case 'skillsManDetail':
          this.violationsDescriptions = event.detail.violationsDescriptions;
          this.show('skillsManDetailSettings');
          document.getElementById('background-wrap').style.display = 'flex';
          break;
        case 'shiftWork':
          this.show('shiftWorkTemplateSettings');
          document.getElementById('background-wrap').style.display = 'flex';
          break;
        case 'clientsMonthlyTemplate':
          this.showComponents.clientsMonthlyTemplate = false;
          this.showComponents.clientsMonthlyTemplate = true;
          this.$nextTick(() => {
            this.triggers.clientsMonthlyTrigger =
              !this.triggers.clientsMonthlyTrigger;
          });
          break;
        case 'fuelInOut_consumption':
          this.fuelInOut_consumptionReport_render();
          break;
        case 'fuelInOut_cistern':
          this.fuelInOut_cisternReport_render();
          break;
        case 'evacuatorMoving':
          this.showComponents.evacuatorMovingTemplate = false;
          this.showComponents.evacuatorMovingTemplate = true;
          this.$nextTick(() => {
            this.triggers.evacuatorMovingTrigger =
              !this.triggers.evacuatorMovingTrigger;
          });
          break;
        case 'regularMovingByGeo':
          this.show('regularMovingByGeoTemplateSetting');
          break;
        case 'overspeed':
          this.form_desc =
            objectsListFormSerializeArray_helper('objectsListForm');
          objectsListFormColorAndTarget_helper(this.form_desc);

          if (this.form_desc.objects.length) {
            this.show('overspeedSettings');
          }

          break;
        case 'specMachine':
          this.form_desc =
            objectsListFormSerializeArray_helper('objectsListForm');
          objectsListFormColorAndTarget_helper(this.form_desc);

          if (this.form_desc.objects.length) {
            this.show('specMachineTemplateSettings');
          }

          this.close('specMachine');

          if (area) area.innerHTML = '';

          break;
        case 'diagnostic':
        case 'diagnosticDetail':
          this.form_desc =
            objectsListFormSerializeArray_helper('objectsListForm');
          objectsListFormColorAndTarget_helper(this.form_desc);

          this.form_desc.templateName = event.detail.name;

          if (this.form_desc.objects.length) {
            this.show('diagnosticSettings');
          }
      }
    },
    close(name) {
      this.showComponents[name] = false;
    },
    show(name) {
      this.showComponents[name] = true;
    },
    displayReport(reportName, reportData) {
      this.close(reportName);
      this.$nextTick().then(() => {
        this.show(reportName);
        this.displayedReportData[reportName] = reportData;
      });
    },

    skillsManReport_render() {
      fetchToTemplateService({
        templateName: 'skillsMan',
        templateHtml: skillsManTemplateHtml,
        options: { option: 0 },
        areaId: 'section-reports',
        errorCallback: this.skillsManReport_action,
      });
    },
    skillsManReport_action() {
      ourAggregated.callOurAggregated({
        smenasSeconds: [],
        option: 0,
        templateName: 'skillsMan',
        templateHtml: skillsManTemplateHtml,
        form_desc: false,
        callback: false,
        roundTo: 10,
        areaId: 'section-reports',
      });
    },
    canIndicatorsReport_render() {
      const canIndicatorsTemplate = new CanIndicatorsTemplate(
        OurAggregated,
        skillsManTemplate,
      );
      // canIndicatorsTemplate.callOurAggregated();
      canIndicatorsTemplate.showSettings();
    },
    clutchWorkReport_render() {
      const clutchWorkTemplate = new ClutchWorkTemplate(
        OurAggregated,
        skillsManTemplate,
      );
      // clutchWorkTemplate.callOurAggregated();
      clutchWorkTemplate.showSettings();
    },
    criticalViolationsReport_render() {
      this.showComponents.criticalViolations = false;
      this.showComponents.criticalViolations = true;
      this.$nextTick(() => {
        this.triggers.criticalViolationsTrigger =
          !this.triggers.criticalViolationsTrigger;
      });
      // const criticalViolations = new CriticalViolationsTemplate(
      //   OurAggregated,
      //   skillsManTemplate,
      // );
      // criticalViolations.execute();
    },
    fuelInOut_consumptionReport_render() {
      // запуск отчета реализован в fuel_in_out_template.js
      const fuelInOutConsumptionTemplate = new FuelInOutTemplate(
        'fuelInOut_consumption',
        ourAggregated,
        'section-reports',
      );
      fuelInOutConsumptionTemplate.submit();
    },
    fuelInOut_cisternReport_render() {
      // запуск отчета реализован в fuel_in_out_template.js
      const fuelInOutCisternTemplate = new FuelInOutTemplate(
        'fuelInOut_cistern',
        ourAggregated,
        'section-reports',
      );
      fuelInOutCisternTemplate.submit();
    },
  },
  mounted() {
    const showTemplateButton = document.getElementById('get-template-button');
    showTemplateButton.addEventListener(
      'get-template-button-event',
      this.templateStart,
    );
  },

  //   showTemplateButton.addEventListener(
  //   "regular-moving-template-show",
  //   this.showTemplateButtonEvent
  // );
};
</script>
