<template>
  <header
    id="main-menu"
    class="header-menu"
  >
    <nav
      class="header-menu__mask"
      :class="{ active: showMenu }"
    >
      <div class="header-menu__list">
        <div
          v-for="link in headLinks"
          :key="link.id"
          :id="link.id"
          :data-section="link.section"
          class="header-menu__btn"
          :class="[
            link.classNames,
            {
              active:
                `section-${showWindow}` === link.section &&
                link.id !== 'menu-btn-print',
            },
          ]"
          @click="link.clickHandler"
        >
          {{ link.title }}
        </div>
      </div>
      <div
        id="logout"
        class="header-menu__logout-btn"
        @click="showLogout"
      >
        <img
          src="/images/logout.svg"
          alt=""
        />
        <span v-if="clientWidth <= 1024">Выход</span>
      </div>
    </nav>

    <div
      class="header-menu__burger"
      :class="{ active: showMenu }"
      @click="toggleShowMenu"
    >
      <span></span>
    </div>
  </header>
</template>

<script>
import { leafletMain } from '../../Leaflet/leaflet_main';
export default {
  name: 'AppHeader',

  props: {
    showWindow: {
      type: String,
      required: true,
    },
  },

  emits: ['showWindowHandler'],

  data() {
    return {
      showMenu: false,
      headLinks: [
        {
          title: 'Статистика',
          id: 'menu-btn-violations',
          section: 'section-violations',
          classNames: '',
          clickHandler: () => this.openPageHandler('section-violations'),
        },
        {
          title: 'Мониторинг',
          id: 'menu-btn-monitoring',
          section: 'section-monitoring',
          classNames: 'monitoring-btn unselectable',
          clickHandler: () => this.openPageHandler('section-monitoring'),
        },
        {
          title: 'Объекты',
          id: 'menu-btn-objects',
          section: 'section-monitoring-sidebar',
          classNames: 'monitoring-components-btn unselectable',
          clickHandler: () =>
            this.openPageHandler('section-monitoring-sidebar'),
        },
        {
          title: 'Карта',
          id: 'menu-btn-map',
          section: 'section-monitoring-map',
          classNames: 'monitoring-components-btn unselectable',
          clickHandler: () => this.openPageHandler('section-monitoring-map'),
        },
        {
          title: 'Отчеты',
          id: 'menu-btn-templates',
          section: 'section-reports',
          classNames: 'unselectable',
          clickHandler: () => this.openPageHandler('section-reports'),
        },
        {
          title: 'Детализация',
          id: 'menu-btn-detail',
          section: 'section-reports-detail',
          classNames: 'unselectable',
          clickHandler: () => this.openPageHandler('section-reports-detail'),
        },
        {
          title: 'Графики',
          id: 'menu-btn-graphics',
          section: 'section-graphics',
          classNames: '',
          clickHandler: () => this.openPageHandler('section-graphics'),
        },
        {
          title: 'На печать',
          id: 'menu-btn-print',
          section: 'section-reports',
          classNames: 'd-none',
          clickHandler: () => this.printHandler(),
        },
      ],
      clientWidth: document.documentElement.clientWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth;
    });

    if (process.env.VUE_APP_SERVICE_MODE === 'doosan') {
      this.headLinks = this.headLinks.filter((link) =>
        [
          'menu-btn-monitoring',
          'menu-btn-objects',
          'menu-btn-map',
          'menu-btn-templates',
          'menu-btn-detail',
        ].includes(link.id),
      );
    }
  },

  watch: {
    clientWidth: {
      handler(newClientWidth) {
        this.showInMonitoringHandler(newClientWidth);
      },
    },
    showWindow: {
      handler(openWindow) {
        // refresh map
        if (openWindow == 'monitoring-map' || openWindow == 'monitoring') {
          document.getElementById('section-monitoring-map').style.display =
            'inline-flex';
          leafletMain.map.invalidateSize();
        }

        //кнопка печати только для секции отчеты и детализация и графики
        if (['reports', 'reports-detail', 'graphics'].includes(openWindow)) {
          for (let i = 0; i < this.headLinks.length; i++) {
            if (this.headLinks[i].id === 'menu-btn-print') {
              const linkClassNames = this.headLinks[i].classNames;
              this.headLinks[i].classNames = linkClassNames.replace(
                'd-none',
                '',
              );
              break;
            }
          }
        } else {
          for (let i = 0; i < this.headLinks.length; i++) {
            if (this.headLinks[i].id === 'menu-btn-print') {
              if (this.headLinks[i].classNames.indexOf('d-none') === -1) {
                this.headLinks[i].classNames += 'd-none';
              }
              break;
            }
          }
        }
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    showInMonitoringHandler(clientWidth) {
      const mapNode = document.getElementById('section-monitoring-map');
      if (clientWidth < 768) {
        mapNode.style.display = 'none';
      } else {
        if (
          this.showWindow === 'monitoring' ||
          this.showWindow === 'monitoring-map'
        ) {
          mapNode.style.display = 'inline-flex';
        }
      }
    },
    showLogout() {
      this.toggleShowMenu();
      this.$emit('showWindowHandler', 'logout');
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
    openPageHandler(dataSetSection) {
      this.toggleShowMenu();

      this.$emit('showWindowHandler', dataSetSection.slice(8));
    },
    printHandler() {
      for (let i = 0; i < this.headLinks.length; i++) {
        if (this.headLinks[i].id === 'menu-btn-print') {
          // document.body.style.zoom = (window.innerWidth / window.outerWidth);

          // масштабирование для печати
          let sectionReportsSumm = document.getElementById('section-reports');
          let sectionReportsDetail = document.getElementById(
            'section-reports-detail',
          );

          let a4width_width = 707; // 18.7cm - для А4 с учетом полей

          let visibleTemplate;
          if (!isHidden_helper(sectionReportsSumm)) {
            visibleTemplate = sectionReportsSumm;
          } else {
            visibleTemplate = sectionReportsDetail;
          }

          let widthTemplate = getElemWidth_helper(visibleTemplate);

          if (widthTemplate > a4width_width) {
            visibleTemplate.style.zoom = a4width_width / widthTemplate;
          }

          window.print();

          visibleTemplate.style.zoom = 1;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/config.scss';

#menu-btn-violations {
  display: block;
  @media (max-width: 1024) and (orientation: landscape) {
    display: none;
  }
  @media (max-width: 450px) {
    display: none;
  }
}
.header-menu {
  margin: 0;
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  border-bottom: 1px solid gray;
  background-color: $bg-menu-color;
  color: rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* justify-content: flex-end;
  flex-direction: column; не работает для IE как надо*/
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    position: relative;
    justify-content: flex-end;
    height: 35px;
    padding: 5px 0;
  }
}
.header-menu__mask {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1024px) {
    position: fixed;
    top: -200%;
    left: 0;
    height: calc(100% - 35px);
    background-color: #20252a;
    z-index: 9999;
    justify-content: flex-start;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    overflow: auto;
    padding-bottom: 25px;
    &.active {
      top: 35px;
    }
  }
}
.header-menu__list {
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
.header-menu__btn {
  cursor: pointer;
  display: inline-block;
  width: 150px;
  padding: 5px 10px;
  color: black;
  background-color: $secondary;
  border: 1px solid black;
  text-align: center;
  opacity: 1;
  font-weight: bold;
  transition: opacity 0.3s ease;

  &.active {
    background-color: $active-color;
    opacity: 1;
  }
  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    width: calc(100% - 20px);
    margin: 0 10px;
    margin-top: 10px;
    border-radius: 15px;
    padding: 20px 10px;
  }
}

.monitoring-components-btn {
  display: none;
}

.header-menu__logout-btn {
  cursor: pointer;
  margin: 0 10px;
  white-space: nowrap;

  & img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-bottom: -5px;
  }
  @media (max-width: 1024px) {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    padding: 20px 10px;
    border: 1px solid;
    border-radius: 15px;
    margin-top: 10px;

    & span {
      margin-left: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .header-menu__burger {
    margin-right: 10px;
    display: block;
    position: relative;
    width: 30px;
    height: 20px;

    & span {
      position: absolute;
      background: #fff;
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
      z-index: 10000;
      transition: all 0.3s ease;
    }

    &::before,
    &::after {
      content: '';
      background: #fff;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      z-index: 10000;
      transition: all 0.3s ease;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &.active {
      &::before {
        transform: rotate(45deg);
        top: 9px;
      }

      &::after {
        transform: rotate(-45deg);
        bottom: 9px;
      }

      & span {
        transform: scale(0);
      }
    }
  }
}
@media (max-width: 768px) {
  .monitoring-btn {
    display: none;
  }
  .monitoring-components-btn {
    display: inline-block;
  }
}
</style>
