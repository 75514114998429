<template>
  <div
    :data-excelsheet="vehicle.name"
    style="margin-top: 5px"
  ></div>
  <h6 class="footer-desc text-center font-weight-bold">{{ tableCaption }}</h6>
  <table style="margin-bottom: 18px">
    <!-- <caption class="footer-desc text-center font-weight-bold" style="caption-side:top;padding:2px;">{{ tableCaption }}</caption> -->
    <thead>
      <tr>
        <th
          v-for="colName in $options.columns.tableColumns"
          :key="colName"
          :data-minwidth="$options.columns[colName].excelWidth ?? 7"
          data-excelalignv="middle"
          data-excelalignh="center"
        >
          {{ $options.columns[colName].text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(row, dayIdx) in vehicle.evacuatorMovingDaysRows"
        :key="dayIdx"
      >
        <tr>
          <td
            v-for="colName in $options.columns.tableColumns"
            :key="colName"
            :data-formatnum="$options.columns[colName].excelFormat ?? ''"
            :data-value="
              $options.columns[colName].getExcelValue
                ? $options.columns[colName].getExcelValue({
                    row,
                    departureIdx: 0,
                    comWorkIdx: 0,
                  })
                : ''
            "
          >
            {{
              $options.columns[colName].get({
                row,
                allGeofencesDict,
                departureIdx: 0,
                comWorkIdx: 0,
              })
            }}
            <template
              v-if="
                $options.columns[colName].getAHref &&
                $options.columns[colName].getAHref({
                  row,
                  allGeofencesDict,
                  departureIdx: 0,
                  comWorkIdx: 0,
                })
              "
            >
              <a
                target="_blank"
                :href="
                  $options.columns[colName].getAHref({
                    row,
                    allGeofencesDict,
                    departureIdx: 0,
                    comWorkIdx: 0,
                  })
                "
              >
                {{
                  $options.columns[colName].getAText({
                    row,
                    allGeofencesDict,
                    departureIdx: 0,
                    comWorkIdx: 0,
                  })
                }}
              </a>
            </template>
          </td>
        </tr>
        <evacuator-moving-table-days-detail-rows
          :row="row"
          :columnsSetting="$options.columns"
          :allGeofencesDict="allGeofencesDict"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import EvacuatorMovingTableDaysDetailRows from './EvacuatorMovingTableDaysDetailRows.vue';

export default {
  name: 'EvacuatorMovingTableDays',
  components: {
    EvacuatorMovingTableDaysDetailRows,
  },
  props: {
    vehicle: {
      type: Object,
      require: true,
    },
    allGeofencesDict: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      columns: this.$options.columns,
    };
  },
  computed: {
    tableCaption() {
      const avtoNo = this.vehicle.avtoNo.replaceAll('&nbsp;', ' ');
      return `Посуточный отчет по работе эвакуатора ${this.vehicle.name} г/н ${avtoNo} ${this.vehicle.view.t_interval}`;
    },
  },
  columns: {
    tableColumns: [
      'dayName',
      'dayTime',
      'leaveTimeWithGeo',
      'backTimeWithGeo',
      'outsideTime',
      'outsideDist',
      'comBegin',
      'comLatLon',
      'canTotalDistBegin',
      'canTotalDistEnd',
      'canDistByTotal',
    ],
    // leaveTime / leaveGeo	backTime / backGeo	outsideTime	outsideDist	comBegin	comLatLon
    dayName: {
      text: 'День недели',
      excelWidth: 7,
      get({ row, departureIdx, comWorkIdx }) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        return row.dayName ?? '';
      },
    },
    dayTime: {
      text: 'Дата',
      excelWidth: 10,
      excelFormat: 2,
      getExcelValue({ row, departureIdx, comWorkIdx }) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { dayTime } = row;
        if (dayTime) {
          return (
            dayTime / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
          );
        }
        return '';
      },
      get({ row, departureIdx, comWorkIdx }) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { dayTime } = row;
        if (dayTime) {
          return formatDateHelper(new Date(dayTime), 'dd.mm.yyyy');
        }
        return '';
      },
    },
    leaveTimeWithGeo: {
      text: 'Время выезда из геозоны база',
      excelWidth: 21,
      excelFormat: 0,
      // getExcelValue({row, comWorkIdx, departureIdx, allGeofencesDict} = {}) {
      //   if (comWorkIdx) {
      //     return '';
      //   }
      //   const {leaveTime, leaveGeo} = row.departures[departureIdx] ?? {};
      //   if (leaveTime > 0) {
      //     const leaveTimeText = formatDateHelper(leaveTime, 'hh:nn:ss');
      //     const leaveGeoText = leaveGeo.map(id => allGeofencesDict[id]?.name ?? 'Недоступная геозона').join('; ');
      //     return `${leaveTimeText} (${leaveGeoText})`;
      //   }
      //   return '';
      // },
      get({ row, comWorkIdx, departureIdx, allGeofencesDict } = {}) {
        if (comWorkIdx) {
          return '';
        }
        const { leaveTime, leaveGeo } = row.departures[departureIdx] ?? {};
        if (leaveTime > 0) {
          const leaveTimeText = formatDateHelper(
            new Date(leaveTime),
            'hh:nn:ss',
          );
          const leaveGeoText = leaveGeo
            .map((id) => allGeofencesDict[id]?.name ?? 'Недоступная геозона')
            .join('; ');
          return `${leaveTimeText} (${leaveGeoText})`;
        }
        return leaveTime;
      },
    },
    backTimeWithGeo: {
      text: 'Время возвращения в геозону база',
      excelWidth: 21,
      excelFormat: 0, // 4
      // getExcelValue({row, departureIdx, comWorkIdx}) {
      //   if (comWorkIdx) {
      //     return '';
      //   }
      //   const {backTime} = row.departures[departureIdx];
      //   if (backTime> 0) {
      //     return backTime / 86400;
      //   }
      //   return '';
      // },
      get({ row, allGeofencesDict, departureIdx, comWorkIdx }) {
        if (comWorkIdx) {
          return '';
        }
        const { backTime, backGeo } = row.departures[departureIdx] ?? {};
        if (backTime > 0) {
          const backTimeText = formatDateHelper(new Date(backTime), 'hh:nn:ss');
          const backGeoText = backGeo
            .map((id) => allGeofencesDict[id]?.name ?? 'Недоступная геозона')
            .join('; ');
          return `${backTimeText} (${backGeoText})`;
        }
        return backTime;
      },
    },
    outsideTime: {
      text: 'Общее время нахождения вне базы',
      excelWidth: 13,
      excelFormat: 4,
      getExcelValue({ row, departureIdx, comWorkIdx }) {
        if (comWorkIdx) {
          return '';
        }
        const { cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        const { outsideTime } = row.departures[departureIdx] ?? {};
        if (outsideTime > 0) {
          return outsideTime / 86400000;
        }
        return '';
      },
      get({ row, departureIdx, comWorkIdx }) {
        if (comWorkIdx) {
          return '';
        }
        const { cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        const { outsideTime } = row.departures[departureIdx] ?? {};
        if (outsideTime > 0) {
          return formatTimeHelper(outsideTime / 86400000, 'tt:nn:ss');
        }
        return outsideTime;
      },
    },
    outsideDist: {
      text: 'Пробег вне геозоны база',
      excelWidth: 12,
      excelFormat: 5,
      getExcelValue({ row, departureIdx, comWorkIdx } = {}) {
        if (comWorkIdx) {
          return '';
        }
        const { departures, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (departures[departureIdx]) {
          return departures[departureIdx].outsideDist;
        }
        return 0;
      },
      get({ row, departureIdx, comWorkIdx } = {}) {
        if (comWorkIdx) {
          return '';
        }
        const { departures, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (departures[departureIdx]) {
          return roundNumber_helper(departures[departureIdx].outsideDist, 1);
        }
        return 0;
      },
    },
    canTotalDistBegin: {
      text: 'Общий пробег по КАН на начало суток',
      excelWidth: 12,
      excelFormat: 5,
      getExcelValue({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canTotalDistBegin, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        // if (departures[departureIdx]) {
        //   return departures[departureIdx].canTotalDistBegin;
        // }
        return canTotalDistBegin;
      },
      get({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canTotalDistBegin, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (typeof canTotalDistBegin === 'number') {
          return formatToDisplay_helper(canTotalDistBegin, this.excelFormat);
        }
        return ' -';
      },
    },
    canTotalDistEnd: {
      text: 'Общий пробег по КАН на конец суток',
      excelWidth: 12,
      excelFormat: 5,
      getExcelValue({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canTotalDistEnd, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        // if (departures[departureIdx]) {
        //   return departures[departureIdx].canTotalDistEnd;
        // }
        return canTotalDistEnd;
      },
      get({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canTotalDistEnd, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (typeof canTotalDistEnd === 'number') {
          return formatToDisplay_helper(canTotalDistEnd, this.excelFormat);
        }
        return ' -';
      },
    },
    canDistByTotal: {
      text: 'Пробег по КАН за сутки',
      excelWidth: 12,
      excelFormat: 5,
      getExcelValue({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canDistByTotal, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (typeof canDistByTotal === 'number') {
          return canDistByTotal;
        }
        return ' -';
      },
      get({ row, departureIdx, comWorkIdx } = {}) {
        if (departureIdx || comWorkIdx) {
          return '';
        }
        const { canDistByTotal, cntRealPos } = row;
        if (!(cntRealPos > 0)) {
          return 'н/д';
        }
        if (typeof canDistByTotal === 'number') {
          return formatToDisplay_helper(canDistByTotal, this.excelFormat);
        }
        return ' -';
      },
    },
    comBegin: {
      text: 'Время начала работы COM',
      excelWidth: 12,
      excelFormat: 3,
      getExcelValue({ row, departureIdx, comWorkIdx } = {}) {
        const { comWorks = [] } = row.departures[departureIdx] ?? {};
        const { begin = 0 } = comWorks[comWorkIdx] ?? {};

        return begin > 0
          ? begin / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
          : '';
      },
      get({ row, departureIdx, comWorkIdx }) {
        const { comWorks = [] } = row.departures[departureIdx] ?? {};
        const { begin = 0 } = comWorks[comWorkIdx] ?? {};

        return begin > 0
          ? formatDateHelper(new Date(begin), 'hh:nn:ss')
          : 'нет';
      },
    },
    comLatLon: {
      text: 'Место работы КОМ вне зоны база',
      excelWidth: 19,
      excelFormat: 0,
      getAHref({ row, departureIdx, comWorkIdx } = {}) {
        const { comWorks = [] } = row.departures[departureIdx] ?? {};
        const { lat, lon } = comWorks[comWorkIdx] ?? {};
        if (!lat || !lon) {
          return '';
        }
        const latRounded = myRoundNumber_helper(lat, 5);
        const lonRounded = myRoundNumber_helper(lon, 5);
        // return `http://maps.google.com/maps?q=${latRounded},${lonRounded}`;
        return `${
          process.env.VUE_APP_BACKEND_URL || window.location.origin
        }/track-motion?type=position&lat=${latRounded}&lon=${lonRounded}`;
      },
      getAText({ row, departureIdx, comWorkIdx } = {}) {
        const { comWorks = [] } = row.departures[departureIdx] ?? {};
        const { lat, lon } = comWorks[comWorkIdx] ?? {};
        if (!lat || !lon) {
          return '';
        }
        const latRounded = myRoundNumber_helper(lat, 5);
        const lonRounded = myRoundNumber_helper(lon, 5);
        return `${latRounded}, ${lonRounded}`;
      },
      get({ row, departureIdx, comWorkIdx } = {}) {
        const { comWorks = [] } = row.departures[departureIdx] ?? {};
        const { lat, lon } = comWorks[comWorkIdx] ?? {};
        if (lat && lon) {
          // return `${lat}, ${lon}`;
          return '';
        }
        return comWorks[comWorkIdx] ? 'не определено' : 'нет';
      },
    },
  },
};
</script>
