<template>
  <teleport to="#section-reports">
    <div id="my-info">
      <button
        class="not-print float-left"
        @click="
          (e) =>
            html_to_excel_helper.export(
              e.target,
              'excavator-operation-template-content',
              REPORT_NAME,
              'landscape',
            )
        "
      >
        Экспорт&nbsp;в&nbsp;EXCEL
      </button>
    </div>
    <div
      class="temlate-report"
      id="excavator-operation-template-content"
    >
      <template
        v-for="(obj, index) of props.data.objects"
        :key="obj.id"
      >
        <div :data-excelsheet="obj.name"></div>

        <div class="text-center">
          <h4
            v-show="index === 0"
            class="footer-desc"
            style="width: calc(100% - 130px)"
            data-excelcolspan="3"
          >
            {{ REPORT_NAME }} {{ obj.name }}
          </h4>
          <p
            v-show="index === 0"
            class=""
          >
            за период {{ interval }}
          </p>
        </div>

        <div
          class="not-excel ml-1 mt-2 font-weight-bold"
          :class="{ 'mt-5': index > 0 }"
          v-html="obj.name"
        ></div>

        <total-table
          :object="obj"
          @display-track="() => displayTrack(obj)"
        />

        <p class="d-none"></p>
        <p
          class="ml-1 mt-2 font-weight-bold"
          v-html="`${obj.name} график работы по дням`"
        ></p>
        <table class="excel-not-border">
          <tbody>
            <tr>
              <td data-image="svg">
                <d3-chart
                  :object="obj"
                  :interval="props.data.responseInterval"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <template v-if="props.data.settings.isDailyDetail">
          <p class="d-none"></p>
          <p
            class="ml-1 mt-2 font-weight-bold"
            v-html="`${obj.name} метрики работы машины`"
          ></p>

          <daily-table
            :object="obj"
            :interval="props.data.responseInterval"
          />
        </template>

        <template v-if="props.data.settings.isDetail">
          <div :data-excelsheet="`${obj.name} (детализация)`"></div>

          <h4
            class="d-none text-center footer-desc no-margin"
            data-excelcolspan="3"
          >
            {{ REPORT_NAME }} {{ obj.name }}
          </h4>
          <p class="d-none text-center no-margin">за период {{ interval }}</p>

          <div
            class="not-excel ml-1 mt-2 font-weight-bold"
            v-html="`${obj.name} детализация работы по дням`"
          ></div>

          <detail-table
            :object="obj"
            @display-track="(interval) => displayTrack(obj, interval)"
          />
        </template>
      </template>
    </div>

    <map-popup
      v-model="trackOptions.isShow"
      :oid="trackOptions.oid"
      :name="trackOptions.name"
      :gCategory="trackOptions.gCategory"
      :from="trackOptions.from"
      :to="trackOptions.to"
    />
  </teleport>
</template>

<script setup>
import { computed, ref, provide, onMounted } from 'vue';

import MapPopup from './popup/MapPopup.vue';
import TotalTable from './table/TotalTable.vue';
import DetailTable from './table/DetailTable.vue';
import DailyTable from './table/DailyTable.vue';
import D3Chart from './chart/D3Chart.vue';

import { formatDateHelper } from '@/helpers/main_helper_010';

const html_to_excel_helper = window.html_to_excel_helper;

const props = defineProps({ data: Object });

const REPORT_NAME = 'Эксплуатация экскаватора';

const SECONDS_IN_THE_HOUR = 3600;

const toHours = (v) => v / SECONDS_IN_THE_HOUR;
const getFullDate = (val) => {
  return formatDateHelper(new Date(+val * 1000), 'dd.mm.yyyy hh:nn:ss');
};
const getOnlyDate = (val) => {
  return formatDateHelper(new Date(+val * 1000), 'dd.mm.yyyy');
};

provide('toHours', toHours);
provide('getFullDate', getFullDate);
provide('getOnlyDate', getOnlyDate);

const trackOptions = ref({
  isShow: false,
  oid: '',
  name: '',
  gCategory: 0,
  from: 0,
  to: 0,
});

const interval = computed(() => {
  const from = getFullDate(props.data.responseInterval.from / 1000);
  const to = getFullDate(props.data.responseInterval.to / 1000);

  return `с ${from} по ${to}`;
});

onMounted(() => {
  const button = document.getElementById('get-template-button');
  const targetSpan = getNeighborByClass_helper(button, 'target-show-span');

  button.style.background = 'blue';
  targetSpan.innerHTML = 'Отчет выполнен';
});

function displayTrack(obj, interval) {
  trackOptions.value.isShow = true;

  trackOptions.value.oid = obj.id;
  trackOptions.value.name = obj.name;
  trackOptions.value.gCategory = obj.gCategory;

  if (interval) {
    trackOptions.value.from = interval.from;
    trackOptions.value.to = interval.to;
  } else {
    trackOptions.value.from = props.data.responseInterval.from;
    trackOptions.value.to = props.data.responseInterval.to;
  }
}
</script>

<style lang="scss">
.my-popup {
  max-width: auto;

  &__body {
    display: flex;
    flex-direction: column;

    max-width: 100%;
    max-height: 100%;
  }
}
</style>
