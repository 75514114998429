import { htmlspecialcharsHelper } from '@/helpers/main_helper_010';

export class ClientsMonthlyTemplateSettings {
  settings;
  subelements;
  settingsBackgroundWrap;

  setClients({ clients } = {}) {
    this.clients = clients;
  }

  showSettings() {
    if (!this.settings) {
      this.settingsBuild();
    }

    this.addClients();

    this.settings.classList.remove('d-none');
    this.settingsBackgroundWrap.classList.remove('d-none');
    // document.body.append(this.settings);
    // document.body.append(this.settingsBackgroundWrap);
  }

  addClients() {
    const clientsAreaNode = document.getElementById('clients-area');

    clientsAreaNode.innerHTML = this.getSettingClientsTemplate();
  }

  closeSettingsEvent(event) {
    this.that.closeSettings();
  }

  closeSettings() {
    this.settings.classList.add('d-none');
    this.settingsBackgroundWrap.classList.add('d-none');
  }

  checkAllClientsEvent(event) {
    event.preventDefault();
    this.that.setCheckedSettingClients(true);
  }

  checkRemoveAllClientsEvent(event) {
    event.preventDefault();
    this.that.setCheckedSettingClients(false);
  }

  setCheckedSettingClients(checked) {
    const { settingClients } = this.subelements;
    const inputs = settingClients.querySelectorAll(`input[type="checkbox"]`);
    inputs.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  settingsBuild() {
    const settings = this.createElement(this.getSettingsTemplate());
    const settingsBackgroundWrap = this.createElement(
      this.getBackgroundWrapTemplate(),
    );

    const subelements = this.getSubElements(settings);
    const { settingsClose, submit, checkAllClients, checkRemoveAllClients } =
      subelements;

    settingsClose.addEventListener('pointerdown', {
      handleEvent: this.closeSettingsEvent,
      that: this,
    });
    settingsBackgroundWrap.addEventListener('pointerdown', {
      handleEvent: this.closeSettingsEvent,
      that: this,
    });
    // submit.addEventListener('pointerdown', {handleEvent: this.submitEvent, that: this});
    checkAllClients.addEventListener('click', {
      handleEvent: this.checkAllClientsEvent,
      that: this,
    });
    checkRemoveAllClients.addEventListener('click', {
      handleEvent: this.checkRemoveAllClientsEvent,
      that: this,
    });

    this.settings = settings;
    this.subelements = subelements;
    this.settingsBackgroundWrap = settingsBackgroundWrap;

    document.body.append(settings);
    document.body.append(settingsBackgroundWrap);
  }

  getSettingsTemplate() {
    return `
        <div class="clients-monthly-template-settings d-none template-setting-window">
            <div class="template-setting-window-header">
                <div class="template-setting-window-header-name">
                    <strong>Статистика по организациям, сравнение по месяцам</strong><br>
                    <small>Настройка отчета</small>
                </div>
                <!-- Картинка крестика-->
                <img data-element="settingsClose" class="close" src="/images/close.png">
            </div>

            <div>
                <label><input type="checkbox" data-element="settingFullMonthOnly" checked>Игнорировать неполные месяцы</label><br>
                Организации: <a href="#" data-element="checkAllClients">Выбрать все</a>
                <a href="#" data-element="checkRemoveAllClients">Очистить все</a>
            </div>

            <div class="overflow-auto">
                <div id="clients-area" data-element="settingClients">
                  
                </div>
            </div>

            <div class="text-center">
                <span class="target-show">
                    <input class="btn btn-primary config-btn-primary btn-sm" type="submit" data-element="submit" value="Выполнить отчет">
                    <span data-element="submitInfo"></span>
                    <span class="target-show-span">Обсчет данных для получения отчета</span>
                </span>
            </div>
        </div>
        `;
  }

  getBackgroundWrapTemplate() {
    return `<div class="background-wrap d-none" style="display: flex;"></div>`;
  }

  createElement(template) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = template;
    return wrapper.firstElementChild;
  }

  getSettingClientsTemplate() {
    if (this.clients.length) {
      const clients = this.clients.map((client) =>
        this.getSettingClientTemplate(client),
      );
      return clients.join('<br>');
    }

    return 'Список организаций пуст';
  }

  getSettingClientTemplate(client) {
    const { name, count } = client;
    const nameToDisplay = htmlspecialcharsHelper(name);
    return `<label><input type="checkbox" data-client="${nameToDisplay}">${nameToDisplay} (${count})</label>`;
  }

  getSubElements(element) {
    const elements = element.querySelectorAll('[data-element]');

    return [...elements].reduce((accum, subElement) => {
      accum[subElement.dataset.element] = subElement;

      return accum;
    }, {});
  }
}
