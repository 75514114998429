import fetchJson from '@/src/dataRequest/fetch-json';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService.js';

export class FuelInOutTemplate {
  api = `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/fuelInOut/get`;
  isError = false;

  constructor(templateName, ourAggregated, areaId) {
    this.templateName = templateName;
    this.ourAggregated = ourAggregated;
    this.areaId = areaId;
    this.host = `http://${document.location.host}`;

    this.isCistern = templateName.includes('cistern');
  }

  geoDelimeter = 1000000000000000;
  databaseColumnsType = {
    line_id: 'int',
    obj_id: 'int',
    lv_code: 'smallint',
    lv_num: 'smallint',
    lv_importance: 'smallint',
    time: 'bigint',
    begin: 'bigint',
    end: 'bigint',
    out: 'bool',
    vol: 'int',
    level_begin: 'int',
    speed: 'smallint',
    gps: 'smallint',
    move: 'bool',
    lat: 'bigint',
    lon: 'bigint',
  };

  databaseColumnsMultipler = {
    time: 1000,
    begin: 1000,
    end: 1000,
  };

  databaseColumnsDelimeter = {
    vol: 1000,
    level_begin: 1000,
    speed: 10,
    lat: this.geoDelimeter,
    lon: this.geoDelimeter,
  };

  submitEvent() {
    this.that.submit();
  }

  async submit() {
    const { areaId, host } = this;

    const {
      objIdArr = [],
      time_begin = 0,
      time_end = 0,
      targetSpan,
      button,
    } = this.getFormValues();

    if (!objIdArr.length) {
      infoShowText_helper(targetSpan, 'Не выбран ни один объект.', areaId);
      return;
    }

    if (time_begin >= time_end) {
      infoShowText_helper(targetSpan, 'Неверно выбрано время запроса.', areaId);
      return;
    }

    infoShowText_helper(
      targetSpan,
      'Отправляю запрос для отчета по сливам и заправкам.',
      areaId,
    );

    const objectsDescription = objIdArr.reduce((acc, objId) => {
      const objRow = document.getElementById(objId);

      acc[objId] = {};

      acc[objId].name = objRow.querySelector('.search-name').textContent;
      acc[objId].stateNumber = objRow.querySelector(
        '.search-stateNumber',
      ).textContent;

      return acc;
    }, {});

    fetchToTemplateService({
      templateName: this.templateName,
      form_desc: {
        objects: objIdArr,
        beginTime: time_begin * 1000,
        endTime: time_end * 1000,
      },
      options: {
        summ_levels_only: 1,
        objectsDescription,
        isCistern: this.isCistern,
      },
      areaId,
      successCallback: this.drawReport({
        areaId,
        time_begin,
        time_end,
        targetSpan,
      }),
      errorCallback: () =>
        this.oldFetchAndDrawReport({
          host,
          objIdArr,
          areaId,
          targetSpan,
          time_begin,
          time_end,
        }),
    });
  }

  oldFetchAndDrawReport({
    host,
    objIdArr,
    areaId,
    targetSpan,
    time_begin,
    time_end,
  }) {
    const promises = this.getDataPromises({
      host,
      objIdArr,
      areaId,
      targetSpan,
      time_begin,
      time_end,
    });

    const worker = new CalculateRequestDataworker();

    Promise.all(promises)
      .then((responses) => {
        infoShowText_helper(
          targetSpan,
          'Данные получены, обрабатываю результат',
          areaId,
        );

        responses.map((response) => {
          const objRow = document.getElementById(response.objId);
          response.name = objRow.querySelector('.search-name').textContent;
          response.stateNumber = objRow.querySelector(
            '.search-stateNumber',
          ).textContent;
        });

        worker.postMessage({
          templateName: this.templateName,
          data: { responses },
        });
      })
      .catch((err) => {
        console.error(err);
        infoShowText_helper(
          targetSpan,
          'Произошла ошибка, попробуйте еще раз или обновите страницу.',
          areaId,
        );
      });

    worker.addEventListener('message', (e) => {
      const data = e.data;

      if (data.message) {
        infoShowText_helper(targetSpan, data.message);
        return;
      }

      const drawReportAction = this.drawReport({
        areaId,
        time_begin,
        time_end,
        targetSpan,
      });

      drawReportAction(data);

      worker.terminate();
    });

    worker.addEventListener('error', ({ message }) => {
      console.error(message);
      worker.terminate();
    });
  }

  drawReport({ areaId, time_begin, time_end, targetSpan }) {
    return (data) => {
      const groupRows = data;

      const area = document.getElementById(areaId);

      area.innerHTML = this.getTemplate({
        groupRows,
        columns: this.tableColumns,
        timeBegin: time_begin,
        timeEnd: time_end,
      });

      infoShowText_helper(
        targetSpan,
        'Отчет построен. Выполнить новый запрос?',
      );
    };
  }

  getDataPromises({
    host,
    objIdArr,
    targetSpan,
    areaId,
    time_begin,
    time_end,
  } = {}) {
    const queryCount = objIdArr.length;
    let receivedCount = 0;

    this.isError = false;

    return objIdArr.map((objId) => {
      const trObject = document.getElementById(objId);
      const lastTimeAggregationElement = trObject.querySelector(
        '[data-last-time-aggregation]',
      );
      const lastTimeAggregation = parseInt(
        lastTimeAggregationElement.dataset.lastTimeAggregation,
      );

      if (lastTimeAggregation < time_begin) {
        return new Promise((resolve) => {
          receivedCount = this.showReceivedPercent({
            targetSpan,
            receivedCount,
            queryCount,
            areaId,
          });
          resolve({
            objId,
            begin: time_begin,
            end: time_end,
            error: 0,
            lines: [],
          });
        });
      }

      const url = new URL(this.api, host);
      url.searchParams.set('id', objId);
      url.searchParams.set('time_begin', time_begin);
      url.searchParams.set('time_end', time_end);

      url.searchParams.set('summ_levels_only', 1);
      if (this.isCistern) {
        url.searchParams.set('consumption', 0);
      } else {
        url.searchParams.set('cistern', 1);
      }

      return (
        fetchJson(url, {})
          // .then((res) => res.json())
          .then((res) => {
            // receivedCount++;
            // const percent = roundNumber_helper(100 * receivedCount / queryCount, 1);
            // infoShowText_helper(targetSpan, `Получен ответ ${receivedCount} из ${queryCount} (${percent}%)`, areaId);
            receivedCount = this.showReceivedPercent({
              targetSpan,
              receivedCount,
              queryCount,
              areaId,
            });

            const { begin, end, error } = res;
            const lines = error
              ? []
              : res.lines.map((line) => this.getLineFromDbType(line));

            return {
              objId,
              begin,
              end,
              error,
              lines,
            };
          })
          .catch(() => {
            this.isError = true;
          })
      );
    });
  }

  showReceivedPercent({ targetSpan, receivedCount, queryCount, areaId } = {}) {
    receivedCount++;
    if (this.isError) {
      return receivedCount;
    }
    const percent = roundNumber_helper((100 * receivedCount) / queryCount, 1);
    infoShowText_helper(
      targetSpan,
      `Получен ответ ${receivedCount} из ${queryCount} (${percent}%)`,
      areaId,
    );
    return receivedCount;
  }

  getFormValues() {
    const formDesc = this.ourAggregated.getFormDesc(5);
    const visibleElements = objectsListFormColorAndTarget_helper(formDesc);

    if (!visibleElements) {
      return {};
    }

    const { targetSpan, button } = visibleElements;
    const { objects, beginTime, endTime } = formDesc;

    return {
      objIdArr: objects,
      //   time_begin: getTimeFromString_helper(beginTime).datetime / 1000,
      //   time_end: getTimeFromString_helper(endTime).datetime / 1000,
      time_begin: beginTime / 1000,
      time_end: endTime / 1000,
      targetSpan,
      button,
    };
  }

  getLineFromDbType(line) {
    const res = {};
    const {
      databaseColumnsType,
      databaseColumnsMultipler,
      databaseColumnsDelimeter,
    } = this;

    for (const [key, val] of Object.entries(line)) {
      const type = databaseColumnsType[key] ?? 'text';
      let value = this.getValueByType(val, type);

      const multiplier = databaseColumnsMultipler[key] || 0;
      if (multiplier) {
        value *= multiplier;
      }

      const delimeter = databaseColumnsDelimeter[key] || 0;
      if (delimeter) {
        value /= delimeter;
      }

      res[key] = value;
    }

    return res;
  }

  getValueByType(val, type) {
    switch (type) {
      case 'int':
      case 'smallint':
      case 'bigint':
        return parseInt(val);
      case 'bool':
      case 'boolean':
        if (val === 'f' || val === 'false' || val === '0' || !val) {
          return false;
        }

        return true;
      case 'text':
      default:
        return val;
    }
  }

  getTemplate({ groupRows, columns, timeBegin, timeEnd } = {}) {
    const simpleTableHelper = new SimpleTableHelper();
    const templateName = this.templateName;
    const headerName = this.isCistern ? 'в цистернах' : 'в баках';

    return `
        <button class="not-print float-left" onclick="html_to_excel_helper.export( this, 'fuel_in_out_template', 'Отчет по заправкам и сливам ${headerName}', 'portrait' )">
            Экспорт&nbsp;в&nbsp;EXCEL 
        </button>
        <div class="temlate-report" id="fuel_in_out_template">
            <div data-excelsheet="Отчет по заправкам и сливам ${headerName}"></div>
            <h6 class="text-center font-weight-bold">Отчет по заправкам и сливам ${headerName} за период c ${formatDateHelper(
      new Date(timeBegin * 1000),
      'dd.mm.yyyy hh:nn:ss',
    )} по ${formatDateHelper(
      new Date(timeEnd * 1000),
      'dd.mm.yyyy hh:nn:ss',
    )}</h6>
            <table class="table-simple">
                <thead>
                    ${simpleTableHelper.getTheadTemplate({ columns })}
                </thead>
                <tbody>
                    ${simpleTableHelper.getTbodyTemplate({ groupRows })}
                </tbody>
            </table>
        </div>
        `;
  }

  tableColumns = {
    name: {
      text: 'Наименование',
      dataminwidth: 15,
    },
    stateNumber: {
      text: 'Гос. номер',
      dataminwidth: 15,
    },
    time: {
      text: 'Дата/время',
      formatNum: 1.1, // 'dd.mm.yyyy hh:nn:ss'
      isDataValue: true,
      dataminwidth: 17,
    },
    vol: {
      text: 'Объем слива / заправки',
      formatNum: 5,
      dataminwidth: 10,
      isDataValue: true,
    },
    vol_out: {
      text: 'Объем слива',
      formatNum: 5,
      dataminwidth: 7,
      isDataValue: true,
    },
    vol_in: {
      text: 'Объем заправки',
      formatNum: 5,
      dataminwidth: 8.3,
      isDataValue: true,
    },
    level_begin: {
      text: 'Начальный уровень',
      formatNum: 5,
      dataminwidth: 10,
      isDataValue: true,
    },
    level_end: {
      text: 'Конечный уровень',
      formatNum: 5,
      dataminwidth: 9.1,
      isDataValue: true,
    },
    lv_num: {
      text: 'Номер уровнемера',
      formatNum: 0.5,
      dataminwidth: 11,
    },
    lv_target: {
      text: 'Назначение уровнемера',
      dataminwidth: 14,
    },
    is_move: {
      text: 'Движение',
      dataminwidth: 9.4,
    },
    location: {
      text: 'Местоположение',
      dataminwidth: 15.6,
    },

    columnsForDisplay: [
      'stateNumber',
      'time',
      'level_begin',
      'vol_out',
      'vol_in',
      'level_end',
      'is_move',
      'lv_num',
      'lv_target',
      'location',
    ],
  };
}
