<template>
  <aside>
    <div style="display: flex; justify-content: space-between">
      <ui-switcher
        :showSidebarSection="showMonitoring"
        @show-sidebar-section="(str) => (showMonitoring = str)"
      />

      <div class="show-sidebar-or-map-block">
        <button
          class="show-sidebar-or-map-image show-sidebar"
          @click="emit('show', 'sidebar')"
          title="Скрыть список объектов"
        >
          <img
            src="/images/violations-graphics/table.svg"
            alt="sidebar"
          />
        </button>

        <button
          class="show-sidebar-or-map-image show-map"
          @click="emit('show', 'map')"
          title="Скрыть карту"
        >
          <img
            src="/images/violations-graphics/map.png"
            alt="map"
          />
        </button>
      </div>

      <ui-hearbeat
        :connect="heartbeatConnect.connect"
        :text="heartbeatConnect.text"
        style="margin-right: 4px"
        @mouseenter="addHoverOnTarget($event)"
        @mouseleave="removeHoverOnTarget($event)"
      />
    </div>

    <div v-show="showMonitoring === 'geofences'">
      <vue-geofences :is-show="showMonitoring === 'geofences'"></vue-geofences>
    </div>

    <form
      v-show="showMonitoring === 'objects'"
      id="objectsListForm"
      @submit.prevent
    >
      <div class="monitoring__container text-center">
        <div
          class="target-show"
          @mouseenter="addHoverOnTarget($event)"
          @mouseleave="removeHoverOnTarget($event)"
        >
          <ui-datepicker
            v-model:date="datepickerDate"
            class="sidebar-datepicker"
          />
          <span class="target-show-span"
            >Дата и время начала отчета/запроса в формате ДД.ММ.ГГГГ ЧЧ:ММ
          </span>
        </div>
        <input
          type="text"
          name="beginTime"
          id="beginTime"
          class="d-none"
          :value="getUnixTimeByDate(datepickerDate[0])"
        />
        <input
          type="text"
          name="endTime"
          id="endTime"
          class="d-none"
          :value="getUnixTimeByDate(datepickerDate[1])"
        />

        <div
          class="monitoring__form"
          @mouseenter="addHoverOnTarget($event)"
          @mouseleave="removeHoverOnTarget($event)"
        >
          <ui-select
            name="templateName"
            id="template-select"
            class="custom-select monitoring__template-select"
            style="color: #000"
            v-model:select="templateSelected"
            :items="TEMPLATE_NAMES"
          />

          <span
            hidden
            class="target-show"
          >
            <span
              class="btn btn-secondary btn-sm"
              name="templateSettingsButton"
              onclick="showHiddenWindow('block', 'template-setting-window')"
            >
              Настройки
            </span>
            <span class="target-show-span">Установить настройки отчета</span>
          </span>

          <input
            type="number"
            name="timeOffset"
            value="5"
            id="timeOffsetInput"
            hidden
          />

          <span
            class="target-show monitoring__button-send"
            style="margin-top: -3px"
            @mouseenter="addHoverOnTarget($event)"
            @mouseleave="removeHoverOnTarget($event)"
          >
            <input
              class="btn btn-primary config-btn-primary btn-sm get-template-button-id"
              style="height: 31px; margin-bottom: 1px"
              type="submit"
              name="send"
              id="get-template-button"
              value="Выполнить отчет"
              @click="executeReport"
            />
            <span class="target-show-span get-template-button-target-id">
              Обсчет данных для получения отчета
            </span>
          </span>
          <br />
        </div>
        <div
          class="flex monitoring-sidebar__form"
          style="width: 100%"
        >
          <div class="flex">
            <ui-image-button
              style="margin-left: 5px"
              path="/images/checked-checkbox-26.png"
              alt="check"
              targetText="Дополнительно выделить отфильтрованные объекты"
              @click="checkAllObjects"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />

            <ui-image-button
              path="/images/cleared-checkbox-26.png"
              alt="clear"
              targetText="Очистить выделение всех объектов (включая скрытые)"
              @click="clearAllObjects"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />
          </div>
          <div class="flex monitoring-sidebar__form-filter">
            <ui-filter
              v-model:text="searchStr"
              input-class="monitoring-sidebar__filter"
              type="text"
              placeholder="Фильтр объектов"
              targetText="Фильтр списка объектов. Можно перечислять через запятую"
              :timer-handler="showObjectsListItemsHandler"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />

            <ui-count
              :countFiltered="countFiltered"
              :countAll="Object.keys(showObjectsKeys).length"
              targetText="Показано объектов / всего доступно"
              @mouseenter="addHoverOnTarget($event)"
              @mouseleave="removeHoverOnTarget($event)"
            />
          </div>
          <div class="flex monitoring-sidebar__filter-footer">
            <div class="d-inline">
              <ui-select
                class="monitoring__filter-select"
                v-model:select="filterSelected"
                placeholder="Не выбрано"
                multiple
                :items="FILTER_NAMES"
              />
            </div>
            <div
              class="setting-btn cursor-pointer"
              @click="showTableSettingsHandler"
            >
              <ui-settings-button />
            </div>
          </div>
        </div>
      </div>

      <objects-list
        :interval="datepickerDate"
        :show-table-columns="columnsToObject(selectedTableColumnsArr)"
        v-model:show-objects-keys="showObjectsKeys"
        v-model:checked-objects-keys="checkedObjectsKeys"
        @heartbeatShow="heartBeatConnectHandler"
      />
    </form>

    <objects-list-table-settings-modal
      :show="isShowTableSettings"
      :client-width="clientWidth"
      :client-height="clientHeight"
      :is-admin="isAdmin"
      @close="isShowTableSettings = false"
      @save-settings="saveTableSettingsHandler"
    />
    <the-toaster ref="toaster" />
  </aside>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue';

import VueGeofences from '../../GeofencesComponents/VueGeofences.vue';
import UiSwitcher from './components/UiSwitcher.vue';
import UiDatepicker from '@/App/Components/UiDoubleDatepicker.vue';
import UiHearbeat from './components/UiHearbeat.vue';
import UiSelect from './components/UiSelect.vue';
import UiImageButton from './components/UiImageButton.vue';
import UiSettingsButton from '@/App/Components/Buttons/UiSettingsButton.vue';
import UiFilter from './components/UiFilter.vue';
import UiCount from './components/UiCount.vue';
import UiTarget from './components/UiTarget.vue';
import ObjectsList from './components/ObjectsList.vue';
import ObjectsListTableSettingsModal from './components/ObjectsListTableSettingsModal.vue';
import TheToaster from '../../Components/toaster/TheToaster.vue';

import {
  leafletMain,
  objectsListFormSubmit as mainScriptObjectsListFormSubmit,
} from '../../../src/dataRequest/mainScript';
import { isTimeEqualZero } from '@/helpers/MineHelper';

import {
  addHoverOnTarget,
  removeHoverOnTarget,
} from '../../helpers/targetsHelper';
import { MonitoringSettings } from '../default/MonitoringSettings';

const emit = defineEmits(['show']);

const TEMPLATE_NAMES = ref([
  {
    value: 'skillsMan',
    text: 'Навыки МАН суммарный',
  },
  {
    value: 'regularMovingByGeo',
    text: 'Рейсы по геозонам',
  },
  {
    value: 'overspeed',
    text: 'Превышение скорости',
  },
  {
    value: 'evacuatorMoving',
    text: 'Работа эвакуатора',
  },
  {
    value: 'criticalViolations',
    text: 'Критические нарушения',
  },
  {
    value: 'skillsManDetail',
    text: 'Навыки МАН детализация',
  },
  {
    value: 'canIndicators',
    text: 'CAN показатели',
  },
  {
    value: 'clutchWork',
    text: 'Работа сцепления',
  },
  {
    value: 'shiftWork',
    text: 'Работа ТС посуточно',
  },
  {
    value: 'clientsMonthly',
    text: 'Статистика по организациям',
  },
  {
    value: 'fuelInOut_consumption',
    text: 'Заправки и сливы (баки)',
  },
  {
    value: 'fuelInOut_cistern',
    text: 'Заправки и сливы (цистерны)',
  },
  {
    value: 'diagnostic',
    text: 'Диагностика',
  },
  {
    value: 'diagnosticDetail',
    text: 'Диагностика (подробный)',
  },
]);

const FILTER_NAMES = ref([
  {
    value: 'name',
    text: 'Наименование',
  },
  {
    value: 'stateNumber',
    text: 'Гос №',
  },
  {
    value: 'last_time_aggregation',
    text: 'Готовность отчета',
  },
  {
    value: 'install_time',
    text: 'Дата установки',
  },
  {
    value: 'lat',
    text: 'Широта',
  },
  {
    value: 'lon',
    text: 'Долгота',
  },
  {
    value: 'model',
    text: 'Модель',
  },
  {
    value: 'category',
    text: 'Категория',
    items: [
      {
        value: 'category-0',
        text: 'Все объекты',
        icon: '/images/categories/all-objects.png',
        items: [
          {
            value: 'category-101',
            text: 'Отключен',
            icon: '/images/categories/disabled.png',
          },
        ],
      },
      {
        value: 'category-1',
        text: 'Статичный объект',
        icon: '/images/categories/home.png',
        items: [
          {
            value: 'category-11',
            text: 'ДЭС',
            icon: '/images/categories/des.png',
          },
          {
            value: 'category-12',
            text: 'Кран',
            icon: '/images/categories/crane.png',
          },
          {
            value: 'category-13',
            text: 'АЗС',
            icon: '/images/categories/gas.png',
          },
        ],
      },
      {
        value: 'category-2',
        text: 'Легковой транспорт',
        icon: '/images/categories/passenger_car.png',
        items: [
          {
            value: 'category-21',
            text: 'Пикап',
            icon: '/images/categories/pickup.png',
          },
        ],
      },
      {
        value: 'category-3',
        text: 'Грузовики',
        icon: '/images/categories/truck.png',
        items: [
          {
            value: 'category-31',
            text: 'Самосвал',
            icon: '/images/categories/samosval.png',
          },
          {
            value: 'category-32',
            text: 'Седельный тягач',
            icon: '/images/categories/tyagach.png',
          },
          {
            value: 'category-33',
            text: 'Автобус на базе груз.тс',
            icon: '/images/categories/gruz-bus.png',
          },
          {
            value: 'category-34',
            text: 'Топливозаправщик',
            icon: '/images/categories/tanker.png',
          },
        ],
      },
      {
        value: 'category-4',
        text: 'Спец.техника',
        icon: '/images/categories/asfaltucladchick.png',
        items: [
          {
            value: 'category-41',
            text: 'Бульдозер',
            icon: '/images/categories/bulldozer.png',
          },
          {
            value: 'category-42',
            text: 'Трактор',
            icon: '/images/categories/tractor.png',
          },
          {
            value: 'category-43',
            text: 'Экскаватор',
            icon: '/images/categories/excavator.png',
          },
          {
            value: 'category-44',
            text: 'Каток',
            icon: '/images/categories/rink.png',
          },
          {
            value: 'category-45',
            text: 'Погрузчик',
            icon: '/images/categories/pogruzchick.png',
          },

          {
            value: 'category-46',
            text: 'Асфальукладчик',
            icon: '/images/categories/asfaltucladchick.png',
          },
          {
            value: 'category-47',
            text: 'Дорожная фреза',
            icon: '/images/categories/road-milling-cutter.png',
          },
        ],
      },
      {
        value: 'category-5',
        text: 'Автокран',
        icon: '/images/categories/auto-crane.png',
      },
      {
        value: 'category-6',
        text: 'Автогрейдер',
        icon: '/images/categories/autograider.png',
      },
      {
        value: 'category-7',
        text: 'Автобусы',
        icon: '/images/categories/bus.png',
        items: [
          {
            value: 'category-71',
            text: 'Микроавтобусы',
            icon: '/images/categories/mini-bus.png',
          },
        ],
      },
      {
        value: 'category-8',
        text: 'VIP',
        icon: '/images/categories/vip.png',
      },
      {
        value: 'category-9',
        text: 'Флот',
        icon: '/images/categories/flot.png',
      },
      {
        value: 'category-10',
        text: 'Вездеход',
        icon: '/images/categories/vezdehod.png',
      },
      // {
      //   value: 'category',
      //   text: 'Транспорт',
      //   icon: '/images/categories/vehicle.png',
      // },
    ],
  },
]);

const projectData = inject('projectData');
const objectsListUpdateTrigger = inject('objectsListUpdateTrigger');

const clientWidth = ref(document.documentElement.clientWidth);
const clientHeight = ref(document.documentElement.clientHeight);

const datepickerDate = ref([]);
const showMonitoring = ref('objects');
const showObjectsKeys = ref({});
const checkedObjectsKeys = ref([]);
const selectedTableColumnsArr = ref(
  MonitoringSettings.checkedObjectsListTableSettings,
);
const searchStr = ref('');
const templateSelected = ref('skillsMan');
const filterSelected = ref(['name']);
const heartbeatConnect = ref({
  connect: true,
  text: 'Связь с сервером установлена',
});
const isShowTableSettings = ref(false);
const toaster = ref(null);

const globalObjectsList = inject('globalObjectsList');
const countFilteredObjects = ref(null);

const saveTableSettingsHandler = (val) => {
  selectedTableColumnsArr.value = val;
};

const setTemplateNames = () => {
  const templateKeys = Object.keys(
    projectData.senderRights?.templates_names_available_arr,
  );

  TEMPLATE_NAMES.value = templateKeys.map((key) => ({
    value: key,
    text: projectData.senderRights.templates_names_available_arr[key],
  }));

  templateSelected.value = TEMPLATE_NAMES.value[0].value;
};

const checkAllObjects = () => {
  const objectsListKeys = Object.keys(showObjectsKeys.value);
  for (let i = 0; i < objectsListKeys.length; i++) {
    if (showObjectsKeys.value[objectsListKeys[i]]) {
      checkedObjectsKeys.value[i] = objectsListKeys[i];
    }
  }
};

const clearAllObjects = () => {
  checkedObjectsKeys.value.length = 0;
};

const heartBeatConnectHandler = (data) => {
  heartbeatConnect.value = data;
};

const showObjectsListItemsHandler = () => {
  const phrases = searchStr.value.toLowerCase().split(',');
  const objectsListKeys = Object.keys(showObjectsKeys.value);

  const regPhrases = [];
  for (let i = 0; i < phrases.length; i++) {
    regPhrases.push(new RegExp(phrases[i].trim(), 'i'));
  }

  const categoryFilterNames = filterSelected.value.filter((filterName) =>
    filterName.startsWith('category-'),
  );

  let counterFilteredObjects = 0;
  let flag = true;
  for (let i = 0; i < objectsListKeys.length; i++) {
    flag = true;

    // проверим категории
    for (const filterName of categoryFilterNames) {
      const category = filterName.replace('category-', '');
      flag = category == globalObjectsList[objectsListKeys[i]].category;
      if (flag) break;
    }

    for (let j = 0; j < regPhrases.length; j++) {
      // если объект входит в выбранные категории или категорий нет
      if (flag) {
        // если выбраны столбцы фильтрации - фильтруем
        // иначе выводим все объекты
        if (filterSelected.value.length) {
          for (const filterName of filterSelected.value) {
            // пропускаем категории - в выборке объекты только по нужным категориям, либо категорий нет
            if (categoryFilterNames.includes(filterName)) {
              continue;
            }

            if (
              ['last_time_aggregation', 'install_time'].includes(filterName)
            ) {
              flag = regPhrases[j].test(
                isTimeEqualZero(
                  globalObjectsList[objectsListKeys[i]][filterName],
                ),
              );
            } else {
              flag = regPhrases[j].test(
                globalObjectsList[objectsListKeys[i]][filterName]
                  .toString()
                  .toLowerCase(),
              );
            }

            // если совпадение найдено - дальше не идем
            if (flag) {
              break;
            }
          }
        } else {
          flag = true;
        }
      }

      // если фраз несколько, то с каждой итерацией пропускаем те объекты которые были отмечены до текущей итерации
      if (j > 0) {
        if (!showObjectsKeys.value[objectsListKeys[i]]) {
          showObjectsKeys.value[objectsListKeys[i]] = flag;
        }
      } else {
        showObjectsKeys.value[objectsListKeys[i]] = flag;
      }

      counterFilteredObjects += flag ? 1 : 0;
    }
  }

  countFilteredObjects.value = counterFilteredObjects;
};

const getUnixTimeByDate = (date) => {
  return new Date(date).getTime();
};

const countFiltered = computed(() => {
  return countFilteredObjects.value ?? Object.keys(globalObjectsList).length;
});

const columnsToObject = (columnsArr) => {
  const obj = {};
  for (let i = 0; i < columnsArr.length; i++) {
    obj[columnsArr[i]['name']] = columnsArr[i];
  }
  return obj;
};

const showTableSettingsHandler = () => {
  if (Object.keys(globalObjectsList).length) {
    isShowTableSettings.value = true;
  } else {
    toaster.value.warning({
      message: 'Необходимо дождаться полной загрузки списка объектов',
    });
  }
};

function executeReport() {
  mainScriptObjectsListFormSubmit(templateSelected.value);
}

watch(projectData, (data) => {
  if (!isAdmin) return;

  const adminColumns = FILTER_NAMES.value.filter((obj) =>
    ['id', 'client'].includes(obj.value),
  );

  if (!adminColumns.length) {
    FILTER_NAMES.value = [
      ...FILTER_NAMES.value,
      {
        value: 'id',
        text: 'ID',
      },
      {
        value: 'client',
        text: 'Владелец',
      },
    ];
  }
});

watch(objectsListUpdateTrigger, () => {
  if (searchStr.value) {
    showObjectsListItemsHandler();
  }

  const shownObjectsListKeys = Object.keys(showObjectsKeys.value);
  const objectsListKeys = Object.keys(globalObjectsList);

  if (
    JSON.stringify(shownObjectsListKeys) !== JSON.stringify(objectsListKeys)
  ) {
    for (let key in showObjectsKeys.value) {
      if (globalObjectsList[key]) {
        showObjectsKeys.value[key] = false;
      } else {
        delete showObjectsKeys.value[key];
      }
    }

    for (let i = 0; i < objectsListKeys.length; i++) {
      showObjectsKeys.value[objectsListKeys[i]] = true;
    }
  }
});

watch(datepickerDate, (newDate, oldDate) => {
  if (!newDate[1]) newDate[1] = oldDate[1];
});

watch(filterSelected, showObjectsListItemsHandler, { deep: true });

onMounted(() => {
  setTemplateNames();

  const d = new Date();
  // const from = new Date(2023, 2, 14, 0, 0, 0);
  // const to = new Date(2023, 2, 15, 0, 0, 0);
  const from = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate() - 1,
    0,
    0,
    0,
  );
  const to = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
  datepickerDate.value = [from.toString(), to.toString()];

  let resizeTimeout = 0;

  document.addEventListener('resize', () => {
    clientWidth.value = document.documentElement.clientWidth;
    clientHeight.value = document.documentElement.clientHeight;

    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(leafletMain.map.invalidateSize, 500);
  });
});

const isAdmin = computed(() => {
  return (
    projectData.userRole === 'admin' || projectData.userRole === 'main_admin'
  );
});
</script>

<style lang="scss">
.sidebar-active {
  & #objectsListForm .monitoring__container {
    width: 420px;
    margin: 0 auto;
  }
}
.show-sidebar-or-map-block {
  display: flex;
  & .show-sidebar-or-map-image {
    width: 30px;
    height: 30px;
    padding: 0;
    border: 1px solid #ccc;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.show-map {
      margin-left: 5px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}
.sidebar-datepicker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  & .dp__pointer {
    padding: 5px 10px;
    padding-right: 25px;
  }
}
.monitoring__container {
  width: 100%;
}
.monitoring__form {
  width: 100%;
  margin: 0;
}
.monitoring__template-select {
  width: 69%;
  margin: 0;
  padding: 0;
  @media (max-width: 450px) {
    width: 190px;
  }
}
.monitoring-sidebar__form {
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
.monitoring-sidebar__form-filter {
  @media (max-width: 450px) {
    width: 75%;
  }
}
.monitoring-sidebar__filter {
  width: 160px;

  @media (max-width: 768px) {
    width: auto;
  }
}
.monitoring-sidebar__filter-footer {
  & .d-inline {
    & select {
      padding: 0 5px;
      width: 125px;
    }
  }
  @media (max-width: 450px) {
    width: 100%;

    & .d-inline {
      width: 100%;
    }

    & .setting-btn {
      margin-right: 15px;
    }
  }
}
.monitoring__button-send {
  width: 30%;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
}
.monitoring-sidebar__settings-title {
  @media (max-width: 450px) {
    font-size: 22px;
  }
}
.monitoring-sidebar__settings-list {
  list-style: none;
  @media (max-width: 450px) {
    margin-left: -20px;
  }
}
</style>
