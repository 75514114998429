import { skillsManTemplate } from '@/Template/skills_man_template/skills_man_template.js';
import { formatDateHelper } from '@/helpers/main_helper_010.js';

const rows = {};

const accordanceColumns = {
  kren: 'DKren',
  tang: 'DTangage',
  com: 'DPTOWork',
  dist: 'DCanDist',
  time: 'DTime',
  speed: 'DCanSpeed',
  engine_temp: 'DCanEngineTemperature',
  accelerate: 'DCanAccelerate',
  rpm: 'DCanRmp',
  engine_load: 'DCanLoad',
  clutch: 'DCanClutch',
  gearbox: 'DCanGearbox',
  // 'acceleration': 'DAcceleration',
  engine_break: 'DEngineBreakes',
  // 'gear_ratio_spd_rpm': 'DGearRatioSpdRpm',
  //190720
  can_speed_not_on_taho: 'DCanSpeedNotOnTaho',
  gear_ratio: 'DGearRatio',
  input_kp_speed: 'DInputSpeed',
  output_kp_speed: 'DOutputSpeed',
  clutch_slip: 'DClutchSlip',
  clutch_slip_speed: 'DClutchSlipSpeed',
  clutch_work: 'DClutchWork',
  clutch_work_avg: 'DClutchWorkAvg',
  clutch_work_avg_last_inclusion: 'DClutchWorkAvgLastInclusion',
  clutch_work_avg_period_summ: 'DClutchWorkAvgPeriodSumm',
  clutch_last_time_temperature: 'DClutchLastTimeTemperature',
  rotating_moment: 'DRotatingMoment',
  rotating_moment_max: 'DRotatingMomentMax',
  rotating_moment_value: 'DRotatingMomentValue',
  break_position: 'DBreakPosition',
  hand_break: 'DHandBreak',
  low_oil_lamp: 'DLowOilLamp',
  hight_cool_temp_lamp: 'DHightCoolTempLamp',
  selected_gear: 'DSelectedGear',
  behavior_write_status: 'DBehaviorWriteStatus',
};

export default (
  {
    REPORT,
    violationsDescriptionsDesc,
    detailViolationsSetting, // dom element value
    morePositionsCnt = 5, // dom element value
    detailPosCntElementValue = 0, // dom element value
    allPosesInDetailElementChecked = false, // dom element value
    // rows = {}, // get in the skillsManDetailTemplate.rows
  },
  sendLog = () => {},
) => {
  if (!violationsDescriptionsDesc) {
    throw new Error('violateionsDescription.desc is not defined');
  }

  if (detailPosCntElementValue) {
    const detailPosCnt = parseInt(detailPosCntElementValue, 10);

    if (detailPosCnt) {
      morePositionsCnt = detailPosCnt;
    }
  }
  const detailViolationsId = detailViolationsSetting.ids.map(
    (detailViolation) => detailViolation.id,
  );

  const detailViolationsDesc = detailViolationsSetting.ids.map(
    (detailViolation) => detailViolation.desc,
  );
  const { isAll: isAllId } = detailViolationsSetting;
  const allPoses = allPosesInDetailElementChecked;

  if (!REPORT['objConf']) {
    throw new Error(`REPORT['objConf'] is not defined`);
  } else if (!REPORT['objConf']['gearbox_cnt']) {
    throw new Error(`REPORT['objConf']['gearbox_cnt'] is not defined`);
  } else if (!REPORT['positions']) {
    throw new Error(`REPORT['positions'] is not defined`);
  }

  let transmissionLockArr = skillsManTemplate.getTransmissionLockArr(
    REPORT['objConf'],
  );

  addDesignTable_detail(
    rows,
    REPORT['objConf'],
    REPORT['objName'],
    REPORT['gearboxName'],
    skillsManTemplate.codeGearBoxNumName,
    transmissionLockArr,
    REPORT['getBegin'],
    REPORT['getEnd'],
  );

  let detailViolationRows = [];
  let i_begin = 0;
  let i_end = 0;
  let posCnt = REPORT['positions'].length;

  // loop through the positions
  for (let i = 0; i < posCnt; i++) {
    if (allPoses) {
      // выгрузка всех позиций на лист
      detailViolationRows.push(
        getDetailRows(
          0,
          posCnt - 1,
          REPORT['positions'],
          transmissionLockArr,
          REPORT['objConf'],
          detailViolationsId,
          isAllId,
        ),
      );
      break;
    }
    if (i % 100 === 0) {
      const percent = Math.round((100 * (i + 1)) / posCnt);
      sendLog(`Обработано ${i + 1} из ${posCnt} (${percent}%)`);
    }

    let pos = REPORT['positions'][i];
    let violationsId = getViolationsId(pos, detailViolationsId, isAllId);
    let isViol = violationsId.length;
    if (!i_begin && !i_end && !isViol) {
      continue;
    }

    if (isViol && i_end) {
      // extension latest violation
      i_end = i + morePositionsCnt;
    }

    if (isViol && !i_end) {
      // first violation after morePositionsCnt * 2 positions
      i_begin = i - morePositionsCnt;
      i_end = i + morePositionsCnt;
    }

    if (i_begin < 0) {
      i_begin = 0;
    }

    if (i_end > posCnt - 1) {
      i_end = posCnt - 1;
    }

    if (i_end && i > i_end + morePositionsCnt) {
      // end and add violations strings
      detailViolationRows.push(
        getDetailRows(
          i_begin,
          i_end,
          REPORT['positions'],
          transmissionLockArr,
          REPORT['objConf'],
          detailViolationsId,
          isAllId,
        ),
      );

      i_end = 0;
      i_begin = 0;
    }
    // violationsPositions.push( {'violationsId': violationsId, 'posIndex': i, 'posIndexBegin': i-morePositionsCnt} );
  }

  rows.detailViolationRows = detailViolationRows;
  // rows.format = formatToDisplay_helper; // defined in skills_man_detail_template.js
  rows.violationsDescriptions = violationsDescriptionsDesc;
  // rows.getVolationDesc_helper = getVolationDesc_helper; // helper
  rows.accordanceColumns = accordanceColumns;
  rows.violationsFilter = { detailViolationsDesc, isAllId };
  return rows;
};

function getDetailViolationsId(detailViolationsTable) {
  if (!detailViolationsTable) {
    return {
      ids: [],
      isAll: true,
    };
  }

  const table = detailViolationsTable;

  return [...table.rows].reduce(
    (accum, row) => {
      const [input, label] = [...row.cells].map(
        (cell) => cell.firstElementChild,
      );

      if (input.checked) {
        const idText = input.dataset.id || -1;
        const id = parseInt(idText, 10);
        const desc = label.textContent;

        accum.ids.push({ id, desc });
      } else {
        accum.isAll = false;
      }

      return accum;
    },
    { ids: [], isAll: true },
  );
}

function addDesignTable_detail(
  rows,
  iobjConf,
  iobjName,
  iGearboxName,
  codeGearBoxNumNameFunction,
  transmissionLockArr,
  getBegin,
  getEnd,
) {
  rows.header = {};
  // let model = iobjConf['model'] || '';
  rows.header.reportName = `Детализация параметров работы ТС ${iobjName} / ${iGearboxName} ${iobjConf['gearbox_cnt']}`;
  rows.header.reportPeriod =
    'за период с ' +
    formatDateHelper(new Date(getBegin * 1000), 'dd.mm.yyyy hh:nn:ss') +
    ' по ' +
    formatDateHelper(new Date(getEnd * 1000), 'dd.mm.yyyy hh:nn:ss');

  rows.header.transmissionLockArr = transmissionLockArr; // вытаскиваем настройку блокировок трансмиссии объекта, в поле Преобразование должно быть X
  rows.header.transmissionLockNameArr = [];
  for (let i = 0; i < rows.header.transmissionLockArr.length; i++) {
    if (i == 0) {
      rows.header['transmissionLock_name'] = addHeaderDict(
        'Название режима трансмиссии',
        0,
        2,
        0,
        49,
      ); //если есть хотябы один - добавится и таблица
      rows.header.transmissionLockNameArr.push('transmissionLock_name'); //для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
    }
    rows.header[rows.header.transmissionLockArr[i].nameArr] = addHeaderDict(
      String(rows.header.transmissionLockArr[i].nameLock),
      0,
      2,
    ); //заполнение шапки с названием блокировок
    rows.header.transmissionLockNameArr.push(
      rows.header.transmissionLockArr[i].nameArr,
    ); //для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
  }

  //объявление таблицы ДЕТАЛИЗАЦИЯ, строки для нее будут созданы при обходе позиций

  addHeaderRowDefaultDict(rows, 'DKren', {
    Hname: 'Угол крена',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DTangage', {
    Hname: 'Угол тангажа',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DPTOWork', {
    Hname: 'Работа КОМ',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 12,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanDist', {
    Hname: 'CAN пробег',
    HminWidth: 13,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 9,
    RalignmentH: -1,
  }); //заголовок (шапка) 9 - три после запятой
  addHeaderRowDefaultDict(rows, 'DTime', {
    Hname: 'Время',
    HminWidth: 20,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 1,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanSpeed', {
    Hname: 'Скорость (тахо или CAN)',
    HminWidth: 9.4,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 12,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanEngineTemperature', {
    Hname: 'CAN темп. двигателя',
    HminWidth: 10.6,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 12,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanAccelerate', {
    Hname: 'CAN положение педали газа',
    HminWidth: 13,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 11,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanRmp', {
    Hname: 'CAN обороты двигателя',
    HminWidth: 10.6,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 12,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanLoad', {
    Hname: 'CAN нагрузка на двигатель',
    HminWidth: 12,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 11,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanClutch', {
    Hname: 'Сцепление, разомкнуто/сомкнуто',
    HminWidth: 13,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: -1,
  }); //заголовок (шапка)

  //190720
  addHeaderRowDefaultDict(rows, 'DCruiseControl', {
    Hname: 'Круиз контроль',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DLowOilLamp', {
    Hname: 'Лампа давления масла',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DHightCoolTempLamp', {
    Hname: 'Лампа температуры ДВС',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: -1,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DRotatingMoment', {
    Hname: 'Крутящий момент, % от max',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DRotatingMomentMax', {
    Hname: 'Крутящий момент max, Нм',
    HminWidth: 8,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DRotatingMomentValue', {
    Hname: 'Крутящий момент, Нм',
    HminWidth: 8,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DClutchWork', {
    Hname: 'Работа трения сцепления мгновенная, Дж',
    HminWidth: 11,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DClutchWorkAvg', {
    Hname: 'Работа трения сцепления, Дж',
    HminWidth: 11,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DClutchWorkAvgLastInclusion', {
    Hname: 'Работа трения сцепления, сумма за последнее включение, Дж',
    HminWidth: 11,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DClutchWorkAvgPeriodSumm', {
    Hname: 'Работа трения сцепления, всего, кДж',
    HminWidth: 11,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DClutchLastTimeTemperature', {
    Hname: 'Темп. дисков сцепл. за 5 минут, °C',
    HminWidth: 10,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка) // 09.05.2020
  addHeaderRowDefaultDict(rows, 'DBreakPosition', {
    Hname: 'Педаль тормоза',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 11,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DHandBreak', {
    Hname: 'Ручной тормоз',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DCanSpeedNotOnTaho', {
    Hname: 'CAN скорость (НЕ тахо)',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DGearRatio', {
    Hname: 'Передат. число КП',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DInputSpeed', {
    Hname: 'Первичный вал КП, об/мин',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DOutputSpeed', {
    Hname: 'Выходной вал КП, об/мин',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DClutchSlip', {
    Hname: 'Размыкание сцепления, %',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 11,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DClutchSlipSpeed', {
    Hname: 'Мгновенная пробукс сцепления, в текщую сек., об/сек',
    HminWidth: 12,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DBehaviorWriteStatus', {
    Hname: 'Причина записи терминалом',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DBehaviorWriteStatusDesc', {
    Hname: 'Причина записи (описание)',
    HminWidth: 30,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: 7,
  }); //заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DSelectedGear', {
    Hname: 'Выбранная передача',
    HminWidth: 11.5,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0,
    RalignmentH: -1,
  }); //заголовок (шапка)

  addHeaderRowDefaultDict(rows, 'DCanGearbox', {
    Hname: 'CAN АКПП, ПЕРЕДАЧА',
    HminWidth: 11.5,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 12,
    RalignmentH: -1,
  }); //заголовок (шапка)
  // addHeaderRowDefaultDict(rows, 'DGearRatioSpdRpm', {Hname: "Передаточное, от ДВС к CAN скорости",
  //     HminWidth: 13.5, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5.5, RalignmentH: 7});//заголовок (шапка)
  // addHeaderRowDefaultDict(rows, 'DAcceleration', {Hname: "Ускорение, м/с2",
  //     HminWidth: 9, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: 7});//заголовок (шапка)
  addHeaderRowDefaultDict(rows, 'DEngineBreakes', {
    Hname: 'Моторный тормоз',
    HminWidth: 7,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
    Rformat: 0.5,
    RalignmentH: 7,
  }); //заголовок (шапка)
  for (var z = 0; z < rows.header.transmissionLockArr.length; z++) {
    //addHeaderRowDefaultDict(rows, 'D' + rows.header.transmissionLockArr[z].nameArr, {Hname: rows.header.transmissionLockNameArr[z].name, HminWidth: 10, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
    //    {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 0}); //строки
    addHeaderRowDefaultDict(
      rows,
      'D' + rows.header.transmissionLockArr[z].nameArr,
      {
        Hname: rows.header.transmissionLockArr[z].nameLock,
        HminWidth: 17,
        Hcolspan: 0,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 12,
        RalignmentH: 7,
      },
    ); //заголовок (шапка) ДЕТАЛИЗАЦИИ
    //nameArrLockFromDetailTable.push('D' + rows.header.transmissionLockArr[z].nameArr);//для выгрузки имен шапки блокировок
    //rows.header['D' + rows.header.transmissionLockArr[z].nameArr] = addHeaderDict(rows.header.transmissionLockArr[z].name, 14, 7, 0, 0, 0, 0);//последняя цифра-формат вывода
  }
  addHeaderRowDefaultDict(rows, 'DComment', {
    Hname: 'Неэффектифная/неправильная эксплуатация, комментарий',
    HminWidth: 30,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
  }); //заголовок (шапка)

  addHeaderRowDefaultDict(rows, 'DdrvTaho1', {
    Hname: 'Код первой карты тахографа',
    HminWidth: 20,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
  }); //заголовок (шапка)

  addHeaderRowDefaultDict(rows, 'DdrvTahoState1', {
    Hname: 'Режим первой карты тахографа',
    HminWidth: 20,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
  }); //заголовок (шапка)

  addHeaderRowDefaultDict(rows, 'DdrvTaho2', {
    Hname: 'Код второй карты тахографа',
    HminWidth: 20,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
  }); //заголовок (шапка)

  addHeaderRowDefaultDict(rows, 'DdrvTahoState2', {
    Hname: 'Режим второй карты тахографа',
    HminWidth: 20,
    Hcolspan: 0,
    Hrowspan: 0,
    HminHeight: 0,
    HalignmentH: -1,
  }); //заголовок (шапка)

  return rows;

  function addHeaderDict(
    name,
    minWidth,
    colspan,
    rowspan,
    minHeight,
    alignmentH,
    format,
    rowAlignmentH,
  ) {
    return {
      name: name || 'NULL',
      minWidth: minWidth || 0,
      minHeight: minHeight || 0,
      colspan: colspan || 0,
      rowspan: rowspan || 0,
      alignmentH: alignmentH !== undefined ? alignmentH : -1,
      format: format || 0, //используется при выгрузке детализации
      rowAlignmentH: rowAlignmentH !== undefined ? rowAlignmentH : -1, //используется при выгрузке детализации
      colnum: 0, //номер столбца в шапке таблицы, используется далее, тут оставлять 0
      rownum: 0, //номер троки в шапке таблицы, используется далее при транспонировании таблицы, тут оставлять 0
      cellStep: 0, //объединение ячеек по вертикали для строки, не являющейся шапкой, используется при расчетах, тут оставлять 0
    };
    /*
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру
            */
  }

  //строка
  function addRowDict(header, format, val, rowspan, colspan) {
    return {
      header: header ? header : false,
      format: format ? format : 0,
      val: val ? val : 0,
      rowspan: rowspan ? rowspan : 0,
      colspan: colspan ? colspan : 0,
    };
  }

  function addHeaderRowDefaultDict(
    rows,
    rowArrname,
    addHeaderDictArr,
    addRowDictArr,
  ) {
    rows.header[rowArrname] = addHeaderDict(
      addHeaderDictArr.Hname,
      addHeaderDictArr.HminWidth,
      addHeaderDictArr.Hcolspan,
      addHeaderDictArr.Hrowspan,
      addHeaderDictArr.HminHeight,
      addHeaderDictArr.HalignmentH,
      addHeaderDictArr.Rformat,
      addHeaderDictArr.RalignmentH,
    );
    if (addRowDictArr) {
      rows[rowArrname] = [];
      rows[rowArrname].val = addRowDict(
        rowArrname,
        addRowDictArr.Rformat,
        addRowDictArr.Rval,
        addRowDictArr.Rrowspan,
        addRowDictArr.Rrcolspan,
      );
    }
  }
}

function getDetailRows(
  i_begin,
  i_end,
  positions,
  transmissionLockArr,
  objConf,
  detailViolationsId,
  isAllId,
) {
  const notValue = 'н/д';
  const KILO = 1000;
  let detailRows = [];
  let prevSpeed = i_begin > 0 ? positions[i_begin - 1]['speed'] : -1;
  let timePrev = i_begin > 0 ? positions[i_begin - 1]['time'] : 0;

  for (let i = i_begin; i < i_end + 1; i++) {
    if (i > positions.length) continue;
    let pos = positions[i];
    let curRow_ = {};

    curRow_['violationsId'] = getViolationsId(pos, detailViolationsId, isAllId);
    if (curRow_['violationsId'].length) {
      curRow_['clutch_time_uninterruptedly'] =
        pos['clutch_time_uninterruptedly'];
      curRow_['pto_cnt_violation'] = pos['pto_cnt_violation'];
      curRow_['spd_accel'] = pos['spd_accel'];
      curRow_['iobj_accelLimit'] = 50; // по последнему письму всегда 50 %
    }

    let timeDiff = timePrev ? pos['time'] - timePrev : 0;

    let transmission_locks = pos['transmission_locks'];
    for (let z = 0; z < transmissionLockArr.length; z++) {
      curRow_['D' + transmissionLockArr[z].nameArr] =
        transmission_locks & (1 << transmissionLockArr[z].num)
          ? 'включено'
          : '0';
    }

    curRow_.DKren = pos['kren'] / 100;
    curRow_.DTangage = pos['tang'] / 100;
    curRow_.DPTOWork = pos['com'] ? 'включено' : '0';
    curRow_.DCanDist = pos['dist'] / 1000;
    curRow_.DTime = pos['time'];
    curRow_.DCanSpeed = pos['speed'] / 10;
    curRow_.DCanEngineTemperature = pos['engine_temp'];
    curRow_.DCanAccelerate = pos['accelerate'];
    curRow_.DCanRmp = pos['rpm'];
    curRow_.DCanLoad = pos['engine_load'];

    if (objConf['gearbox_is_clutch']) {
      curRow_.DCanClutch = pos['clutch'] ? 'разомкнуто' : 'сомкнуто';
    } else {
      curRow_.DCanClutch = 'нет данных';
    }

    curRow_.DCanGearbox = pos['gearbox'];
    // if (pos['speed'] > 0 && pos['rpm'] > 0) {
    //     curRow_.DGearRatioSpdRpm = pos['rpm'] / pos['speed'];
    // } else {
    //     curRow_.DGearRatioSpdRpm = pos['rpm'] > -1 && pos['speed'] > -1 ? ' - ' : notValue;
    // }
    //curRow_.DComment = '';
    //curRow_.DColor = '';//цвет строки
    // if (pos['speed'] > -1 && prevSpeed > -1 && timeDiff > 0){
    //     curRow_.DAcceleration = (pos['speed'] - prevSpeed) * 278 / timeDiff; //0.278 перевод в м/с, а 1000 перевод в секунды для timeDiff
    // } else {
    //     curRow_.DAcceleration = !(timeDiff > 0) ? ' - ' : notValue;
    // }
    //моторный тормоз это параметр (p213<27000), когда данный параметр становится = 27000, работа моторного тормоза прекращается

    curRow_.DEngineBreakes = pos['engine_break'] ? 'вкл.' : 'откл.';
    curRow_.DdrvTaho1 = pos['drv_tah_1'] || 'нет';
    curRow_.DdrvTaho2 = pos['drv_tah_2'] || 'нет';
    curRow_.DdrvTahoState1 = getTahoDrvState(pos['drv_tah_s'], 1);
    curRow_.DdrvTahoState2 = getTahoDrvState(pos['drv_tah_s'], 2);

    curRow_.DCanSpeedNotOnTaho = getValue(
      pos['can_speed_not_on_taho'],
      -1,
      notValue,
    );
    curRow_.DGearRatio = getValue(pos['gear_ratio'], -1, notValue, 10);
    curRow_.DInputSpeed = getValue(pos['input_kp_speed'], -1, notValue, 10);
    curRow_.DOutputSpeed = getValue(pos['output_kp_speed'], -1, notValue, 10);
    curRow_.DClutchSlip = getValue(pos['clutch_slip'], -1, notValue);
    curRow_.DClutchSlipSpeed = getValue(pos['clutch_slip_speed'], 0, ' - ', 10);
    curRow_.DClutchWork = getValue(pos['clutch_work'], 0, ' - ', 10);
    curRow_.DClutchWorkAvg = getValue(pos['clutch_work_avg'], 0, ' - ', 10);
    curRow_.DClutchWorkAvgLastInclusion = getValue(
      pos['clutch_work_avg_last_inclusion'],
      0,
      ' - ',
      10,
    );
    curRow_.DClutchWorkAvgPeriodSumm = getValue(
      pos['clutch_work_avg_period_summ'],
      0,
      ' - ',
      KILO,
    );
    curRow_.DClutchLastTimeTemperature = getValue(
      pos['clutch_last_time_temperature'],
      0,
      ' - ',
      100,
    );
    curRow_.DRotatingMoment = getValue(pos['rotating_moment'], 0, ' - ');
    curRow_.DRotatingMomentMax = getValue(
      pos['rotating_moment_max'],
      0,
      ' - ',
      10,
    );
    curRow_.DRotatingMomentValue = getValue(
      pos['rotating_moment_value'],
      0,
      ' - ',
      10,
    );
    curRow_.DBreakPosition = getValue(pos['break_position'], -1, notValue);
    curRow_.DHandBreak =
      pos['hand_break'] === -1
        ? notValue
        : pos['hand_break'] === 1
        ? 'вкл.'
        : 'откл.';
    curRow_.DLowOilLamp = getValue(pos['low_oil_lamp'], -1, ' - ');
    curRow_.DHightCoolTempLamp = getValue(
      pos['hight_cool_temp_lamp'],
      -1,
      ' - ',
    );
    curRow_.DSelectedGear = getValue(pos['selected_gear'], -1, ' - ');
    curRow_.DBehaviorWriteStatus = getValue(
      pos['behavior_write_status'],
      -1,
      ' - ',
    );

    prevSpeed = pos['speed'];
    timePrev = pos['time'];

    detailRows.push(curRow_);
  }
  return detailRows;
}

function getViolationsId(pos, detailViolationsId, isAllId) {
  let column = 0;
  const violations_id = [];

  while ('violation_' + Number(++column) in pos) {
    const col_name = 'violation_' + column;

    if (!(pos[col_name] > 0)) {
      continue;
    }

    for (let i = 0; i < 31; i++) {
      if (pos[col_name] & (1 << i)) {
        const viol_id = i + 1 + 31 * (column - 1);
        if (isAllId || detailViolationsId.includes(viol_id)) {
          // фильтр нарушений из настройки отчета
          violations_id.push(viol_id);
        }
      }
    }
  }

  if (violations_id.length > 1 && violations_id.includes(17)) {
    const removedIndex = violations_id.indexOf(17);

    if (removedIndex > -1) {
      violations_id.splice(removedIndex, 1);
    }
  }

  return violations_id;
}

function getTahoDrvState(state, drvNum) {
  state = parseInt(state);
  if (!state) {
    return 'нет';
  }
  let drvState = -1;
  if (drvNum === 1) {
    drvState = state & 0xf;
  }
  if (drvNum === 2) {
    drvState = (state & 0xf00) >> 16;
  }
  return (
    {
      0: 'отдых',
      1: 'готовность',
      2: 'работа',
      3: 'вождение',
    }[drvState] || 'не определен'
  );
}

function getValue(value, comparison, notValue, delimeter = 0) {
  if (value > comparison) {
    if (delimeter) {
      return value / delimeter;
    }

    return value;
  }

  return notValue;
}
