<template>
  <!-- Настройка отчета: само окно-->
  <div
    id="skills-man-detail-template-setting"
    class="template-setting-window"
    style="display: flex"
  >
    <div class="template-setting-window-header">
      <div class="template-setting-window-header-name">
        <strong>Навыки MAN (детализация)</strong><br />
        <small>Настройка отчета</small>
      </div>
      <!-- Картинка крестика-->
      <img
        class="close"
        src="/images/close.png"
        @click="closeHandler"
      />
    </div>

    <div class="overflow-auto">
      <table class="unselectable">
        <tr>
          <td>
            <label for="skills-man-detail-template-poscount"
              >Число позиций</label
            >
          </td>
          <td>
            <input
              id="skills-man-detail-template-poscount"
              type="text"
              v-model="posCount"
            />
          </td>
        </tr>
        <tr>
          <td>
            <label for="skills-man-detail-template-allpositions"
              >Все позиции</label
            >
          </td>
          <td>
            <input
              id="skills-man-detail-template-allpositions"
              type="checkbox"
              v-model="allPositionsChecked"
            />
          </td>
        </tr>
      </table>
      <hr />

      <div
        id="skills-man-detail-template-violations"
        class="unselectable"
      >
        Нарушения
        <span
          data-elem="checked"
          class="padding-none margin-none btn btn-link"
          style="margin: 0"
          >Выбрать все</span
        >
        <span
          data-elem="unChecked"
          class="padding-none margin-none btn btn-link"
          style="margin: 0"
          >Очистить все</span
        >

        <table>
          <tr
            v-for="descObj in props.violationsDescriptions"
            :key="descObj.id"
          >
            <td>
              <input
                :data-id="descObj.id"
                :id="'skills-man-detail-template-violation-' + descObj.id"
                type="checkbox"
                checked
              />
            </td>
            <td>
              <label
                data-desc
                :for="'skills-man-detail-template-violation-' + descObj.id"
                >{{ descObj.description }}</label
              >
            </td>
          </tr>
        </table>
      </div>

      <hr />

      <div class="padding-top-5 text-center">
        <small>Окончание настройки отчета</small>
      </div>
    </div>
    <div class="text-center">
      <span
        class="target-show"
        @mouseenter="addHoverOnTargetByParent($event)"
        @mouseleave="removeHoverOnTargetByParent($event)"
      >
        <input
          id="skills-man-detail-template-setting-window-get-template-button"
          class="btn btn-sm"
          :class="[
            {
              'btn-danger': isButtonDanger,
              'btn-primary config-btn-primary': !isButtonDanger,
            },
          ]"
          type="submit"
          value="Выполнить отчет"
          @click="renderReport"
        />
        <span class="target-show-span">{{ targetText }}</span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import { SkillsManDetailTemplate } from '../../../Template/skills_man_template/skills_man_detail_template';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template';
import {
  addHoverOnTargetByParent,
  removeHoverOnTargetByParent,
} from '../../helpers/targetsHelper';
import { callOurPositions } from '../../use/getElementPosition';

const props = defineProps({
  userRole: {
    type: String,
    default: '',
  },
  violationsDescriptions: { type: Object, require: true },
});

const emit = defineEmits(['close']);

const posCount = ref('5');
const allPositionsChecked = ref(false);
const isButtonDanger = ref(false);
const targetText = ref('Обсчет данных для получения отчета');

const globalObjectsList = inject('globalObjectsList');

watch(posCount, () => {
  allPositionsChecked.value = false;
});

let skillsManDetailTemplate;
onMounted(() => {
  isButtonDanger.value = false;
  targetText.value = 'Обсчет данных для получения отчета';
  skillsManDetailTemplate = new SkillsManDetailTemplate(
    skillsManTemplate,
    lodashRenderHtml_helper,
    props.userRole,
  );
});

const renderReport = (event) => {
  const form_desc = objectsListFormSerializeArray_helper('objectsListForm');
  const visibleElements = objectsListFormColorAndTarget_helper(form_desc);

  if (!visibleElements) {
    isButtonDanger.value = true;
    targetText.value = 'Не выбран ни один объект';
    return;
  }

  isButtonDanger.value = false;
  targetText.value = 'Обсчет данных для получения отчета';

  const dataRequest = {
    objId: form_desc.objects[0],
    timeBegin: +form_desc.beginTime,
    timeEnd: +form_desc.endTime,
  };

  callOurPositions({
    obj: globalObjectsList[dataRequest.objId] || { id: dataRequest.objId },
    clickElem: visibleElements.button,
    targetElem: visibleElements.targetSpan,
    timeInterval: {
      timeBegin: +dataRequest.timeBegin,
      timeEnd: dataRequest.timeEnd,
    },
    isOnMap: false,
    isDistFilter: false,
    skillsManDetailTemplate,
    isWebSocket: event.altKey,
  });
  closeHandler();
};
const closeHandler = () => {
  document.getElementById('background-wrap').style.display = 'none';
  emit('close', 'skillsManDetailSettings');
};
</script>

<style></style>
