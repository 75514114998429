<template>
  <table
    class="text-align-center"
    border="1"
    cellpadding="5"
    style="
      border-collapse: collapse;
      border: 1px solid black;
      caption-side: top;
      margin-right: 5px;
    "
  >
    <thead>
      <tr class="border-bottom-tr print-repeat">
        <template
          v-for="colHead of DAILY_DETAIL_COLUMNS"
          :key="colHead.name"
        >
          <th
            v-bind="colHead.attrs"
            v-html="colHead.label"
          ></th>
        </template>
      </tr>
      <tr class="border-bottom-tr print-repeat">
        <template
          v-for="colHead of DAILY_DETAIL_COLUMNS"
          :key="colHead.name"
        >
          <th
            v-for="col of colHead.cols"
            :key="col.label"
            v-bind="col.attrs"
            v-html="col.label"
          ></th>
        </template>
      </tr>
    </thead>

    <tbody>
      <template
        v-for="index of getMaxScaleColsCount(DAILY_DETAIL_COLUMNS)"
        :key="index"
      >
        <tr>
          <template
            v-for="colHead of DAILY_DETAIL_COLUMNS"
            :key="colHead.name"
          >
            <template
              v-for="col of colHead.cols"
              :key="col.name + index + colHead.name"
            >
              <td
                v-if="col.name === 'scale'"
                class="text-left"
                v-html="SCALE[colHead.name][index - 1]?.label || ''"
              ></td>
              <td
                v-else-if="col.name === 'value'"
                :data-formatnum="7"
                @click="
                  () => +col.format(index - 1) != 0 && col.onClick(index - 1)
                "
              >
                <div
                  v-bind="col.attrs(index - 1)"
                  v-html="tableFormat(col.format(index - 1))"
                ></div>
              </td>
              <td
                v-else-if="col.name === 'percent'"
                :data-formatnum="6"
              >
                {{ tableFormat(col.format(index - 1)) }}
              </td>
            </template>
          </template>
        </tr>
      </template>
    </tbody>
  </table>

  <daily-detail-popup
    v-model="detailOptions.isShow"
    :object="props.object"
    :oid="detailOptions.oid"
    :name="detailOptions.name"
    :from="detailOptions.from"
    :to="detailOptions.to"
    :param-title="detailOptions.paramTitle"
    :paramKey="detailOptions.paramKey"
    :nextParamKey="detailOptions.nextParamKey"
  />
</template>

<script setup>
import { inject, ref } from 'vue';

import DailyDetailPopup from '../popup/DailyDetailPopup.vue';

import {
  getPercent,
  myRoundNumber_helper,
  tableFormat,
} from '@/helpers/main_helper_010';

const props = defineProps({
  object: { type: Object, required: true },
  interval: { type: Object, required: true },
});

const toHours = inject('toHours');

const SCALE = {
  rpms: [
    { name: '2001', label: '> 2000' },
    { name: '2000', label: '1901 - 2000' },
    { name: '1900', label: '1801-1900' },
    { name: '1800', label: '1701-1800' },
    { name: '1700', label: '1601-1700' },
    { name: '1600', label: '1501-1600' },
    { name: '1500', label: '1401-1500' },
    { name: '1400', label: '1301-1400' },
    { name: '1300', label: '1200-1300' },
    { name: '1200', label: 'менее 1200' },
  ],
  engtmprs: [
    { name: '96', label: '> 95 (перегрев)' },
    { name: '95', label: '86-95' },
    { name: '85', label: '61-85' },
    { name: '60', label: '41-60' },
    { name: '40', label: '1-40' },
    { name: '0', label: 'от -19  до 0' },
    { name: '-20', label: 'от -30 до -20' },
    { name: '-30', label: 'менее -30' },
  ],
  hydoiltmprs: [
    { name: '100', label: '91-100% (перегрев)' },
    { name: '90', label: '81-90%' },
    { name: '80', label: '61-80%' },
    { name: '60', label: '51-60%' },
    { name: '50', label: '31-50%' },
    { name: '30', label: '0-30%' },
  ],
};

const DAILY_DETAIL_COLUMNS = [
  {
    name: 'hydoiltmprs',
    label: `Температура гидравлического масла<br>(в процентах, в соответствии с дисплеем)`,
    attrs: {
      'style': { minWidth: '310px', textAlign: 'left' },
      'colspan': 3,
      'data-minwidth': 16,
    },
    cols: [
      {
        name: 'scale',
        label: '',
        attrs: {},
        format: (val) => val,
      },
      {
        name: 'value',
        label: 'м/ч',
        attrs: (index) => ({
          class: [
            {
              'bg-red-12':
                index == 0 && +getValue(props.object, index, 'rpms') > 0,
              'my-link': +getValue(props.object, index, 'hydoiltmprs') > 0,
            },
          ],
        }),
        format: (index) => getValue(props.object, index, 'hydoiltmprs'),
        onClick(index) {
          displayDailyDetail(index, 'hydoiltmprs');
        },
      },
      {
        name: 'percent',
        label: '% от общего',
        attrs: {},
        format: (index) => getDailyPercent(props.object, index, 'hydoiltmprs'),
      },
    ],
  },
  {
    name: 'engtmprs',
    label: `Температура охлаждающей жидкости<br>(градусов по Цельсию)`,
    attrs: {
      'style': { minWidth: '280px', textAlign: 'left' },
      'colspan': 3,
      'data-minwidth': 16,
    },
    cols: [
      {
        name: 'scale',
        label: '',
        attrs: {},
        format: (val) => val,
      },
      {
        name: 'value',
        label: 'м/ч',
        attrs: (index) => ({
          class: [
            {
              'bg-red-12':
                index == 0 && +getValue(props.object, index, 'rpms') > 0,
              'my-link': +getValue(props.object, index, 'engtmprs') > 0,
            },
          ],
        }),
        format: (index) => getValue(props.object, index, 'engtmprs'),
        onClick(index) {
          displayDailyDetail(index, 'engtmprs');
        },
      },
      {
        name: 'percent',
        label: '% от общего',
        attrs: {},
        format: (index) => getDailyPercent(props.object, index, 'engtmprs'),
      },
    ],
  },
  {
    name: 'rpms',
    label: `Обороты двигателя<br>(оборотов в минуту)`,
    attrs: {
      'style': { minWidth: '275px', textAlign: 'left' },
      'colspan': 3,
      'data-minwidth': 16,
    },
    cols: [
      {
        name: 'scale',
        label: '',
        attrs: {},
        format: (val) => val,
      },
      {
        name: 'value',
        label: 'м/ч',
        attrs: (index) => ({
          class: [
            {
              'bg-red-12':
                index == 0 && +getValue(props.object, index, 'rpms') > 0,
              'my-link': +getValue(props.object, index, 'rpms') > 0,
            },
          ],
        }),
        format: (index) => getValue(props.object, index, 'rpms'),
        onClick(index) {
          displayDailyDetail(index, 'rpms');
        },
      },
      {
        name: 'percent',
        label: '% от общего',
        attrs: {},
        format: (index) => getDailyPercent(props.object, index, 'rpms'),
      },
    ],
  },
];

const detailOptions = ref({
  isShow: false,
  oid: '',
  name: '',
  from: 0,
  to: 0,
  paramTitle: '',
  paramKey: '',
  nextParamKey: '',
});

const getMaxScaleColsCount = (arr) => {
  const scaleColsCount = (name) => Object.keys(SCALE[name]).length;

  const col = arr.toSorted(
    (a, b) => scaleColsCount(b.name) - scaleColsCount(a.name),
  )[0];

  return scaleColsCount(col.name);
};

const getValue = (obj, index, key) => {
  if (!SCALE[key][index]) return '';

  let val = ' - ';
  if (!obj.sum[key]) {
    return val;
  }
  val = obj.sum[key][SCALE[key][index].name];

  return isNaN(val) ? ' - ' : `${myRoundNumber_helper(toHours(val), 1)}`;
};

const getDailyPercent = (obj, index, key) => {
  if (!SCALE[key][index]) return '';

  let val = 0;
  if (!obj.sum[key]) {
    return val;
  }
  val = obj.sum[key][SCALE[key][index].name];

  if (!val || !obj.sum.engine_time) return 0;

  return getPercent(Number(val), obj.sum.engine_time, 1);
};

function displayDailyDetail(index, key, interval) {
  const paramKey = key;
  const nextParamKey = SCALE[key][index].name;

  detailOptions.value.isShow = true;

  detailOptions.value.oid = props.object.id;
  detailOptions.value.name = props.object.name;

  detailOptions.value.paramKey = paramKey;
  detailOptions.value.nextParamKey = nextParamKey;

  if (interval) {
    detailOptions.value.from = interval.from;
    detailOptions.value.to = interval.to;
  } else {
    detailOptions.value.from = props.interval.from;
    detailOptions.value.to = props.interval.to;
  }

  const col = DAILY_DETAIL_COLUMNS.find((col) => col.name === key);

  detailOptions.value.paramTitle = `${col.label
    .replaceAll(/\(.*\)/gi, '')
    .replaceAll('<br>', '')} (${SCALE[key][index].label})`;
}
</script>

<style lang="scss"></style>
