import { shiftWorkTemplateHtml } from '../../App/TemplateComponents/ShiftWork/shiftWorkTemplateHtml.js';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';

const SECONDS_BY_DAY = 86400000;

export let shiftWork_template = {
  SUMM_AREA_ID: 'section-reports',
  Report: {}, // настройки отчета
  isConsoleLog: false, // писать логи в консоль (для отладки)
  // showHiddenWindow: () => {}, // тут будет вставлена функция
  ourAggregated: {}, // тут будет ourAggregated класс

  onclick: function (event) {
    this.submit();
  },

  submit: function () {
    // нажатие на кнопку в форме настройки отчета

    // shiftWork_template.showHiddenWindow('none', settingId);

    let button = document.getElementById(
      'shiftWork-template-setting-window-get-template-button',
    );
    let targetSpan = getNeighborByClass_helper(button, 'target-show-span');

    infoShowText_helper(
      targetSpan,
      'Обсчет данных для получения отчета',
      this.SUMM_AREA_ID,
    );
    button.style.borderColor = '';
    button.style.color = '';

    let settingId = 'shiftWork-template-setting-window';
    let shortSettingId = 'shiftWork';
    let roundTo = 5; // округление по 5 минут

    let form_desc = shiftWork_template.ourAggregated.getFormDesc(roundTo);

    shiftWork_template.Report.begin = +form_desc['beginTime'];
    shiftWork_template.Report.end = +form_desc['endTime'];

    shiftWork_template.getReportSettings(shortSettingId);

    // вычисление смен для отчета
    shiftWork_template.smenas.getSmenasSetting(
      settingId,
      shortSettingId,
      this,
      roundTo,
    );

    if (!objectsListFormColorAndTarget_helper(form_desc)) {
      infoShowText_helper(
        targetSpan,
        'Не выбран ни один объект',
        this.SUMM_AREA_ID,
      );
      button.style.borderColor = 'yellow';
      button.style.color = 'yellow';
      return 'error';
    } else if (shiftWork_template.smenas.badSmenasFlag) {
      infoShowText_helper(
        targetSpan,
        'Смены должны быть заданы стык в стык',
        this.SUMM_AREA_ID,
      );
      button.style.borderColor = 'yellow';
      button.style.color = 'yellow';
      return 'error';
    } else {
      infoShowText_helper(
        targetSpan,
        'Обсчет данных для получения отчета',
        this.SUMM_AREA_ID,
      );
      button.style.borderColor = '';
      button.style.color = '';
    }

    fetchToTemplateService({
      templateName: 'shiftWork',
      form_desc,
      templateHtml: shiftWorkTemplateHtml,
      options: {
        option: 2,
        Report: shiftWork_template.Report,
        smenas: shiftWork_template.smenas,
      },
      areaId: this.SUMM_AREA_ID,
      errorCallback: shiftWork_template.requestData,
    });
  },

  requestData() {
    const roundTo = 5;
    const form_desc = shiftWork_template.ourAggregated.getFormDesc(roundTo);
    shiftWork_template.ourAggregated.callOurAggregated({
      smenasSeconds: shiftWork_template.smenas.forResponse,
      option: 2,
      templateName: 'shiftWork',
      templateHtml: shiftWorkTemplateHtml,
      shiftWorkWorkerData: {
        Report: shiftWork_template.Report,
        smenas: shiftWork_template.smenas,
      },
      form_desc: form_desc,
      roundTo: roundTo,
      areaId: 'section-reports',
    });
  },

  getReportSettings: function (shortSettingId) {
    let columnsArr = [
      'client',
      'endSmena',
      'distIgn',
      'distIgnOff',
      'can_dist',
      'can_dist_deviation',
      'beginLevel',
      'endLevel',
      'fuelIn',
      'fuelOut',
      'fuelExpence',
      'can_expence',
      'can_expence_deviation',
      'fuelExpenceAvgLHour',
      'expenceMove',
      'expenceAktiv',
      'expenceIdle',
      'expenceIdlePercent',
      'avgExpenceL100km',
      'avgExpenceMoveL100km',
      'avgExpenceMoveLHour',
      'avgExpenceAktivLHour',
      'avgExpenceParkLHour',
      'avgSpeed',
      'maxSpeed',
      'engineWork',
      'engineWorkMove',
      'engineWorkAktiv',
      'engineWorkPark',
      'moto2',
      'expenceMoto2',
      'dataReceivePercent',
      'faultDUT',
      'lastPwr',
      'lastRealTime',
    ];

    this.Report.columns = {};
    for (let c in columnsArr) {
      let arrName = columnsArr[c];
      this.Report.columns[arrName] = document.getElementById(
        shortSettingId + '-cols-' + arrName,
      ).checked;
    }

    this.Report.settings = {};
    this.Report.settings['total'] = document.getElementById(
      shortSettingId + '-' + 'total',
    ).checked;
    this.Report.settings['isDetail'] = document.getElementById(
      shortSettingId + '-' + 'isDetail',
    ).checked;
    this.Report.settings['sortBy'] = document.getElementById(
      shortSettingId + '-' + 'sortBy',
    ).value;
  },

  smenas: {
    forResponse: [],
    smenasSetting: [],
    setSmenas: {},
    smenasFlag: false,
    breakdown: false,
    badSmenasFlag: true,

    getSmenasSetting: function (
      settingId,
      shortSettingId,
      ThisReport,
      roundTo,
    ) {
      this.smenasFlag = document.getElementById(
        shortSettingId + '-SmenasFlag',
      ).checked;
      this.breakdown = document.getElementById(
        shortSettingId + '-Breakdown',
      ).checked;

      if (!this.smenasFlag) {
        this.smenasSetting = [];
        // вычисление периодов
        this.getSmenas(this.smenasFlag, [], ThisReport);
      }

      let ssSetting = [];
      let sSetting = {};

      for (let s = 1; s < 4; s++) {
        if (!document.getElementById(shortSettingId + '-s' + s).checked) break;

        let sBeginHours = document.getElementById(
          shortSettingId + '-s' + s + '-bh',
        ).value;
        let sBeginMinutes = document.getElementById(
          shortSettingId + '-s' + s + '-bm',
        ).value;
        let sEndHours = document.getElementById(
          shortSettingId + '-s' + s + '-eh',
        ).value;
        let sEndMinutes = document.getElementById(
          shortSettingId + '-s' + s + '-em',
        ).value;

        sSetting['begin'] = this.getSmenasSetting_pice(
          sBeginHours,
          sBeginMinutes,
          roundTo,
        );
        sSetting['end'] = this.getSmenasSetting_pice(
          sEndHours,
          sEndMinutes,
          roundTo,
        );
        ssSetting.push(sSetting);

        for (let key in sSetting) {
          if (!this.smenasFlag) {
            break;
          }
          let secondsOfTheDay = this.getSmenas_getTime(sSetting[key]) / 1000;
          if (
            secondsOfTheDay > 0 &&
            secondsOfTheDay != 86400 &&
            !in_array_helper(secondsOfTheDay, this.forResponse)
          ) {
            this.forResponse.push(secondsOfTheDay);
          }
        }

        sSetting = {};
      }

      this.smenasSetting = ssSetting;

      // вычисление периодов
      this.getSmenas(this.smenasFlag, ssSetting, ThisReport);
    },

    getSmenasSetting_pice: function (hours, minutes, roundTo) {
      roundTo = roundTo || 10;

      if (!hours && !minutes) {
        return '00:00';
      }

      if (+hours > 23 || +hours < 0) hours = '00';
      if (!minutes) {
        return hours + ':00';
      }

      if (+minutes > 60 || +minutes < 0) {
        return hours + ':' + '00';
      }

      let deciMinute = minutes.charAt(0) || '0';

      if (roundTo == 10) {
        // минута всегда будет нулевой
        return hours + ':' + deciMinute + '0'; // минуты по 10 минут допускаются
      }

      let minute = minutes.charAt(1) || '0';

      // roundTo = 5
      if (+minute < 5) {
        return hours + ':' + deciMinute + '0'; // минуты по 5 минут допускаются, но тут округление
      }
      return hours + ':' + deciMinute + '5'; // минуты по 5 минут допускаются
    },

    getSmenas_getTime: function (timeStroke) {
      // seconds from begin day (secons of a day)
      return (
        (Number(timeStroke.split(':')[0]) * 60 +
          Number(timeStroke.split(':')[1])) *
        60000
      ); // в милисекундах
    },

    getSmenas_createPeriod: function (v_time, arrSmena, idxWorkTime) {
      var Period = {};
      if (v_time) {
        Period.TimeBegin = new Date(
          Number(v_time.toOnlyDay()) + arrSmena[idxWorkTime].TimeBegin,
        );
        Period.TimeEnd = new Date(
          Number(v_time.toOnlyDay()) + arrSmena[idxWorkTime].TimeEnd,
        );
      }
      Period.nSmena = idxWorkTime;
      Period.isWebOpened = false;
      Period.isWebClosed = false;
      Period.webBegin = {};
      Period.webEnd = {};
      Period.webSumm = {};
      return Period;
    },

    getSmenas: function (smenasFlag, smenasSetting, ThisReport) {
      /* получение смен за весь период */

      function getFirstSmenaIdx_checkBeginInSmena(
        reportBegin,
        timeSmenaBegin,
        timeSmenaEnd,
      ) {
        return Boolean(
          reportBegin >= timeSmenaBegin && reportBegin < timeSmenaEnd,
        );
      }

      function getFirstSmenaIdx(startDayOfSmenas, arrSmena, ThisReport) {
        // оставляем внутри getSmenas
        if (!WorkTimesCnt) {
          return -1;
        }

        let i;
        let arrSmenaTimes = [];
        let reportBegin = ThisReport.Report.begin;

        for (i = 0; i < WorkTimesCnt; i++) {
          let timeBegin = arrSmena[i].TimeBegin + startDayOfSmenas;
          let timeEnd = arrSmena[i].TimeEnd + startDayOfSmenas;

          if (timeBegin >= timeEnd) {
            timeEnd += SECONDS_BY_DAY;

            if (
              getFirstSmenaIdx_checkBeginInSmena(
                reportBegin,
                timeBegin - SECONDS_BY_DAY,
                timeEnd - SECONDS_BY_DAY,
              )
            ) {
              // в этой смене начинается отчет, но с переводом на сутки назад
              CurWorkTimeBegin = reportBegin;
              CurWorkTimeEnd = timeEnd - SECONDS_BY_DAY;
              return i;
            }
          }

          if (
            getFirstSmenaIdx_checkBeginInSmena(reportBegin, timeBegin, timeEnd)
          ) {
            // в этой смене начинается отчет
            CurWorkTimeBegin = reportBegin;
            CurWorkTimeEnd = timeEnd;
            return i;
          }

          arrSmenaTimes[i] = {
            timeBegin: timeBegin,
            timeEnd: timeEnd,
            nSmena: i,
          };
        }

        // отчет не начинается ни в одной из заданных смен
        arrSmenaTimes.sort(function (a, b) {
          return a.TimeBegin - b.TimeBegin;
        });

        for (i = 0; i < WorkTimesCnt; i++) {
          if (arrSmenaTimes[i].timeBegin > reportBegin) {
            CurWorkTimeBegin = arrSmenaTimes[i].timeBegin;
            CurWorkTimeEnd = arrSmenaTimes[i].timeEnd;
            return arrSmenaTimes[i].nSmena;
          }
        }

        // в этом дне нет смен, нужно брать первую смену следующего дня (в которой начало смены лежит в следующем дне)
        CurWorkTimeBegin = arrSmenaTimes[0].timeBegin + SECONDS_BY_DAY;
        CurWorkTimeEnd = arrSmenaTimes[0].timeEnd + SECONDS_BY_DAY;
        return arrSmenaTimes[0].nSmena;
      }

      this.badSmenasFlag = true;
      this.setSmenas = {};

      let WorkTimesCnt = 1; // одна смена - минимум
      let arrSmena = [];

      if (smenasFlag && 1 in smenasSetting) {
        // две смены точно есть
        if (2 in smenasSetting) {
          // установлены три смены
          WorkTimesCnt = 3;
        } else {
          WorkTimesCnt = 2;
        }
      }

      let Smena = {};

      for (let s = 0; s < WorkTimesCnt; s++) {
        Smena = {};
        Smena.TimeBegin =
          smenasFlag && s in smenasSetting
            ? this.getSmenas_getTime(smenasSetting[s]['begin'])
            : 0;
        Smena.TimeEnd =
          smenasFlag && s in smenasSetting
            ? this.getSmenas_getTime(smenasSetting[s]['end'])
            : 86400 * 1000;
        arrSmena.push(Smena);
      }

      let CurWorkTimeBegin;
      let CurWorkTimeEnd;
      let Periods = [];
      let idxWorkTime = 0;
      let idxWorkTimeNext;
      let Period = {};
      let j = 1;

      //находим первую полную смену за период
      let startDayOfReport = Number(
        new Date(ThisReport.Report.begin).toOnlyDay(),
      );

      idxWorkTime = getFirstSmenaIdx(startDayOfReport, arrSmena, ThisReport);

      if (idxWorkTime < 0) {
        // в этих сутках нет смены - берем следующие
        idxWorkTime = getFirstSmenaIdx(
          startDayOfReport + 86400000,
          arrSmena,
          ThisReport,
        );
      }

      //раскладка смен на весь период
      var cntSP = 0;

      Period = this.getSmenas_createPeriod(false, arrSmena, idxWorkTime);
      Period.TimeBegin = new Date(CurWorkTimeBegin);
      Period.TimeEnd = new Date(CurWorkTimeEnd);
      Periods[0] = Period;

      let v_time;

      while (Period.TimeEnd < ThisReport.Report.end) {
        cntSP++;

        if (
          WorkTimesCnt == 1 &&
          arrSmena[idxWorkTime].TimeEnd > arrSmena[idxWorkTime].TimeBegin
        ) {
          // v_time = new Date(Number(Periods[cntSP - 1].TimeEnd.toOnlyDay()) + 24 * 3600 * 1000);
          const onlyDayTimeStamp = Number(
            Periods[cntSP - 1].TimeEnd.toOnlyDay(),
          );
          v_time = new Date(onlyDayTimeStamp);

          Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);

          // 241120
          if (+Periods[cntSP - 1].TimeEnd === +Period.TimeEnd) {
            v_time = new Date(86400000 + onlyDayTimeStamp);
            Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);
          }
        } else {
          idxWorkTimeNext =
            idxWorkTime < WorkTimesCnt - 1 ? idxWorkTime + 1 : 0;
          idxWorkTime = idxWorkTimeNext;
          v_time = Periods[cntSP - 1].TimeEnd.toOnlyDay();

          Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);

          if (Period.TimeEnd <= Period.TimeBegin) {
            Period.TimeEnd = new Date(
              Number(Period.TimeEnd) + 24 * 3600 * 1000,
            );
          }

          var maxSmenasCol = 3;
          var cnt = 0;
          while (cnt < maxSmenasCol) {
            cnt++;
            if (cntSP - cnt < 0) {
              break;
            }
            if (
              +Periods[cntSP - cnt].TimeEnd == +Period.TimeEnd &&
              +Periods[cntSP - cnt].TimeBegin == +Period.TimeBegin
            ) {
              // такая смена уже есть - нужно перелистнуть сутки
              Period.TimeEnd = new Date(
                Number(Period.TimeEnd) + 24 * 3600 * 1000,
              );
              Period.TimeBegin = new Date(
                Number(Period.TimeBegin) + 24 * 3600 * 1000,
              );
              break;
            }
          }
        }

        if (Period.TimeBegin < ThisReport.Report.end) {
          Periods[cntSP] = Period;
        } else {
          // 241120
          cntSP--;
        }
      }

      // 241120
      if (Periods[cntSP].TimeBegin >= ThisReport.Report.end) {
        Periods.splice(cntSP, 1);
      }

      var smenas = [];
      var smena_name;
      if (smenasFlag) {
        for (let z = 0; z < WorkTimesCnt; z++) {
          smena_name =
            'Смена' +
            (z + 1) +
            ' (' +
            smenasSetting[z]['begin'] +
            ' - ' +
            smenasSetting[z]['end'] +
            ')';
          smenas.push(smena_name);
        }
      } else {
        smena_name =
          'Расчет ведется по суткам (сменой считаются границы суток с 00:00:00 до 23:59:59)';
        smenas.push(smena_name);
      }

      this.setSmenas = {
        Periods: Periods,
        smenas: smenas,
        arrSmena: arrSmena,
        WorkTimesCnt: WorkTimesCnt,
      };

      // корректировка первого и посленего периода по периоду отчета при необходимости
      if (Periods.length) {
        if (+Periods[0].TimeBegin < ThisReport.Report.begin) {
          // нужно проверить можно ли этот интервал привязать к какой-нибудь из смен
          Periods[0].TimeBegin = new Date(+ThisReport.Report.begin);
        }

        if (+Periods[Periods.length - 1].TimeEnd > ThisReport.Report.end) {
          Periods[Periods.length - 1].TimeEnd = new Date(
            +ThisReport.Report.end,
          );
        }
      }
      //проверка раскладки смен (цикл ниже)
      this.badSmenasFlag = false;
      for (let i = 0; i < Periods.length - 1; i++) {
        const isTimeEndMoreThanNextTimeBegin =
          Periods[i].TimeEnd > Periods[i + 1].TimeBegin;

        const smenasAreNotJointToJoint =
          +Periods[i].TimeEnd != +Periods[i + 1].TimeBegin;

        if (isTimeEndMoreThanNextTimeBegin || smenasAreNotJointToJoint) {
          this.badSmenasFlag = true;
          break;
        }
      }
    },
  },
};
