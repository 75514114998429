<template>
  <div
    ref="mainRef"
    v-if="isMainShow"
  >
    <button
      class="not-print float-left"
      onclick="html_to_excel_helper.export( this, 'clients_monthly_template', 'Статистика по организациям', 'portrait' )"
    >
      Экспорт&nbsp;в&nbsp;EXCEL
    </button>
    <div
      class="temlate-report"
      id="clients_monthly_template"
    >
      <h6 class="text-center font-weight-bold">
        Статистика нарушений на 100 км пробега
      </h6>
      <table class="table-simple">
        <thead v-html="getTheadTemplate()"></thead>
        <tbody>
          <tr
            v-for="clientForQuery in clientsForQuery.concat(summRow)"
            :key="clientForQuery.index || 'summ'"
          >
            <td
              v-for="column in $options.columns"
              :key="column"
              class="text-center"
              data-excelalignh="center"
            >
              {{ clientForQuery[column] }}
            </td>

            <template v-for="summInterval in clientForQuery.summ">
              <template
                v-for="intervalColumn in $options.intervalColumns"
                :key="intervalColumn"
              >
                <td
                  v-if="
                    $options.headers[intervalColumn].isShowDetailButton ?? false
                  "
                  :data-formatnum="$options.headers[intervalColumn].formatnum"
                  class="text-center my-underline"
                  data-excelalignh="center"
                  @click="showDetail(summInterval)"
                >
                  {{ summInterval[intervalColumn] }}
                </td>
                <td
                  v-else
                  :data-formatnum="$options.headers[intervalColumn].formatnum"
                  class="text-center"
                  data-excelalignh="center"
                >
                  {{
                    formatToDisplay_helper(
                      summInterval[intervalColumn],
                      $options.headers[intervalColumn].formatnum,
                    )
                  }}
                </td>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <clients-monthly-detail
      v-if="isShowDetail"
      :summInterval="detailData"
      @close="isShowDetail = false"
    />
  </div>
</template>

<script>
import { ClientsMonthlyTemplateSettings } from './clients_monthly_template_settings.js';
import { skillsManTemplate } from '../../../Template/skills_man_template/skills_man_template.js';
import { globalObjects } from '../../../src/dataRequest/mainScript.js';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';
const url_get_aggregated = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/getAgregationPositions`;
const POSES_COUNT_OBJECT_WAS_ONLINE = 1;

import ClientsMonthlyDetail from './ClientsMonthlyDetail.vue';
import fetchJson from '../../../src/dataRequest/fetch-json.js';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService';

// getRelativeValue_helper
// myRoundNumber_helper
// formatToDisplay_helper
// isNumber_helper

export default {
  components: {
    ClientsMonthlyDetail,
  },
  props: {
    trigger: {
      type: Boolean,
      require: true,
    },
  },
  SUMM_AREA_ID: 'section-reports',
  inject: ['globalObjectsList'],
  data() {
    return {
      isMainShow: false,
      submit: null,
      clientsMonthlyTemplateSettings: null,
      clientsForQuery: [],
      summRow: [],
      areaId: null,
      beginTimeElement: null,
      endTimeElement: null,
      queryIntervals: null,
      // queryTimeInterval: {
      //   begin: new Date(),
      //   end: new Date(),
      // },
      endTime: new Date(),
      button: null,
      buttonTarget: null,
      objectList: null,
      skillsManTemplate: null,
      hostname: null,
      protocol: null,
      detailData: {},
      isShowDetail: false,
    };
  },
  mounted() {
    this.clientsMonthlyTemplateSettings = new ClientsMonthlyTemplateSettings();
    // this.areaId = areaId;
    this.templateContentElement = document.getElementById(
      this.$options.SUMM_AREA_ID,
    );
    // this.beginTimeElement = document.getElementsByClassName(
    //   'objectsListForm-beginTime',
    // )[0];
    // this.endTimeElement = document.getElementsByClassName(
    //   'objectsListForm-endTime',
    // )[0];
    this.button = document.getElementsByClassName('get-template-button-id')[0];
    this.buttonTarget = document.getElementsByClassName(
      'get-template-button-target-id',
    )[0];
    this.objectList = document.getElementsByClassName('objectsListId')[0];
    this.skillsManTemplate = skillsManTemplate;
    this.hostname = location.hostname;
    this.protocol = location.protocol;
  },
  watch: {
    trigger() {
      this.clientsMonthlyTemplateSettings.setClients({
        clients: this.setClients,
      });

      this.showSettings();
    },
  },
  computed: {
    setClients() {
      const clients = [];
      const globalObjectsListKeys = Object.keys(this.globalObjectsList);
      const objClients = globalObjectsListKeys.reduce((accum, key) => {
        accum.push(this.globalObjectsList[key].client);
        return accum;
      }, []);
      const clientsDictionary = {};

      objClients.forEach((objClient) => {
        const client = objClient;
        const cIndex = (clientsDictionary[client] ?? 0) - 1;

        if (cIndex > -1) {
          clients[cIndex]['count']++;
        } else {
          clientsDictionary[client] = clients.push({ name: client, count: 1 });
        }
      });

      clients.sort((a, b) => a['name'].localeCompare(b['name']));
      return clients;
    },
  },
  methods: {
    formatToDisplay_helper(value, formatNum) {
      return formatToDisplay_helper(value, formatNum).replaceAll(
        '&nbsp;',
        '\xA0',
      );
    },
    showDetail(summInterval) {
      this.detailData = summInterval;
      this.isShowDetail = true;
    },
    showSettings() {
      this.clientsMonthlyTemplateSettings.showSettings();

      if (!this.submit) {
        const { submit, submitInfo } =
          this.clientsMonthlyTemplateSettings.subelements;
        submit.addEventListener('pointerdown', {
          handleEvent: this.submitEvent,
          that: this,
        });
        this.submit = submit;
        this.submitInfo = submitInfo;
      }

      this.clientsMonthlyTemplateSettings.subelements.submitInfo.textContent =
        '';
    },

    submitEvent(event) {
      event.preventDefault();
      this.submitFunction();
    },

    async submitFunction() {
      this.submitInfo.textContent = '';
      this.button.style.borderColor = 'yellow';

      const interval = this.getTimeInterval();

      if (!interval) {
        return;
      }

      const { queryIntervals, begin, end } = interval;
      const clientsForQuery = this.getClientsForQuery(queryIntervals);

      if (!clientsForQuery) {
        return;
      }

      this.clientsMonthlyTemplateSettings.closeSettings();

      const intervalsCount = queryIntervals.length;

      this.queryIntervals = queryIntervals;

      this.getDataAndCalculate(clientsForQuery, intervalsCount, { begin, end });

      // const clients = await this.getDataAndCalculate(
      //   clientsForQuery,
      //   intervalsCount,
      //   { begin, end },
      // );

      // if (!clients) {
      //   // ошибка в однм из запросов
      //   this.button.style.borderColor = 'red';
      //   infoShowText_helper(
      //     this.buttonTarget,
      //     `Произошла ошибка`,
      //     this.$options.SUMM_AREA_ID,
      //   );
      // } else {
      //   const summRow = this.summRowCalculate(clients, queryIntervals);
      //   this.clientsForQuery = clients;
      //   this.summRow = summRow;
      //   this.queryIntervals = queryIntervals;

      //   // включаем отображене - вью наполнит данными
      //   this.templateContentElement.innerText = '';
      //   this.isMainShow = true;
      //   this.$nextTick(() => {
      //     // переносим в ID где отчеты
      //     this.templateContentElement.append(this.$refs.mainRef);
      //     infoShowText_helper(
      //       this.buttonTarget,
      //       `Отчет построен. Выполнить новый запрос?`,
      //     );
      //     this.button.style.color = 'green';
      //     this.button.style.borderColor = 'green';
      //   });
      // }
    },

    summRowCalculate(clientsForQuery, queryIntervals) {
      // суммарная строка по всем клиентам
      const summForSummRow = queryIntervals.map((queryInterval) => {
        return {
          begin: queryInterval.begin,
          end: queryInterval.end,
          distance: 0,
          canExpence100km: 0,
          canExpence: 0,
          distanceForCanExpence: 0,
          forfeits: 0,
          forfeitsRelative: 0,
          objectCountInMonth: 0,
          objectCountWasOnline: 0,
          objectCountWasOffline: 0,
          objects: [],
        };
      });

      const summRow = {
        client: 'Сумма',
        index: '',
        objectsCount: 0,
        summ: summForSummRow,
      };

      clientsForQuery.forEach((clientForQuery) => {
        const { objectsCount, summ } = clientForQuery;
        summRow.objectsCount += objectsCount;

        summ.forEach((summInterval, index) => {
          const {
            distance,
            forfeits,
            canExpence,
            distanceForCanExpence,
            objectCountInMonth,
            objectCountWasOnline,
            objectCountWasOffline,
            objects,
          } = summInterval;

          summForSummRow[index].objects =
            summForSummRow[index].objects.concat(objects);
          summForSummRow[index].intervalIndex = index;
          summForSummRow[index].distance += isNumber_helper(distance)
            ? distance
            : 0;
          summForSummRow[index].forfeits += isNumber_helper(forfeits)
            ? forfeits
            : 0;
          summForSummRow[index].canExpence += isNumber_helper(canExpence)
            ? canExpence
            : 0;
          summForSummRow[index].distanceForCanExpence += isNumber_helper(
            distanceForCanExpence,
          )
            ? distanceForCanExpence
            : 0;
          summForSummRow[index].objectCountWasOnline += objectCountWasOnline;
          summForSummRow[index].objectCountWasOffline += objectCountWasOffline;
          summForSummRow[index].objectCountInMonth +=
            objectCountWasOnline + objectCountWasOffline;
        });
      });

      summForSummRow.forEach((summInterval, index) => {
        summForSummRow[index].forfeitsRelative = getRelativeValue_helper(
          'forfeitsRelative',
          summInterval,
        );
        summForSummRow[index].canExpence100km = getRelativeValue_helper(
          'canExpence100km',
          {
            distance: summInterval.distanceForCanExpence,
            canExpence: summInterval.canExpence,
          },
        );
      });

      return summRow;
    },

    async getDataStepByStep(clientsForQuery, intervalsCount) {
      // clientsForQuery будет содержать данные
      const queryCount = clientsForQuery.reduce((acc, clientForQuery) => {
        const { objects } = clientForQuery;
        acc += objects.length * intervalsCount;
        return acc;
      }, 0);

      const isGetSmenas = false,
        smenasOrigin = [];
      let queriesData = [];
      let workerResponceCount = 0;

      const worker = new CalculateRequestDataworker();

      worker.addEventListener('message', (e) => {
        workerResponceCount++;
        const queryData = queriesData[e.data.sendedCount];
        const {
          realPosCnt,
          rows_experiment = { canExpence: { val: { val: 0 } } },
          rows_summ,
        } = e.data.objectCalculated;
        const canExpence = rows_experiment.canExpence.val.val ?? 0;

        const { DDist: distance, forfeits } = rows_summ.lines[0][0];

        queryData.interval.isResponced = true;
        queryData.interval.realPosCnt = realPosCnt;
        queryData.interval.distance = distance;
        queryData.interval.canExpence = canExpence;
        queryData.interval.forfeits = forfeits;
        queryData.interval.isCalculated = true;
      });

      worker.addEventListener('error', ({ message }) => {
        console.error(message);
        worker.terminate();
      });

      // есть queriesData
      // нужно создать сначала 10 fetch запросов
      // потом при ответе на один запрос создавать еще запрос
      // так до тех пор, пока все запросы не закончатся
      // а если один из них завершится ошикой, то и другие не создавать

      const fetcher = {
        isError: false,
        isDone: false,
        queryCount,
        queryIndex: 0,
        sendedCount: 0,
        responsesCount: 0,
        maxTogetherCount: 8,
        checkAndGetFetch: async (fetcher, resolve, reject) => {
          const {
            queryCount,
            sendedCount,
            responsesCount,
            maxTogetherCount,
            isError,
          } = fetcher;
          if (isError) {
            reject();
            return;
          }
          if (responsesCount === queryCount) {
            while (workerResponceCount !== queryCount) {
              await new Promise((res) => setTimeout(res, 500));
            }
            worker.terminate();
            fetcher.isDone = true;
            resolve();
          }
          if (
            sendedCount - responsesCount < maxTogetherCount &&
            sendedCount < queryCount
          ) {
            const queryData = queriesData[sendedCount];
            fetcher.sendedCount++;
            if (queryData.isGetFlag) {
              this.getData(queryData, fetcher)
                .then((res) => {
                  // при выполеннии одного из запросов
                  worker.postMessage({
                    templateName: 'clientsMonthly',
                    data: {
                      sendedCount,
                      calculated: {
                        REPORT: res,
                        ReportBegin: new Date(queryData.begin.valueOf()),
                        ReportEnd: new Date(queryData.end.valueOf()),
                        stateNumber: queryData.stateNumber,
                        model: queryData.model,
                        objs: [
                          {
                            name: queryData.name,
                            avtoNo: queryData.stateNumber,
                            model: queryData.model,
                          },
                        ],
                        //   fetcher.queryIndex,
                        curIndex: 0, // индекс объекта в обсчете, всегда один объект
                        isGetSmenas,
                        smenasOrigin,
                      },
                    },
                  });

                  fetcher.responsesCount++;
                  fetcher.checkAndGetFetch(fetcher, resolve, reject);
                })
                .catch((e) => {
                  fetcher.isError = true;
                  reject();
                  // fetcher.checkAndGetFetch(fetcher, resolve, reject);
                });
            } else {
              fetcher.responsesCount++;
            }
            // и при добавлении одного запроса
            fetcher.checkAndGetFetch(fetcher, resolve, reject);
          }
        },
      };

      queriesData = this.queriesPrepare(clientsForQuery, fetcher);

      await new Promise((resolve, reject) => {
        fetcher.checkAndGetFetch(fetcher, resolve, reject);
      });

      fetcher.queriesData = queriesData;

      return clientsForQuery;
    },

    async getDataAndCalculate(clientsForQuery, intervalsCount, { begin, end }) {
      infoShowText_helper(
        this.buttonTarget,
        `Отправляю запрос...`,
        this.$options.SUMM_AREA_ID,
      );

      const objects = clientsForQuery.reduce(
        (acc, client) => acc.concat(client.objects),
        [],
      );
      const objIdsArr = objects.map((obj) => obj.id);

      fetchToTemplateService({
        templateName: 'clients_monthly_template',
        form_desc: {
          objects: objIdsArr,
          beginTime: begin,
          endTime: end,
        },
        options: { clientsForQuery, intervalsCount },
        areaId: this.$options.SUMM_AREA_ID,
        successCallback: this.displayReport,
        errorCallback: async () => {
          try {
            await this.getDataStepByStep(clientsForQuery, intervalsCount);
          } catch (e) {
            console.error(e);
          }

          this.displayReport(clientsForQuery);
        },
      });
    },

    displayReport(clientsForQuery) {
      const clients = this.summCalculate(clientsForQuery);

      if (!clients) {
        // ошибка в однм из запросов
        this.button.style.borderColor = 'red';
        infoShowText_helper(
          this.buttonTarget,
          `Произошла ошибка`,
          this.$options.SUMM_AREA_ID,
        );
      } else {
        const queryIntervals = this.queryIntervals;
        const summRow = this.summRowCalculate(clients, queryIntervals);
        this.clientsForQuery = clients;
        this.summRow = summRow;
        this.queryIntervals = queryIntervals;

        // включаем отображене - вью наполнит данными
        this.templateContentElement.innerText = '';
        this.isMainShow = true;
        this.$nextTick(() => {
          // переносим в ID где отчеты
          this.templateContentElement.append(this.$refs.mainRef);
          infoShowText_helper(
            this.buttonTarget,
            `Отчет построен. Выполнить новый запрос?`,
          );
          this.button.style.color = 'green';
          this.button.style.borderColor = 'green';
        });
      }
    },

    summCalculate(clientsForQuery) {
      clientsForQuery.forEach((clientForQuery) => {
        // client
        const { objects, summ } = clientForQuery;

        objects.forEach((object) => {
          // сумма по клиенту - пробег и прочие
          this.summObjectCalculate(object, summ);
        });

        for (var idx = 0; idx < summ.length; idx++) {
          // сумма по клиенту производные - расход на 100 км и прочие
          const summInterval = summ[idx];
          if (summInterval.error) {
            summInterval.forfeitsRelative = 'ошибка запроса';
            summInterval.canExpence100km = 'ошибка запроса';
            summInterval.isCalculated = 'ошибка запроса';
          }
          const forfeitsRelativeIntervalSumm = getRelativeValue_helper(
            'forfeitsRelative',
            summInterval,
          );
          const canExpence100km = getRelativeValue_helper('canExpence100km', {
            distance: summInterval.distanceForCanExpence,
            canExpence: summInterval.canExpence,
          });
          summInterval.forfeitsRelative = myRoundNumber_helper(
            forfeitsRelativeIntervalSumm,
            2,
          );
          summInterval.canExpence100km = myRoundNumber_helper(
            canExpence100km,
            2,
          );
          summInterval.isCalculated = true;
        }
      });
      return clientsForQuery;
    },

    summObjectCalculate(object, summ) {
      const { intervals } = object;

      object.summ = {
        distance: 0,
        forfeits: 0,
        canExpence: 0,
        distanceForCanExpence: 0,
        isCalculated: false,
      };

      const objSumm = object.summ;

      for (
        var intervalIndex = 0;
        intervalIndex < intervals.length;
        intervalIndex++
      ) {
        // interval это сумма по одному объекту заодин месяц
        const interval = intervals[intervalIndex];
        const {
          distance,
          forfeits,
          canExpence,
          realPosCnt,
          isInstallTimeLater,
          isResponced,
        } = interval;
        // summInterval это сумма по одному месяцу по всем объектам у одного клиента
        const summInterval = summ[intervalIndex];

        if (!isInstallTimeLater) {
          const isWasOnline = realPosCnt >= POSES_COUNT_OBJECT_WAS_ONLINE;
          object.isWasOnline = isWasOnline;
          object.intervals[intervalIndex].isWasOnline = isWasOnline;
          summInterval.objects.push(object);
          summInterval.intervalIndex = intervalIndex;
          summInterval.objectCountInMonth++;
          if (isWasOnline) {
            summInterval.objectCountWasOnline++;
          } else {
            summInterval.objectCountWasOffline++;
          }
        }

        if (!isResponced) {
          summ[intervalIndex].error = true;

          const errorText = 'ошибка запроса';
          objSumm.distance = errorText;
          summInterval.distance = errorText;
          objSumm.forfeits = errorText;
          summInterval.forfeits = errorText;
          objSumm.canExpence = errorText;
          summInterval.canExpence = errorText;
          objSumm.distanceForCanExpence = errorText;
          summInterval.distanceForCanExpence = errorText;
          break;
        }

        const isDistanceNumber = isNumber_helper(distance);

        if (isDistanceNumber) {
          objSumm.distance += distance;
          summInterval.distance += distance;
        }

        if (isNumber_helper(forfeits)) {
          objSumm.forfeits += forfeits;
          summInterval.forfeits += forfeits;
        }

        if (isDistanceNumber && isNumber_helper(canExpence) && canExpence > 0) {
          objSumm.canExpence += canExpence;
          summInterval.canExpence += canExpence;

          objSumm.distanceForCanExpence += distance;
          summInterval.distanceForCanExpence += distance;
        }
      }

      objSumm.isCalculated = true;
    },

    queriesPrepare(clientsForQuery, fetcher = {}) {
      const queriesData = clientsForQuery.reduce((acc, clientForQuery) => {
        this.prepareQueriesData(acc, { clientForQuery, fetcher });
        return acc;
      }, []);

      return queriesData;
    },

    prepareQueriesData(queriesData, { clientForQuery, fetcher = {} }) {
      // вместо addFetchPromises
      const { objects } = clientForQuery;
      const buttonTarget = this.buttonTarget;
      const areaId = this.$options.SUMM_AREA_ID;
      const { hostname, protocol } = this;

      let isGetFlag;

      objects.forEach((object) => {
        const { id, name, model, stateNumber, intervals, installTime } = object;

        intervals.forEach((interval) => {
          const { begin, end } = interval;

          if (installTime > end.getTime() / 1000) {
            isGetFlag = false;
            interval.isResponced = true;
            interval.realPosCnt = 0;
            interval.distance = 0;
            interval.canExpence = 0;
            interval.forfeits = 0;
            interval.isCalculated = true;
            interval.isInstallTimeLater = true;
          } else {
            isGetFlag = true;
            interval.isResponced = false;
            interval.isInstallTimeLater = false;
            interval.queryIndex = fetcher.queryIndex;
            fetcher.queryIndex++;
          }

          queriesData.push({
            isGetFlag,
            interval,
            protocol,
            hostname,
            id,
            begin,
            end,
            fetcher,
            buttonTarget,
            areaId,
            stateNumber,
            model,
            name,
          });
        });
      });
    },

    async getData(
      {
        interval,
        protocol,
        hostname,
        id,
        begin,
        end,
        buttonTarget,
        areaId,
        stateNumber,
        model,
        name,
      } = {},
      fetcher,
    ) {
      return new Promise((resolve, reject) => {
        try {
          // const url = new URL(`${protocol}//${hostname}${url_get_aggregated}`);
          const url = new URL(
            url_get_aggregated,
            `http://${document.location.host}`,
          );
          // http://server-v2.part2/Api/getAgregationPositions?id=51889&time_begin=1606762800&time_end=1614496800&option=2&template_name=skillsMan
          url.searchParams.append('id', id);
          url.searchParams.append('time_begin', begin.valueOf() / 1000);
          url.searchParams.append('time_end', end.valueOf() / 1000);
          url.searchParams.append('option', 2);
          url.searchParams.append('template_name', 'clients_monthly_template');

          fetchJson(url, {})
            .then((REPORT) => {
              // fetcher.responsesCount++;
              const { responsesCount, queryCount } = fetcher;
              const percent = Math.round(
                (100 * (responsesCount + 1)) / queryCount,
              );

              infoShowText_helper(
                buttonTarget,
                `Получен ответ ${
                  responsesCount + 1
                } из ${queryCount} (${percent}%)`,
                areaId,
              );

              resolve(REPORT);
            })
            .catch((error) => {
              console.error(error.message);
              infoShowText_helper(
                buttonTarget,
                `При запросе данных произошла ошибка`,
                areaId,
              );
              reject();
            });
        } catch (error) {
          console.error(error.message);
          infoShowText_helper(
            buttonTarget,
            `При запросе данных произошла оибка`,
            areaId,
          );
          reject();
        }
      });
    },

    getClientsForQuery(queryIntervals) {
      const { settingClients, submitInfo } =
        this.clientsMonthlyTemplateSettings.subelements;
      const elements = settingClients.querySelectorAll('[data-client]');

      const clientsDict = {};

      const clients = [...elements].reduce((acc, element) => {
        if (element.checked) {
          const client = element.dataset.client;
          const summ = this.getPreparedIntervals(queryIntervals);
          clientsDict[client] = acc.push({ client, summ, objects: [] }) - 1;
        }
        return acc;
      }, []);

      if (!clients.length) {
        submitInfo.textContent = 'Не выбраны организации';
        return;
      }

      const rows = this.objectList.rows;
      const rowsCount = rows.length;

      for (let i = 1; i < rowsCount; i++) {
        const row = rows[i];
        const objClient = row.querySelector('.obj-client-search').textContent;

        if (!(objClient in clientsDict)) {
          continue;
        }

        const index = clientsDict[objClient];
        const id = row.id;
        const name = row.querySelector('.search-name').textContent;
        const stateNumber = row.querySelector(
          '.search-stateNumber',
        ).textContent;
        const model = row.querySelector('.obj-model-search').textContent;
        const installTime = Number(
          row.querySelector('.obj-install_time-search').dataset.installTime,
        );
        const vin = row.querySelector('.search-vin').textContent;

        const intervals = this.getPreparedIntervals(queryIntervals);

        clients[index].objects.push({
          id,
          name,
          model,
          stateNumber,
          intervals,
          installTime,
          vin,
          client: objClient,
        });
      }

      clients.forEach((client, index) => {
        const { objects } = client;
        client.index = index + 1;
        client.objectsCount = objects.length;
      });

      return clients;
    },

    getPreparedIntervals(queryIntervals) {
      return queryIntervals.map((queryInterval) => {
        const { begin, end } = queryInterval;
        return {
          begin,
          end,
          objectCountInMonth: 0,
          objectCountWasOnline: 0,
          objectCountWasOffline: 0,
          distance: 0,
          canExpence: 0,
          distanceForCanExpence: 0,
          forfeits: 0,
          isCalculated: false,
          objects: [],
        };
      });
    },

    getTimeInterval() {
      const { submitInfo, settingFullMonthOnly } =
        this.clientsMonthlyTemplateSettings.subelements;
      const { beginTimeElement, endTimeElement } = this;

      const timeBeginValue = parseInt(
        document.getElementById('beginTime').value,
      );
      const timeEndValue = parseInt(document.getElementById('endTime').value);

      if (!timeBeginValue) {
        submitInfo.textContent = 'Неверно указано дата/время начала отчета';
        return;
      }

      if (!timeEndValue) {
        submitInfo.textContent = 'Неверно указано дата/время окончания отчета';
        return;
      }

      if (timeBeginValue > timeEndValue) {
        submitInfo.textContent = 'Неверно указан временной интервал';
        return;
      }

      const interval = settingFullMonthOnly.checked
        ? {
            begin: this.getSharpMonth(timeBeginValue, true),
            end: this.getSharpMonth(timeEndValue, false),
          }
        : {
            begin: new Date(timeBeginValue),
            end: new Date(timeEndValue),
          };

      if (!(interval.begin < interval.end)) {
        submitInfo.textContent = 'Неверно указан временной интервал';
        return;
      }

      interval.queryIntervals = this.getQueryIntervals(interval);

      return interval;
    },

    getQueryIntervals(interval) {
      const { begin: queryBegin, end: queryEnd } = interval;
      const queryIntervals = [];

      let beginTimestamp = queryBegin.valueOf();

      for (let i = 0; i < 100; i++) {
        const begin = new Date(beginTimestamp);

        let end = this.setSharpMonth(new Date(begin));
        this.setNextMonth(end);

        beginTimestamp = end.valueOf();

        if (end - queryEnd > 0) {
          end = queryEnd;
        }

        if (end - begin > 0) {
          queryIntervals[i] = {
            begin,
            end,
          };
        } else {
          break;
        }
      }

      return queryIntervals;
    },

    setSharpMonth(date) {
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      return date;
    },

    setNextMonth(date) {
      const nextMonth = 1 + date.getMonth();

      if (nextMonth > 11) {
        date.setFullYear(1 + date.getFullYear());
        date.setMonth(0);
      } else {
        date.setMonth(nextMonth);
      }

      return date;
    },

    getSharpMonth(datetime, isNextMonth = false) {
      const time = new Date(datetime);
      const timeSharp = this.setSharpMonth(new Date(datetime));

      const notSharp = Boolean(time - timeSharp);
      if (notSharp && isNextMonth) {
        this.setNextMonth(timeSharp);
      }

      return timeSharp;
    },

    checkSharpMonth(date) {
      const sharp = this.getSharpMonth(new Date(date.valueOf()));
      return Boolean(date - sharp === 0);
    },

    getMonthAndYear(date) {
      const month = date.toLocaleString('ru', {
        month: 'long',
      });

      const year = date.getFullYear();

      return `${month}.${year}`;
    },

    getSubElements(element) {
      const elements = element.querySelectorAll('[data-element]');

      return [...elements].reduce((accum, subElement) => {
        accum[subElement.dataset.element] = subElement;

        return accum;
      }, {});
    },

    // getHeaderName() {
    //   return {
    //     index: {
    //       text: "№ п/п",
    //       dataminwidth: 7,
    //       rowspan: 2,
    //     },
    //     client: {
    //       text: "Наименование организации",
    //       dataminwidth: 20,
    //       rowspan: 2,
    //     },
    //     objectsCount: {
    //       text: "Количество ТС оснащённых телематическим оборудованием",
    //       dataminwidth: 16,
    //       rowspan: 2,
    //       formatnum: 12,
    //     },
    //     objectCountInMonth: {
    //       text: "Количество оснащённых ТС",
    //       dataminwidth: 15,
    //       formatnum: 12,
    //       isShowDetailButton: true,
    //     },
    //     objectCountWasOnline: {
    //       text: "Количество ТС на связи",
    //       dataminwidth: 15,
    //       formatnum: 12,
    //     },
    //     objectCountWasOffline: {
    //       text: "Количество ТС не на связи",
    //       dataminwidth: 15,
    //       formatnum: 12,
    //     },
    //     distance: {
    //       text: "Пробег за период (км)",
    //       dataminwidth: 14,
    //       formatnum: 14,
    //     },
    //     canExpence100km: {
    //       text: "Расход топлива (л. на 100 км)",
    //       dataminwidth: 14,
    //       formatnum: 14,
    //     },
    //     forfeits: {
    //       text: "Общее кол-во нарушений",
    //       dataminwidth: 15,
    //       formatnum: 14,
    //     },
    //     forfeitsRelative: {
    //       text: "Кол-во нарушений на 100 км пробега",
    //       dataminwidth: 17,
    //       formatnum: 14,
    //     },
    //   };
    // },

    uploadTemplate(clientsForQuery, queryIntervals, summRow) {
      const columns = this.$options.columns;
      const intervalColumns = this.$options.intervalColumns;

      const areaTemplate = document.getElementById(this.$options.SUMM_AREA_ID);

      areaTemplate.innerHTML = this.getTemplate({
        clientsForQuery,
        summRow,
        queryIntervals,
        columns,
        intervalColumns,
      });

      infoShowText_helper(
        this.buttonTarget,
        'Суммарный отчет построен. Выполнить новый запрос?',
      );
      this.button.style.borderColor = 'green';
    },

    // getTemplate({
    //   clientsForQuery,
    //   summRow,
    //   queryIntervals,
    //   columns,
    //   intervalColumns,
    // } = {}) {
    //   return `
    //       <button class="not-print float-left" onclick="html_to_excel_helper.export( this, 'clients_monthly_template', 'Статистика по организациям', 'portrait' )">
    //           Экспорт&nbsp;в&nbsp;EXCEL
    //       </button>
    //       <div class="temlate-report" id="clients_monthly_template">
    //           <h6 class="text-center font-weight-bold">Статистика нарушений на 100 км пробега</h6>
    //           <table class="table-simple">
    //               <thead>
    //                   ${this.getTheadTemplate({
    //                     queryIntervals,
    //                     columns,
    //                     intervalColumns,
    //                   })}
    //               </thead>
    //               <tbody>
    //                   ${this.getTbodyTemplate({
    //                     clientsForQuery,
    //                     summRow,
    //                     columns,
    //                     intervalColumns,
    //                   })}
    //               </tbody>
    //           </table>
    //       </div>
    //       `;
    // },

    // getTbodyTemplate({ clientsForQuery, summRow, columns, intervalColumns } = {}) {
    //   const headers = this.getHeaderName();

    //   const rows = clientsForQuery.reduce((accum, clientForQuery) => {
    //     const rowArr = this.getTbodyRow({
    //       clientForQuery,
    //       headers,
    //       columns,
    //       intervalColumns,
    //     });
    //     accum.push(`<tr>${rowArr.join("")}</tr>`);
    //     return accum;
    //   }, []);

    //   const summRowArr = this.getTbodyRow({
    //     clientForQuery: summRow,
    //     headers,
    //     columns,
    //     intervalColumns,
    //   });
    //   rows.push(`<tr>${summRowArr.join("")}</tr>`);

    //   return rows.join("");
    // },

    getTbodyRow({ clientForQuery, headers, columns, intervalColumns } = {}) {
      const { summ } = clientForQuery;

      const rowArr = columns.map((column) => {
        return this.getRowColumnTemplate(clientForQuery[column]);
      });

      summ.forEach((summInterval) => {
        intervalColumns.forEach((intervalColumn) => {
          const value = summInterval[intervalColumn];
          const header = headers[intervalColumn];
          const { formatnum } = header;

          rowArr.push(this.getRowColumnTemplate(value, formatnum));
        });
      });

      return rowArr;
    },

    getTheadTemplate() {
      // getTheadTemplate({ queryIntervals, columns, intervalColumns } = {}) {
      const queryIntervals = this.queryIntervals;
      const columns = this.$options.columns;
      const intervalColumns = this.$options.intervalColumns;

      const headerNames = this.$options.headers;

      const headerArr = columns.map((column) => {
        return this.getHeaderColumnTemplate({ headerNames, column });
      });

      const periodsHeaderArr = queryIntervals.map((queryInterval) => {
        const { begin, end } = queryInterval;
        const isSharpMonth = Boolean(
          this.checkSharpMonth(begin) && this.checkSharpMonth(end),
        );

        const text = isSharpMonth
          ? ` ${this.getMonthAndYear(begin)}`
          : `
                  ${formatDateHelper(
                    new Date(begin.valueOf()),
                    'dd.mm.yyyy hh:nn',
                  )} -
                  ${formatDateHelper(
                    new Date(end.valueOf()),
                    'dd.mm.yyyy hh:nn',
                  )}
              `;

        const colspan = intervalColumns.length;
        return `<th data-excelalignv="middle" colspan="${colspan}" data-excelalignh="center">${text}</th>`;
      });

      const rowArr_1 = headerArr.concat(periodsHeaderArr);
      const rowArr_2 = queryIntervals.reduce((accum) => {
        intervalColumns.forEach((column) => {
          const headerHtml = this.getHeaderColumnTemplate({
            headerNames,
            column,
          });
          accum.push(headerHtml);
        });

        return accum;
      }, []);

      return `<tr>${rowArr_1.join('')}</tr><tr>${rowArr_2.join('')}</tr>`;
    },

    getHeaderColumnTemplate({ headerNames, column } = {}) {
      const headerName = headerNames[column];
      const { text, dataminwidth, rowspan = 0 } = headerName;

      if (rowspan) {
        return `<th data-excelalignv="middle" rowspan="${rowspan}" data-minwidth="${dataminwidth}" data-excelalignh="center">${text}</th>`;
      }

      return `<th data-excelalignv="middle" data-minwidth="${dataminwidth}" data-excelalignh="center">${text}</th>`;
    },

    getRowColumnTemplate(value, formatNum) {
      if (formatNum !== undefined) {
        return `<td data-formatnum="${formatNum}" class="text-center" data-excelalignh="center">${formatToDisplay_helper(
          value,
          formatNum,
        )}</td>`;
      }

      return `<td class="text-center" data-excelalignh="center">${value}</td>`;
    },
  },
  columns: ['index', 'client', 'objectsCount'],
  intervalColumns: [
    'objectCountInMonth',
    'objectCountWasOnline',
    'objectCountWasOffline',
    'distance',
    'canExpence100km',
    'forfeits',
    'forfeitsRelative',
  ],
  headers: {
    index: {
      text: '№ п/п',
      dataminwidth: 7,
      rowspan: 2,
    },
    client: {
      text: 'Наименование организации',
      dataminwidth: 20,
      rowspan: 2,
    },
    objectsCount: {
      text: 'Количество ТС оснащённых телематическим оборудованием',
      dataminwidth: 16,
      rowspan: 2,
      formatnum: 12,
    },
    objectCountInMonth: {
      text: 'Количество оснащённых ТС',
      dataminwidth: 15,
      formatnum: 12,
      isShowDetailButton: true,
    },
    objectCountWasOnline: {
      text: 'Количество ТС на связи',
      dataminwidth: 15,
      formatnum: 12,
    },
    objectCountWasOffline: {
      text: 'Количество ТС не на связи',
      dataminwidth: 15,
      formatnum: 12,
    },
    distance: {
      text: 'Пробег за период (км)',
      dataminwidth: 14,
      formatnum: 14,
    },
    canExpence100km: {
      text: 'Расход топлива (л. на 100 км)',
      dataminwidth: 14,
      formatnum: 14,
    },
    forfeits: {
      text: 'Общее кол-во нарушений',
      dataminwidth: 15,
      formatnum: 14,
    },
    forfeitsRelative: {
      text: 'Кол-во нарушений на 100 км пробега',
      dataminwidth: 17,
      formatnum: 14,
    },
  },
};
</script>
<style scoped>
.my-underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
