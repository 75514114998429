import { addCopyArrOfObjects_helper } from '@/helpers/main_helper_010';

import OurAggregated from '@/src/dataRequest/OurAggregatedClass';

import { CATEGORIES } from '../Categories';

import { IPeriod, ISmena, ISmenasOptions, Smenas } from '../Smenas';
import {
  TSettingOfValues,
  aggregationCalculating,
  mathArray,
} from '../AggregationCalculating';

type TProcessCallback = (...args: any[]) => any;

interface IFullPeriod extends IPeriod {
  TimeBegin: number;
  TimeEnd: number;
}

interface IGetReportOptions {
  objects: { id: string }[];
  from: number;
  to: number;
  time_scale_minutes?: number;
}

interface IReportOptions {
  time_scale_minutes?: number;
  begin?: number;
  end?: number;
  columns?: { [key: string]: any };
  settings: {
    total: boolean;
    isDetail: boolean;
    isDailyDetail: boolean;
  };
}

interface IInterval {
  timeBegin: number;
  timeEnd: number;
}

interface IResponse {
  posArray: IPosition[];
  error: number;
  objConf: IObjConf;
}

interface IPosition {
  active_time: string;
  engine_time: string;
  percent_data: string;
  seconds_of_the_day: string;
  time: string;
}
interface ICalculatedPosition {
  active_time: number;
  engine_time: number;
  percent_data: number;
  seconds_of_the_day: number;
  time: number;
}

interface IObjConf {
  line_id: string;
  id: string;
  name: string;
  state_number: string;
  model: string;
  client: string;
  vin: string;
  category: string;
  parent_code: string;
  last_time_vendor: string;
  lat: string;
  lon: string;
  alt: string;
  head: string;
  speed: string;
  last_time_aggregation: string;
  owner?: string;
}

export interface IReportObject {
  name: string;
  id: string;
  categoryNum: string;
  gCategory: number;
  techType: string;
  category: string;
  moto2Name: string;
  model: string;
  unit: string;
  avtoNo: string;
  avtoModel: string;
  vin: string;
  client: string;
  owner: string;
  timeScaleMinutes: number;
  sum?: IReportSum;
  detailByPeriods?: IReportDetail;
  periods?: IFullPeriod[];
}

interface IReportSum {
  parkTime: number;
  usageFactor: number;
  dailyAverageEngineTime: number;
  dailyAverageActiveTime: number;
  dailyAverageUsageFactor: number;
}

interface IReportDetail {
  [key: number]: any;
}

type TOnSuccess<T> = (result: T) => void;

const ROUND_TO = 5; // округление по 5 минут

const SECONDS_IN_THE_HOUR = 3600;

const TIME_SCALE_MINUTES = 30;

const OPTION_FOR_DETAIL = 3;
const OPTION_FOR_DAILY_DETAIL = 4;

const NOT_VALUE = 'н/у';
const NOT_VALIDATE = 'нет значения';

export default class {
  private _smenas: Smenas;
  private _Report: IReportOptions;

  private _onProcess: TProcessCallback | null;
  private _onSuccess: TOnSuccess<any> | null;

  private settingOfValues: TSettingOfValues = {
    time: [0.001, false],
    engine_time: [1, true],
    active_time: [1, true],
    percent_data: [1, false],
    seconds_of_the_day: [1, false],
    hirpm_notactime: [1, true],
    rpms: {
      '2001': [1, true],
      '2000': [1, true],
      '1900': [1, true],
      '1800': [1, true],
      '1700': [1, true],
      '1600': [1, true],
      '1500': [1, true],
      '1400': [1, true],
      '1300': [1, true],
      '1200': [1, true],
    },
    engtmprs: {
      '96': [1, true],
      '95': [1, true],
      '85': [1, true],
      '60': [1, true],
      '40': [1, true],
      '0': [1, true],
      '-20': [1, true],
      '-30': [1, true],
    },
    hydoiltmprs: {
      '100': [1, true],
      '90': [1, true],
      '80': [1, true],
      '60': [1, true],
      '50': [1, true],
      '30': [1, true],
    },
  };

  constructor(settings: IReportOptions, smenasSetting: ISmenasOptions) {
    this._Report = settings;

    this._onProcess = null;
    this._onSuccess = null;

    this._smenas = new Smenas(smenasSetting);
  }

  public getReport({
    objects,
    from,
    to,
    time_scale_minutes,
  }: IGetReportOptions) {
    // обнуление запомненных настроек
    this._smenas.resetSettings();

    // нажатие на кнопку в форме настройки отчета

    if (this._onProcess) {
      this._onProcess('success', 'Обсчет данных для получения отчета');
    }

    this._Report.time_scale_minutes = time_scale_minutes || 0;
    this._Report.begin = this.roundMinutes(new Date(from), ROUND_TO);
    this._Report.end = this.roundMinutes(new Date(to), ROUND_TO);

    // вычисление смен для отчета
    this._smenas.getSmenasSetting(
      ROUND_TO,
      this._Report.begin,
      this._Report.end,
    );

    if (this._smenas.badSmenasFlag) {
      if (this._onProcess) {
        this._onProcess('error', 'Смены были заданы неверно');
      } else {
        console.error('Смены были заданы неверно');
      }
      return;
    } else {
      if (this._onProcess) {
        this._onProcess('success', 'Обсчет данных для получения отчета');
      }
    }

    const ourAggregated = new OurAggregated('specMachine', {
      timeBegin: this._Report.begin,
      timeEnd: this._Report.end,
    });

    if (this._onProcess) {
      ourAggregated.onProcess(this._onProcess);
    }

    ourAggregated.onSuccess<IResponse[]>((responses) => {
      try {
        const reportCalculated = this.calculate(responses, {
          timeBegin: Number(this._Report.begin),
          timeEnd: Number(this._Report.end),
        });

        if (this._onSuccess) this._onSuccess(reportCalculated);
      } catch (error) {
        if (this._onProcess) {
          const type = `error`;
          const message = `Произошла ошибка при обсчете данных. Повторите запрос, либо перезагрузите страницу`;
          this._onProcess(type, message);
        }
      }
    });

    time_scale_minutes = this.getTimeScaleMinutes();
    const option = this._Report.settings.isDailyDetail
      ? OPTION_FOR_DAILY_DETAIL
      : OPTION_FOR_DETAIL;

    // запрос и вычисления
    ourAggregated.callOurAggregated(
      objects,
      this._smenas.forResponse,
      option,
      time_scale_minutes,
    );
  }

  public getTimeScaleMinutes(): number {
    if (this._Report.time_scale_minutes) {
      return this._Report.time_scale_minutes;
    }
    if (this._Report.settings.isDetail) {
      return TIME_SCALE_MINUTES;
    }

    return 0;
  }

  public roundMinutes(date: Date, roundTo: number): number {
    const minutes = date.getMinutes();

    date.setMinutes(minutes - (minutes % roundTo));

    return date.getTime();
  }

  public calculate(Response: IResponse[], ResponseInterval: IInterval) {
    const from = new Date(ResponseInterval['timeBegin']);
    const to = new Date(ResponseInterval['timeEnd']);

    let Periods: IFullPeriod[] = addCopyArrOfObjects_helper(
      this._smenas.setSmenas.Periods || [],
    ) as IFullPeriod[];

    const arrSmenas = this._smenas.setSmenas.arrSmena as ISmena[];

    const objcnt = Response.length;

    const objects: any[] = [];

    for (let i = 0; i < objcnt; i++) {
      const w_result = Response[i];

      const objConf: IObjConf = Response[i]['objConf'];

      const obj: IReportObject = {
        name: objConf.name,
        id: objConf.id,
        categoryNum: objConf.category,

        gCategory: CATEGORIES[+objConf.category]
          ? CATEGORIES[+objConf.category][0]
          : 0,
        techType: CATEGORIES[+objConf.category]
          ? CATEGORIES[+objConf.category][1]
          : '',
        category: CATEGORIES[+objConf.category]
          ? CATEGORIES[+objConf.category][2]
          : '',

        moto2Name: '',

        model: objConf.model || '',
        unit: objConf.parent_code || '',

        avtoNo: objConf.state_number || '',

        avtoModel: objConf.model || '',
        vin: objConf.vin || NOT_VALIDATE,
        client: objConf.client || '',
        owner: objConf.owner || '',

        timeScaleMinutes: this.getTimeScaleMinutes(),
      };

      if (
        !('error' in w_result) ||
        w_result['error'] != 0 ||
        !('posArray' in w_result) ||
        !('objConf' in w_result)
      ) {
        throw new Error('ExcavatorOperation: error on calculating');
      }

      if (!obj.avtoNo) {
        obj.avtoNo = 'г/н ' + NOT_VALUE;
      }

      // получим позиции
      const w_posArr = w_result?.posArray ? w_result.posArray : [];

      aggregationCalculating(
        w_posArr,
        this.settingOfValues,
        Periods,
        +from,
        +to,
      );

      const sum = this.getSummByPeriods(Periods);

      const { engine_time = 0, active_time = 0 } = sum;
      const engineTimeInHours = engine_time / SECONDS_IN_THE_HOUR;
      const activeTimeInHours = active_time / SECONDS_IN_THE_HOUR;

      obj.sum = {
        ...sum,
        parkTime: engine_time - active_time,
        usageFactor: engine_time ? (active_time / engine_time) * 100 : 0,
        dailyAverageEngineTime: engineTimeInHours / Periods.length,
        dailyAverageActiveTime: activeTimeInHours / Periods.length,
        dailyAverageUsageFactor: engine_time
          ? (active_time / engine_time) * 100
          : 0,
      };

      if (this._Report.settings.isDetail) {
        obj.detailByPeriods = this.splitDetailByHour(
          Periods,
          arrSmenas,
          obj.timeScaleMinutes,
        );
      }
      obj.periods = Periods;

      objects.push(obj);

      // очистим таблицу исходных значений по объекту
      Periods = addCopyArrOfObjects_helper(
        this._smenas.setSmenas.Periods || [],
      ) as IFullPeriod[];
    }

    return {
      settings: this._Report.settings,
      objects,
      responseInterval: { from: +from, to: +to },
    };
  }

  getSummByPeriods(Periods: IFullPeriod[]) {
    const accum = this.getObjectWithEmptyValues();

    const summByPeriods = Periods.reduce((acc, period) => {
      if (!Object.keys(period.webSumm).length) return acc;

      acc = mathArray(period.webSumm, acc, '+', this.settingOfValues, true);

      return acc;
    }, accum);

    return summByPeriods;
  }

  splitDetailByHour(
    Periods: IFullPeriod[],
    arrSmenas: ISmena[],
    time_scale_minutes: number,
  ) {
    if (!Periods[0] || !Periods[0].TimeBegin) return [];
    const detailByHours = [];

    const HOUR_IN_MINUTES = 60;

    let hourPositions: (ICalculatedPosition | null)[] = [];
    for (const period of Periods) {
      if (!period.TimeBegin || !period.TimeEnd) {
        throw new Error('periods is not valid');
      }

      const positionsByHours: (ICalculatedPosition | null)[][] = [];

      const periodDateBegin = new Date(period.TimeBegin);

      for (const smena of arrSmenas) {
        const smenaTimeBegin = smena.TimeBegin;

        const roundedPeriodTimeBegin =
          new Date(
            periodDateBegin.getFullYear(),
            periodDateBegin.getMonth(),
            periodDateBegin.getDate(),
          ).getTime() + smenaTimeBegin;

        const interval = (smena.TimeEnd - smena.TimeBegin) / 1000;
        const iterationsInHour = HOUR_IN_MINUTES / time_scale_minutes;
        const iterationsCount = interval / HOUR_IN_MINUTES / time_scale_minutes;

        for (let index = 1; index < iterationsCount + 1; index++) {
          const curIterationTime =
            roundedPeriodTimeBegin +
            smenaTimeBegin +
            index * time_scale_minutes * HOUR_IN_MINUTES * 1000;

          const position =
            period.webDetail.find(
              (pos) => pos && pos.time === curIterationTime,
            ) ?? null;

          hourPositions.push(position);

          if (index % iterationsInHour === 0) {
            positionsByHours.push(hourPositions);
            hourPositions = [];
          }
        }
      }

      detailByHours.push(positionsByHours);
    }

    return detailByHours;
  }

  getObjectWithEmptyValues() {
    const obj: { [key: string]: any } = {};

    for (const key in this.settingOfValues) {
      if (this.settingOfValues[key].toString() === '[object Object]') {
        obj[key] = {};
        for (const k in this.settingOfValues[key]) {
          obj[key][k] = 0;
        }
        continue;
      }

      obj[key] = 0;
    }

    return obj;
  }

  public onProcess(listener: TProcessCallback) {
    this._onProcess = listener;
    return this;
  }
  public onSuccess<T>(listener: TOnSuccess<T>) {
    this._onSuccess = listener;
    return this;
  }
}
