<template>
  <div
    :data-excelsheet="vehicle.name"
    style="margin-top: 5px"
  ></div>
  <h6 class="footer-desc text-center font-weight-bold">{{ tableCaption }}</h6>
  <table style="margin-bottom: 18px">
    <!-- <caption class="footer-desc text-center font-weight-bold" style="caption-side:top;padding:2px;">{{ tableCaption }}</caption> -->
    <thead>
      <tr>
        <th
          v-for="colName in columns.tableColumns"
          :key="colName"
          :data-minwidth="columns[colName].excelWidth ?? 7"
          data-excelalignv="middle"
          data-excelalignh="center"
        >
          {{ columns[colName].text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in vehicle.evacuatorMovingRows"
        :key="row.begin.time"
      >
        <!-- <evacuator-moving-column v-for="colName in columns.tableColumns" :key="colName"
          :htmlText="columns[colName].getAHref ? columns[colName].getAHref(row) : ''"
          :text="columns[colName].get ? columns[colName].get(row, allGeofencesDict) : row.evacuatorRow[colName]"
        /> -->

        <td
          v-for="colName in columns.tableColumns"
          :key="colName"
          :data-formatnum="columns[colName].excelFormat ?? ''"
          :data-value="
            columns[colName].getExcelValue
              ? columns[colName].getExcelValue(row)
              : ''
          "
        >
          {{
            columns[colName].get
              ? columns[colName].get(row, allGeofencesDict)
              : row.evacuatorRow[colName]
          }}
          <template v-if="columns[colName].getAHref">
            <br />
            <a
              target="_blank"
              :href="columns[colName].getAHref(row)"
            >
              {{ columns[colName].getAText(row) }}
            </a>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import EvacuatorMovingColumn from './EvacuatorMovingColumn.vue'

export default {
  name: 'EvacuatorMovingTable',
  // components: {
  //   EvacuatorMovingColumn
  // },
  props: {
    vehicle: {
      type: Object,
      require: true,
    },
    columns: {
      type: Object,
      require: true,
    },
    allGeofencesDict: {
      type: Object,
      require: true,
    },
  },
  computed: {
    tableCaption() {
      const avtoNo = this.vehicle.avtoNo.replaceAll('&nbsp;', ' ');
      return `Отчет по работе эвакуатора ${this.vehicle.name} г/н ${avtoNo} ${this.vehicle.view.t_interval}`;
    },
  },
};

// :rows="obj.evacuatorMovingRows"
</script>
